import './admin_sidebar.css';
import { Link, useLocation } from 'react-router-dom';

export default function AdminSidebar() {

    const pathname = useLocation().pathname;

    return (
        <>
            <div className="admin_sidebar">
                <div className="menu">
                    <div className="menu-item">
                        <Link to="/admin/users" className={`${pathname === "/admin/users" ? 'menu-link active' : 'menu-link'}`}>
                            <span className="menu-title">Users</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}