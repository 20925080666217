import './sidebar.css';
import { Link, useLocation } from 'react-router-dom';

export default function Sidebar() {

    const pathname = useLocation().pathname;
    const product = localStorage.getItem("product");
    return (
        <>
            <div className="sidebar">
                <div className="menu">
                    <div className="menu-item">
                        <Link to="/general-info" className={`${pathname === "/general-info" ? 'menu-link active' : 'menu-link'}`}>
                            <span className="menu-title">Product(s) & Company information</span>
                        </Link>
                    </div>
                    {(() => {
                        if (product === null) {
                            return (
                                <>
                                    <div className="menu-item">
                                        <div className="menu-link disabled">
                                            <span className="menu-title">LCA</span>
                                        </div>
                                    </div>
                                    <div className="menu-item">
                                        <div className="menu-link disabled">
                                            <span className="menu-title">LCC</span>
                                        </div>
                                    </div>
                                    <div className="menu-item">
                                        <div className="menu-link disabled">
                                            <span className="menu-title">S-LCA</span>
                                        </div>
                                    </div>
                                    <div className="menu-item">
                                        <div className="menu-link disabled">
                                            <span className="menu-title">Circularity</span>
                                        </div>
                                    </div>
                                    <div className="menu-item">
                                        <div className="menu-link disabled">
                                            <span className="menu-title">Criticality</span>
                                        </div>
                                    </div>
                                    <div className="separator"></div>
                                    <div className="menu-item">
                                        <div className="menu-link disabled">
                                            <span className="menu-title">LCSA</span>
                                        </div>
                                    </div>
                                    <div className="menu-item">
                                        <div className="menu-link disabled">
                                            <span className="menu-title">Product comparison</span>
                                        </div>
                                    </div>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div className="menu-item">
                                        <Link to="/lca" className={`${pathname === "/lca" ? 'menu-link active' : 'menu-link'}`}>
                                            <span className="menu-title">LCA</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/lcc" className={`${pathname === "/lcc" ? 'menu-link active' : 'menu-link'}`}>
                                            <span className="menu-title">LCC</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/s-lca" className={`${pathname === "/s-lca" ? 'menu-link active' : 'menu-link'}`}>
                                            <span className="menu-title">S-LCA</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/circularity" className={`${pathname === "/circularity" ? 'menu-link active' : 'menu-link'}`}>
                                            <span className="menu-title">Circularity</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/criticality" className={`${pathname === "/criticality" ? 'menu-link active' : 'menu-link'}`}>
                                            <span className="menu-title">Criticality</span>
                                        </Link>
                                    </div>
                                    <div className="separator"></div>
                                    <div className="menu-item">
                                        <Link to="/lcsa" className={`${pathname === "/lcsa" ? 'menu-link active' : 'menu-link'}`}>
                                            <span className="menu-title">LCSA</span>
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to="/product-comparison" className={`${pathname === "/product-comparison" ? 'menu-link active' : 'menu-link'}`}>
                                            <span className="menu-title">Product comparison</span>
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                    })()}
                </div>
                <div className="aside-footer flex-column-auto px-6 px-lg-9">
                    <Link to="/glossary" className={`${pathname === "/glossary" ? 'menu-link active' : 'menu-link'}`}>
                        <span className="menu-title">Glossary</span>
                    </Link>
                </div>
            </div>
        </>
    )
}