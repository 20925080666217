import React, { useState, useRef } from "react";
import { Table } from 'react-bootstrap';
import { Next } from "react-bootstrap/esm/PageItem";
import { Bar, getElementAtEvent } from 'react-chartjs-2';

export default function ProductComparisonLCC({ product, products, lcc, lccTotals, categoriesLCC, selectedProductsLCC, selectedProductsLCCTotals, selected, lcsa, selectedProductsLCSA }) {
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const [dataLCC, setDataLCC] = useState(null);
    const [isChecked, setIsChecked] = useState(true);
    const [titleLCC, setTitleLCC] = useState("");
    const chartRfLCC = useRef();
    const [val, setVal] = useState(null);
    const [dataGraphBar, setDataGraphBar] = useState(null);
    const [title, setTitle] = useState("");
    const [errors, setErrors] = useState([]);

    const selectTotal = (value, checked) => {
        if (value === "" && val !== null) {
            value = val;
        }
        if (value !== "") {
            let categories = null;
            setErrors([]);
            setDataGraphBar(null);
            let title = "";
            if (value === "totalUndiscountedValue") {
                title = "Total undiscounted value";
            } else if (value === "netPresentValue") {
                title = "Net present value";
            } else if (value === "totalUndiscountedCosts") {
                title = "Total undiscounted costs";
            } else if (value === "totalPresentCosts") {
                title = "Total present costs";
            } else if (value === "netUndiscountedCosts") {
                title = "Net undiscounted costs";
            } else if (value === "netPresentCosts") {
                title = "Net present costs";
            }
            setTitleLCC(title);
            categories = categoriesLCC.filter(el => el.type === title)[0].categories;
            let categoryList = [...new Set([].concat(...categories.map((o) => o.subcategories)))].map(e => e.subcategory);
            if (!checked) {
                categoryList = categoryList.filter(e => e !== "A. Capital Costs");
            }
            const datasets = [];

            let exist = false;
            let dataset = { label: `${product.name} - ${product.scenario}`, data: [], backgroundColor: colors[0] };
            for (let j = 0; j < lcc.length; j++) {
                if (lcc[j].valueType === title) {
                    exist = true;
                    const result = [...new Set([].concat(...lcc[j].items.map((o) => o.items)))];
                    for (let k = 0; k < categoryList.length; k++) {
                        if (result.filter(x => x.indicator === categoryList[k]).length > 0) {
                            dataset.data.push(result.filter(x => x.indicator === categoryList[k])[0].value);
                        } else {
                            dataset.data.push(0);
                        }
                    }
                    break;
                }
            }
            let errs = [];
            if (!exist) {
                for (let k = 0; k < categoryList.length; k++) {
                    dataset.data.push(0);
                };
                errs.push(`${product.name} - ${product.scenario}`);
            }
            datasets.push(dataset);

            for (let i = 0; i < selectedProductsLCC.length; i++) {
                const pr = products.filter(x => x.id === selectedProductsLCC[i].id)[0];
                dataset = { label: `${pr.productName} - ${pr.scenario}`, data: [], backgroundColor: colors[i + 1] };
                let exist = false;
                for (let j = 0; j < selectedProductsLCC[i].data.length; j++) {
                    if (selectedProductsLCC[i].data[j].valueType === title) {
                        exist = true;
                        const result = [...new Set([].concat(...selectedProductsLCC[i].data[j].items.map((o) => o.items)))];
                        for (let k = 0; k < categoryList.length; k++) {
                            if (result.filter(x => x.indicator === categoryList[k]).length > 0) {
                                dataset.data.push(result.filter(x => x.indicator === categoryList[k])[0].value);
                            } else {
                                dataset.data.push(0);
                            }
                        }
                        break;
                    }
                }
                if (!exist) {
                    for (let k = 0; k < categoryList.length; k++) {
                        dataset.data.push(0);
                    }
                    errs.push(`${pr.productName} - ${pr.scenario}`);
                }
                datasets.push(dataset);
            };
            const data = {
                labels: categoryList,
                datasets: datasets
            }
            setDataLCC(data);
            setErrors(errs);
            setVal(value);
        }
    }

    const onClick2 = (event) => {
        const active = getElementAtEvent(chartRfLCC.current, event);
        if (active.length > 0) {
            const label = dataLCC.labels[active[0].index];
            setTitle(label);
            createCharts(label);
        }
    }

    const createCharts = (result) => {
        const topics = [];
        const valuesLcc = lcc.filter(e => e.valueType === titleLCC)[0];
        const resultLccItems = [...new Set([].concat(...valuesLcc.items.map((o) => o.items)))];
        const resultLcc = resultLccItems.filter(e => e.indicator === result)[0];

        topics.push({ id: localStorage.getItem('product'), data: resultLcc.items });

        for (let i = 0; i < selectedProductsLCC.length; i++) {
            const valuesLccOthers = selectedProductsLCC[i].data.filter(e => e.valueType === titleLCC)[0];
            const resultLccItemsOthers = [...new Set([].concat(...valuesLccOthers.items.map((o) => o.items)))];
            const resultLccOthers = resultLccItemsOthers.filter(e => e.indicator === result)[0];
            topics.push({ id: selectedProductsLCC[i].id, data: resultLccOthers.items });
        }

        const tops = [];
        for (let i = 0; i < topics.length; i++) {
            const newTopics = topics[i].data;
            newTopics.sort((a, b) => a.position - b.position);
            const stages = newTopics.map(c => c.stage);
            for (let j = 0; j < stages.length; j++) {
                tops.push(stages[j]);
            }
        }
        const uniqueTopics = [...new Set(tops)];
        const datasets = [];
        for (let i = 0; i < topics.length; i++) {
            const pr = products.filter(x => x.id === topics[i].id)[0];
            const dataset = { label: `${pr.productName} - ${pr.scenario}`, data: [], unit: [], backgroundColor: colors[i] };
            for (let j = 0; j < uniqueTopics.length; j++) {
                let exist = false;
                for (let k = 0; k < topics[i].data.length; k++) {
                    if (topics[i].data[k].stage === uniqueTopics[j]) {
                        dataset.data.push(topics[i].data[k].value);
                        dataset.unit.push(topics[i].data[k].unit);
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    dataset.data.push(0);
                }
            }
            datasets.push(dataset);
        }

        const dataGraph = {
            labels: uniqueTopics,
            datasets: datasets
        }

        setDataGraphBar(dataGraph);
    }

    const handleOnChange = () => {
        setIsChecked(!isChecked);
        selectTotal("", !isChecked);
        setDataGraphBar(null);
    }

    const optionsLCC = {
        indexAxis: "y",
        duration: 0,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (`${item.formattedValue}€`);
                    }
                }
            }
        }
    };

    const options3 = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        const index = item.dataset.data.findIndex((element) => element === item.raw);
                        return (`${item.dataset.label}: ${item.formattedValue} ${item.dataset.unit[index]}`);
                    }
                }
            },
            title: {
                display: false
            }
        }
    };

    return (
        <>
            {
                selected.length === 0 && <div className="card card-flush h-md-100">
                    <div className="card-body">
                        <div className="mt-3 w-100">
                            <div className="row">
                                <div className="col">No product has been selected to compare</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                selected.length > 0 && <div className="card card-flush h-md-100">
                    <div className="card-body">
                        <div className="row mt-3">
                            <div className="col">
                                <div className="alert alert-info">
                                    Note that including the user's capital cost may dilute the relevance of other cost categories as it typically incorporates upstream producer's costs.
                                    For this reason, especially when adopting a producer perspective, it is suggested to exclude this element from the analysis.
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={isChecked} onChange={handleOnChange} id="includeCapitalCosts" />
                                    <label className="form-check-label" htmlFor="includeCapitalCosts">
                                        Including the user’s capital cost (i.e., the product acquisition price)
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <Table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style={{ backgroundColor: colors[0] }}>{`${product.name} - ${product.scenario}`}</th>
                                            {
                                                selectedProductsLCC.map((item, index) => {
                                                    return (
                                                        <th style={{ backgroundColor: colors[index + 1] }} key={index}>{`${products.filter(x => x.id === item.id)[0].productName} - ${products.filter(x => x.id === item.id)[0].scenario}`}</th>
                                                    );
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b>Single score</b></td>
                                            {
                                                (() => {
                                                    if (lcsa.lccSingleScore !== null) {
                                                        if (isChecked) {
                                                            return (<td><b>{lcsa.lccSingleScore.toFixed(2)}</b></td>)
                                                        } else if (!isChecked && lcc.filter(el => el.valueType === "Total undiscounted costs").length > 0) {
                                                            const value = lcc.filter(el => el.valueType === "Total undiscounted costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                            return (<td><b>{(lcsa.lccSingleScore - value).toFixed(2)}</b></td>)
                                                        } else {
                                                            return (<td><b>{lcsa.lccSingleScore.toFixed(2)}</b></td>)
                                                        }
                                                    } else {
                                                        return (<td><b>-</b></td>)
                                                    }
                                                })()
                                            }
                                            {
                                                selectedProductsLCSA.map((item, index) => {
                                                    return (
                                                        (() => {
                                                            if (item.data.lccSingleScore !== null) {
                                                                if (isChecked) {
                                                                    return (<td key={index}><b>{item.data.lccSingleScore.toFixed(2)}</b></td>)
                                                                } else if (!isChecked && lcc.filter(el => el.valueType === "Total undiscounted costs").length > 0) {
                                                                    const value = selectedProductsLCC.filter(d => d.id === item.id)[0].data.filter(el => el.valueType === "Total undiscounted costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                    return (<td key={index}><b>{(item.data.lccSingleScore - value).toFixed(2)}</b></td>)
                                                                } else {
                                                                    return (<td key={index}><b>{item.data.lccSingleScore.toFixed(2)}</b></td>)
                                                                }
                                                            } else {
                                                                return (<td key={index}><b>-</b></td>)
                                                            }
                                                        })()
                                                    );
                                                })
                                            }
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="mt-3 w-100">
                            <div className="row mt-3">
                                <div className="col">
                                    Click on one of the economic inventory categories to see the disaggregated
                                    results and be able to compare them for the different selected products:
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <Table className="table table-bordered lcc-comparison">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th style={{ backgroundColor: colors[0] }}>{`${product.name} - ${product.scenario}`}</th>
                                                {
                                                    selectedProductsLCC.map((item, index) => {
                                                        return (
                                                            <th style={{ backgroundColor: colors[index + 1] }} key={index}>{`${products.filter(x => x.id === item.id)[0].productName} - ${products.filter(x => x.id === item.id)[0].scenario}`}</th>
                                                        );
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (lccTotals.totalUndiscountedValue || selectedProductsLCCTotals.some(e => e.data.totalUndiscountedValue !== null)) && <tr onClick={() => selectTotal('totalUndiscountedValue', isChecked)}>
                                                    <td>Total undiscounted value</td>
                                                    {
                                                        (() => {
                                                            if (typeof (lccTotals.totalUndiscountedValue) !== "undefined" && lccTotals.totalUndiscountedValue !== null) {
                                                                if (isChecked) {
                                                                    return (<td>{lccTotals.totalUndiscountedValue.toFixed(2)}</td>)
                                                                } else if (!isChecked && lcc.filter(el => el.valueType === "Total undiscounted value").length > 0) {
                                                                    const value = lcc.filter(el => el.valueType === "Total undiscounted value")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                    return (<td>{(lccTotals.totalUndiscountedValue - value).toFixed(2)}</td>)
                                                                } else {
                                                                    return (<td>{lccTotals.totalUndiscountedValue}</td>)
                                                                }
                                                            } else {
                                                                return (<td>-</td>)
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        selectedProductsLCCTotals.map((item, index) => {
                                                            return (
                                                                (() => {
                                                                    if (typeof (item.data.totalUndiscountedValue) !== "undefined" && item.data.totalUndiscountedValue !== null) {
                                                                        if (isChecked) {
                                                                            return (<td key={index}>{item.data.totalUndiscountedValue.toFixed(2)}</td>)
                                                                        } else if (!isChecked && lcc.filter(el => el.valueType === "Total undiscounted value").length > 0) {
                                                                            const value = selectedProductsLCC.filter(d => d.id === item.id)[0].data.filter(el => el.valueType === "Total undiscounted value")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                            return (<td key={index}>{(item.data.totalUndiscountedValue - value).toFixed(2)}</td>)
                                                                        } else {
                                                                            return (<td key={index}>{item.data.totalUndiscountedValue}</td>)
                                                                        }
                                                                    } else {
                                                                        return (<td key={index}>-</td>)
                                                                    }
                                                                })()
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            }
                                            {
                                                (lccTotals.netPresentValue || selectedProductsLCCTotals.some(e => e.data.netPresentValue !== null)) && <tr onClick={() => selectTotal('netPresentValue', isChecked)}>
                                                    <td>Net present value</td>
                                                    {
                                                        (() => {
                                                            if (typeof (lccTotals.netPresentValue) !== "undefined" && lccTotals.netPresentValue !== null) {
                                                                if (isChecked) {
                                                                    return (<td>{lccTotals.netPresentValue.toFixed(2)}</td>)
                                                                } else if (!isChecked && lcc.filter(el => el.valueType === "Net present value").length > 0) {
                                                                    const value = lcc.filter(el => el.valueType === "Net present value")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                    return (<td>{(lccTotals.netPresentValue - value).toFixed(2)}</td>)
                                                                } else {
                                                                    return (<td>{lccTotals.netPresentValue}</td>)
                                                                }
                                                            } else {
                                                                return (<td>-</td>)
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        selectedProductsLCCTotals.map((item, index) => {
                                                            return (
                                                                (() => {
                                                                    if (typeof (item.data.netPresentValue) !== "undefined" && item.data.netPresentValue !== null) {
                                                                        if (isChecked) {
                                                                            return (<td key={index}>{item.data.netPresentValue.toFixed(2)}</td>)
                                                                        } else if (!isChecked && lcc.filter(el => el.valueType === "Net present value").length > 0) {
                                                                            const value = selectedProductsLCC.filter(d => d.id === item.id)[0].data.filter(el => el.valueType === "Net present value")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                            return (<td key={index}>{(item.data.netPresentValue - value).toFixed(2)}</td>)
                                                                        } else {
                                                                            return (<td key={index}>{item.data.netPresentValue}</td>)
                                                                        }
                                                                    } else {
                                                                        return (<td key={index}>-</td>)
                                                                    }
                                                                })()
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            }
                                            {
                                                (lccTotals.totalUndiscountedCosts || selectedProductsLCCTotals.some(e => e.data.totalUndiscountedCosts !== null)) && <tr onClick={() => selectTotal('totalUndiscountedCosts', isChecked)}>
                                                    <td>Total undiscounted costs</td>
                                                    {
                                                        (() => {
                                                            if (typeof (lccTotals.totalUndiscountedCosts) !== "undefined" && lccTotals.totalUndiscountedCosts !== null) {
                                                                if (isChecked) {
                                                                    return (<td>{lccTotals.totalUndiscountedCosts.toFixed(2)}</td>)
                                                                } else if (!isChecked && lcc.filter(el => el.valueType === "Total undiscounted costs").length > 0) {
                                                                    const value = lcc.filter(el => el.valueType === "Total undiscounted costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                    return (<td>{(lccTotals.totalUndiscountedCosts - value).toFixed(2)}</td>)
                                                                } else {
                                                                    return (<td>{lccTotals.totalUndiscountedCosts}</td>)
                                                                }
                                                            } else {
                                                                return (<td>-</td>)
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        selectedProductsLCCTotals.map((item, index) => {
                                                            return (
                                                                (() => {
                                                                    if (typeof (item.data.totalUndiscountedCosts) !== "undefined" && item.data.totalUndiscountedCosts !== null) {
                                                                        if (isChecked) {
                                                                            return (<td key={index}>{item.data.totalUndiscountedCosts.toFixed(2)}</td>)
                                                                        } else if (!isChecked && lcc.filter(el => el.valueType === "Total undiscounted costs").length > 0) {
                                                                            const value = selectedProductsLCC.filter(d => d.id === item.id)[0].data.filter(el => el.valueType === "Total undiscounted costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                            return (<td key={index}>{(item.data.totalUndiscountedCosts - value).toFixed(2)}</td>)
                                                                        } else {
                                                                            return (<td key={index}>{item.data.totalUndiscountedCosts}</td>)
                                                                        }
                                                                    } else {
                                                                        return (<td key={index}>-</td>)
                                                                    }
                                                                })()
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            }
                                            {
                                                (lccTotals.totalPresentCosts || selectedProductsLCCTotals.some(e => e.data.totalPresentCosts !== null)) && <tr onClick={() => selectTotal('totalPresentCosts', isChecked)}>
                                                    <td>Total present costs</td>
                                                    {
                                                        (() => {
                                                            if (typeof (lccTotals.totalPresentCosts) !== "undefined" && lccTotals.totalPresentCosts !== null) {
                                                                if (isChecked) {
                                                                    return (<td>{lccTotals.totalPresentCosts.toFixed(2)}</td>)
                                                                } else if (!isChecked && lcc.filter(el => el.valueType === "Total present costs").length > 0) {
                                                                    const value = lcc.filter(el => el.valueType === "Total present costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                    return (<td>{(lccTotals.totalPresentCosts - value).toFixed(2)}</td>)
                                                                } else {
                                                                    return (<td>{lccTotals.totalPresentCosts}</td>)
                                                                }
                                                            } else {
                                                                return (<td>-</td>)
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        selectedProductsLCCTotals.map((item, index) => {
                                                            return (
                                                                (() => {
                                                                    if (typeof (item.data.totalPresentCosts) !== "undefined" && item.data.totalPresentCosts !== null) {
                                                                        if (isChecked) {
                                                                            return (<td key={index}>{item.data.totalPresentCosts.toFixed(2)}</td>)
                                                                        } else if (!isChecked && lcc.filter(el => el.valueType === "Total present costs").length > 0) {
                                                                            const value = selectedProductsLCC.filter(d => d.id === item.id)[0].data.filter(el => el.valueType === "Total present costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                            return (<td key={index}>{(item.data.totalPresentCosts - value).toFixed(2)}</td>)
                                                                        } else {
                                                                            return (<td key={index}>{item.data.totalPresentCosts}</td>)
                                                                        }
                                                                    } else {
                                                                        return (<td key={index}>-</td>)
                                                                    }
                                                                })()
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            }
                                            {
                                                (lccTotals.netUndiscountedCosts || selectedProductsLCCTotals.some(e => e.data.netUndiscountedCosts !== null)) && <tr onClick={() => selectTotal('netUndiscountedCosts', isChecked)}>
                                                    <td>Net undiscounted costs</td>
                                                    {
                                                        (() => {
                                                            if (typeof (lccTotals.netUndiscountedCosts) !== "undefined" && lccTotals.netUndiscountedCosts !== null) {
                                                                if (isChecked) {
                                                                    return (<td>{lccTotals.netUndiscountedCosts.toFixed(2)}</td>)
                                                                } else if (!isChecked && lcc.filter(el => el.valueType === "Net undiscounted costs").length > 0) {
                                                                    const value = lcc.filter(el => el.valueType === "Net undiscounted costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                    return (<td>{(lccTotals.netUndiscountedCosts - value).toFixed(2)}</td>)
                                                                } else {
                                                                    return (<td>{lccTotals.netUndiscountedCosts}</td>)
                                                                }
                                                            } else {
                                                                return (<td>-</td>)
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        selectedProductsLCCTotals.map((item, index) => {
                                                            return (
                                                                (() => {
                                                                    if (typeof (item.data.netUndiscountedCosts) !== "undefined" && item.data.netUndiscountedCosts !== null) {
                                                                        if (isChecked) {
                                                                            return (<td key={index}>{item.data.netUndiscountedCosts.toFixed(2)}</td>)
                                                                        } else if (!isChecked && lcc.filter(el => el.valueType === "Net undiscounted costs").length > 0) {
                                                                            const value = selectedProductsLCC.filter(d => d.id === item.id)[0].data.filter(el => el.valueType === "Net undiscounted costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                            return (<td key={index}>{(item.data.netUndiscountedCosts - value).toFixed(2)}</td>)
                                                                        } else {
                                                                            return (<td key={index}>{item.data.netUndiscountedCosts}</td>)
                                                                        }
                                                                    } else {
                                                                        return (<td key={index}>-</td>)
                                                                    }
                                                                })()
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            }
                                            {
                                                (lccTotals.netPresentCosts || selectedProductsLCCTotals.some(e => e.data.netPresentCosts !== null)) && <tr onClick={() => selectTotal('netPresentCosts', isChecked)}>
                                                    <td>Net present costs</td>
                                                    {
                                                        (() => {
                                                            if (typeof (lccTotals.netPresentCosts) !== "undefined" && lccTotals.netPresentCosts !== null) {
                                                                if (isChecked) {
                                                                    return (<td>{lccTotals.netPresentCosts.toFixed(2)}</td>)
                                                                } else if (!isChecked && lcc.filter(el => el.valueType === "Net present costs").length > 0) {
                                                                    const value = lcc.filter(el => el.valueType === "Net present costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                    return (<td>{(lccTotals.netPresentCosts - value).toFixed(2)}</td>)
                                                                } else {
                                                                    return (<td>{lccTotals.netPresentCosts}</td>)
                                                                }
                                                            } else {
                                                                return (<td>-</td>)
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        selectedProductsLCCTotals.map((item, index) => {
                                                            return (
                                                                (() => {
                                                                    if (typeof (item.data.netPresentCosts) !== "undefined" && item.data.netPresentCosts !== null) {
                                                                        if (isChecked) {
                                                                            return (<td key={index}>{item.data.netPresentCosts.toFixed(2)}</td>)
                                                                        } else if (!isChecked && lcc.filter(el => el.valueType === "Net present costs").length > 0) {
                                                                            const value = selectedProductsLCC.filter(d => d.id === item.id)[0].data.filter(el => el.valueType === "Net present costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                            return (<td key={index}>{(item.data.netPresentCosts - value).toFixed(2)}</td>)
                                                                        } else {
                                                                            return (<td key={index}>{item.data.netPresentCosts}</td>)
                                                                        }
                                                                    } else {
                                                                        return (<td key={index}>-</td>)
                                                                    }
                                                                })()
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="title2">{titleLCC}</div>
                                </div>
                            </div>
                            {
                                errors.length > 0 && errors.length < (selectedProductsLCC.length + 1) && <div className="row mt-3">
                                    <div className="col">
                                        {
                                            errors.map((element, index) => {
                                                return (
                                                    <div key={index} className="row">
                                                        <div className="col">
                                                            <div className="alert alert-warning">
                                                                Note that no disaggregated data has been entered into the OOF for product <b>{element}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                errors.length > 0 && errors.length === (selectedProductsLCC.length + 1) && <div className="row mt-3">
                                    <div className="col">
                                        <div className="alert alert-warning">
                                            Note that no disaggregated data has been entered into the OOF for the selected products
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                dataLCC !== null && errors.length < (selectedProductsLCC.length + 1) && <><div className="row mt-3">
                                    <div className="col">
                                        <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataLCC} ref={chartRfLCC} options={optionsLCC} onClick={onClick2} />
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col">
                                            If you select one of the economic inventory categories, the results per life cycle stage will be shown:
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                dataGraphBar !== null && <><div className="row mt-5">
                                    <div className="col-md-6">
                                        <div className="title2">{title}</div>
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Bar className="mt-5" options={options3} data={dataGraphBar} />
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div >
            }
        </>
    );
}