export default function SLCARiskTableDetailedRow({ row, index }) {
    const convertExponential = (value) => {
        let decimals = 0;
        if (Math.floor(value.valueOf()) === value.valueOf()) {
            decimals = 0;
        } else {
            decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
        }
        if (decimals > 2) {
            return value.toExponential(3);
        } else {
            return value;
        }
    }
    return (
        <tr>
            <td></td>
            <td className="ps-7 pb-3 pt-3 min-w-100px align-middle text-start">{row.stage}</td>
            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{row.unit.split(" ").map((text, i) => {
                if (text === "m3") {
                    return <span key={`${index}-${i}`}>m<sup>3</sup></span>
                } else {
                    const new_text = text.replace("eq", "<sub>eq</sub>");
                    return (
                        <span key={`${index}-${i}`} dangerouslySetInnerHTML={{
                            __html: `${new_text} `
                        }} />
                    )
                }
            })}</td>
            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-center">{row.value !== null ? convertExponential(row.value) : '-'}</td>
        </tr>
    );
}