import User from "./User";
import './admin_header.css';
import logo from '../../logo_white.png';
import * as giIcons from "react-icons/gi";

export default function Header() {
    const toggleBar = () => {
        const wrapper = document.querySelector(".admin_wrapper");
        if (wrapper.classList.contains('hide')) {
            wrapper.classList.remove("hide");
        } else {
            wrapper.classList.add("hide");
        }
    }

    return (
        <>
            <div className="admin_header">
                <div className="logo"><img src={logo} alt="Orienting" /></div>
                <div className="menu">
                    <div className="hamburger" onClick={toggleBar}>
                        <giIcons.GiHamburgerMenu />
                    </div>
                </div>
                <div className="header-menu">
                    <User />
                </div>
            </div>
        </>
    )
};