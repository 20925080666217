import React, { useState, useEffect, useCallback, useRef } from "react";
import Select from 'react-select';
import axios from 'axios';
import { Form, Table } from 'react-bootstrap';
import "./lcsa.css";

export default function LSCAPage2({ product, products, selected }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const selectInputRef = useRef();
    const [lcaList, setLcaList] = useState([]);
    const [lccList, setLccList] = useState([]);
    const [performanceCategoryList, setPerformanceCategoryList] = useState([]);
    const [riskCategoryList, setRiskCategoryList] = useState([]);
    const [lcaCategoryList, setLcaCategoryList] = useState([]);
    const [slcaCategoryList, setSlcaCategoryList] = useState([]);
    const [slcaRiskList, setSlcaRiskList] = useState([]);
    const [slcaPerformanceList, setSlcaPerformanceList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectedOptionsLCA, setSelectedOptionsLCA] = useState(null);
    const [selectedOptionsSLCAType, setSelectedOptionsSLCAType] = useState(null);
    const [selectedOptionsSLCA, setSelectedOptionsSLCA] = useState(null);
    const [valueTypeSAW, setValueTypeSAW] = useState("equal");
    const [valueTypeMAVT, setValueTypeMAVT] = useState("equal");
    const [valueTypeTOPSIS, setValueTypeTOPSIS] = useState("equal");
    const [scores, setScores] = useState([]);
    const indicators = ["SAW", "MAVT", "TOPSIS"];
    const [type, setType] = useState('-');
    const [weights, setWeights] = useState({});
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const [errorMaut, setErrorMaut] = useState(false);

    const handleChange = (data) => {
        setSelectedOptions(data);
        setType('-');
        if (data.filter(e => e.value === 'slca').length === 0) {
            setSelectedOptionsSLCA(null);
            setSelectedOptionsSLCAType(null);
        }
    };

    const handleChangeLCA = (data) => {
        setSelectedOptionsLCA(data);
        setType('-');
    };

    const handleChangeSLCA = (data) => {
        setSelectedOptionsSLCA(data);
        setType('-');
    };

    const handleChangeSLCAType = (data) => {
        setSelectedOptionsSLCAType(data);
        if (data.value === 'Risk assesment') {
            setSlcaCategoryList(riskCategoryList);
        } else {
            setSlcaCategoryList(performanceCategoryList);
        }
        setType('-');
        setSelectedOptionsSLCA(null);
        if (selectInputRef.current) {
            selectInputRef.current.clearValue();
        }
    };

    const options = [
        { value: 'lca', label: 'LCA' },
        { value: 'lcc', label: 'LCC' },
        { value: 'slca', label: 'S-LCA' }
    ];

    const optionsLCC = [
        { value: 'Total undiscounted costs', label: 'Total undiscounted costs' }
    ];

    const slcaType = [
        { value: 'Performance assesment', label: 'Performance assesment' },
        { value: 'Risk assesment', label: 'Risk assesment' }
    ]

    const changeValue = (type) => {
        setType(type);
        setValueTypeSAW('equal');
        setValueTypeMAVT('equal');
        setValueTypeTOPSIS('equal');
        setErrorMaut(false);
        calculate();
    }

    const calculate = () => {
        const scores = getScores();
        for (let i = 0; i < scores.length; i++) {
            for (let j = 0; j < scores[i].results.length; j++) {
                //saw results
                if (scores[i].results[j].score !== null) {
                    if (scores[i].results[j].score < 0) {
                        scores[i].results[j].score_saw = Math.abs(scores[i].results[j].score);
                    } else {
                        scores[i].results[j].score_saw = scores[i].results[j].score;
                    }
                    scores[i].results[j].score_saw = 1 / scores[i].results[j].score_saw;
                }
            }
        }
        for (let i = 0; i < scores.length; i++) {
            for (let j = 0; j < scores[i].results.length; j++) {
                //normalize results
                const map_saw = scores.map(function (o) { return o.results[j].score_saw; });
                const map_mavt = scores.map(function (o) { return o.results[j].score; });
                if (scores[i].results[j].score !== null) {
                    scores[i].results[j].normalized_saw = scores[i].results[j].score_saw / Math.max.apply(Math, map_saw);
                    const normalized_mavt = (Math.max.apply(Math, map_mavt) - scores[i].results[j].score) / (Math.max.apply(Math, map_mavt) - Math.min.apply(Math, map_mavt));
                    if (isNaN(normalized_mavt)) {
                        scores[i].results[j].normalized_mavt = 0;
                        setErrorMaut(true);
                    }
                    else {
                        scores[i].results[j].normalized_mavt = normalized_mavt;
                    }
                    scores[i].results[j].score_topsis = scores[i].results[j].score / sqrt(map_mavt);
                }
            }
        }
        setScores(scores);
    }

    function sqrt(array) {
        const sumaCuadrados = array.reduce((acumulador, elemento) => {
            return acumulador + Math.pow(elemento, 2);
        }, 0);
        const raizSumaCuadrados = Math.sqrt(sumaCuadrados);
        return raizSumaCuadrados;
    }

    Array.prototype.calculateTopsis = function () {
        for (let i = 0; i < this.length; i++) {
            for (let j = 0; j < this[i].results.length; j++) {
                if (valueTypeTOPSIS === 'equal') {
                    this[i].results[j].normalized_topsis = this[i].results[j].score_topsis / this[i].results.length;
                } else {
                    this[i].results[j].normalized_topsis = this[i].results[j].score_topsis * weights[`topsis${j}`];
                }
            }
        }
        const max_topsis = [];
        const min_topsis = [];
        for (let j = 0; j < this[0].results.length; j++) {
            const map_topsis = scores.map(function (o) { return o.results[j].normalized_topsis; });
            max_topsis.push(Math.max.apply(Math, map_topsis));
            min_topsis.push(Math.min.apply(Math, map_topsis));
        }

        for (let i = 0; i < this.length; i++) {
            let sum_max = 0;
            let sum_min = 0;
            for (let j = 0; j < this[i].results.length; j++) {
                this[i].results[j].max_d_topsis = Math.pow(this[i].results[j].normalized_topsis - min_topsis[j], 2);
                this[i].results[j].min_d_topsis = Math.pow(this[i].results[j].normalized_topsis - max_topsis[j], 2);
                sum_max += this[i].results[j].max_d_topsis;
                sum_min += this[i].results[j].min_d_topsis;
            }
            this[i].max_d_topsis = Math.sqrt(sum_max);
            this[i].min_d_topsis = Math.sqrt(sum_min);
            this[i].single_score_topsis = this[i].min_d_topsis / (this[i].max_d_topsis + this[i].min_d_topsis);
        }
        return this;
    }

    const getScores = () => {
        const array = [localStorage.getItem("product"), ...selected];
        const weights = {};
        const scores = [];
        for (let i = 0; i < array.length; i++) {
            weights[`saw${i}`] = null;
            const score = { id: array[i], results: [] };
            if (selectedOptions.filter(e => e.value === 'lca').length > 0) {
                const lca = lcaList.filter(e => e.id === array[i])[0].data;
                for (let j = 0; j < lca.length; j++) {
                    const category = lca[j].category;
                    if (selectedOptionsLCA.filter(e => e.value === category).length > 0) {
                        const sc = lca[j].value;
                        const result = { category: category, score: sc };
                        score.results.push(result);
                    }
                }
            }
            if (selectedOptions.filter(e => e.value === 'lcc').length > 0) {
                const lcc = lccList.filter(e => e.id === array[i])[0].data;
                const result = { category: "Total undiscounted costs", score: lcc.lccSingleScore };
                score.results.push(result);
            }
            if (selectedOptions.filter(e => e.value === 'slca').length > 0) {
                if (selectedOptionsSLCAType.value === 'Performance assesment') {
                    const slca = slcaPerformanceList.filter(e => e.id === array[i])[0].data;
                    const results = [];
                    for (let j = 0; j < slca.length; j++) {
                        for (let k = 0; k < slca[j].items.length; k++) {
                            const topic = `${slca[j].stakeholder} - ${slca[j].items[k].topic}`;
                            if (selectedOptionsSLCA.filter(e => e.value === topic).length > 0) {
                                const sc = slca[j].items[k].aggregatedScore;
                                let v = 0;
                                if (sc === 2) {
                                    v = 1;
                                } else if (sc === 1) {
                                    v = 2;
                                } else if (sc === 0) {
                                    v = 3;
                                } else if (sc === -1) {
                                    v = 4;
                                } else if (sc === 2) {
                                    v = 5;
                                }
                                const result = { category: topic, score: v };
                                results.push(result);
                            }
                        }
                    }
                    results.sort(function (a, b) {
                        if (a.category < b.category) {
                            return -1;
                        }
                        if (a.category > b.category) {
                            return 1;
                        }
                        return 0;
                    });
                    for (let j = 0; j < results.length; j++) {
                        score.results.push(results[j]);
                    }
                } else {
                    const slca = slcaRiskList.filter(e => e.id === array[i])[0].data;
                    const results = [];
                    for (let j = 0; j < slca.length; j++) {
                        for (let k = 0; k < slca[j].items.length; k++) {
                            const topic = slca[j].items[k].topic;
                            if (selectedOptionsSLCA.filter(e => e.value === topic).length > 0) {
                                const sc = slca[j].items[k].value;
                                const result = { category: topic, score: sc };
                                results.push(result);
                            }
                        }
                    }
                    results.sort(function (a, b) {
                        if (a.category < b.category) {
                            return -1;
                        }
                        if (a.category > b.category) {
                            return 1;
                        }
                        return 0;
                    });
                    for (let j = 0; j < results.length; j++) {
                        score.results.push(results[j]);
                    }
                }
            }
            scores.push(score);
        }
        setWeights(weights);
        return scores;
    }

    const fetchLcaData = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lca`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data.filter(e => e.weighValue !== null) };
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchSelectedSlcaRiskDatabaseData = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/risk/databases`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchSelectedSlcaRiskData = useCallback(async (id, database) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/risk/databases/${database}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchSlcaPerformanceData = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/performance`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchSelectedLcsaData = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lcsa`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    });

    const getLcaCategoryList = (lcas) => {
        const categories = [];
        for (let i = 0; i < lcas.length; i++) {
            const lca = lcas[i];
            const data = lca.data;
            for (let j = 0; j < data.length; j++) {
                const category = data[j].category;
                if (categories.indexOf(category) === -1) {
                    categories.push({ value: category, label: category });
                }
            }
        }
        categories.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        categories.forEach(function (item, index, object) {
            if (categories.filter(val => val.value === item.value)) {
                object.splice(index, 1);
            }
        });
        setLcaCategoryList(categories);
    }

    const getSlcaCategoryList = (slcas) => {
        const categories = [];
        const cats = [];
        for (let i = 0; i < slcas.length; i++) {
            const newDataOthers = slcas[i].data.map(c => c.items).flat(2);
            for (let j = 0; j < newDataOthers.length; j++) {
                categories.push(newDataOthers[j].topic);
            }
        }

        const counts = {};
        categories.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        const entriesList = Object.entries(counts);
        const filteredCategories = entriesList.filter(([_, val]) => val === slcas.length).map(([key, _]) => key);

        filteredCategories.sort((a, b) => a.localeCompare(b));
        const newCategories = [...new Set(filteredCategories)];
        for (let j = 0; j < newCategories.length; j++) {
            const cat = newCategories[j];
            cats.push({ value: cat, label: cat });
        }
        setRiskCategoryList(cats);
    }

    const getSlcaPerformanceList = (slcas) => {
        const categories = [];
        const cats = [];
        for (let i = 0; i < slcas.length; i++) {
            for (let j = 0; j < slcas[i].data.length; j++) {
                for (let k = 0; k < slcas[i].data[j].items.length; k++) {
                    categories.push(`${slcas[i].data[j].stakeholder} - ${slcas[i].data[j].items[k].topic}`);
                }
            }
        }

        const counts = {};
        categories.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        const entriesList = Object.entries(counts);
        const filteredCategories = entriesList.filter(([_, val]) => val === slcas.length).map(([key, _]) => key);

        filteredCategories.sort((a, b) => a.localeCompare(b));
        const newCategories = [...new Set(filteredCategories)];
        for (let j = 0; j < newCategories.length; j++) {
            const cat = newCategories[j];
            cats.push({ value: cat, label: cat });
        }
        setPerformanceCategoryList(cats);
    }

    const changeWeightValue = (type, index, value) => {
        if (type === 'saw') {
            if (!isNaN(value) && value >= 0 && value <= 1) {
                setWeights({ ...weights, [`saw${index}`]: value });
            } else if (!isNaN(value) && value < 0) {
                setWeights({ ...weights, [`saw${index}`]: 0 });
            } else if (!isNaN(value) && value > 1) {
                setWeights({ ...weights, [`saw${index}`]: 1 });
            } else {
                setWeights({ ...weights, [`saw${index}`]: null });
            }
        } else if (type === 'mavt') {
            if (!isNaN(value) && value >= 0 && value <= 1) {
                setWeights({ ...weights, [`mavt${index}`]: value });
            } else if (!isNaN(value) && value < 0) {
                setWeights({ ...weights, [`mavt${index}`]: 0 });
            } else if (!isNaN(value) && value > 1) {
                setWeights({ ...weights, [`mavt${index}`]: 1 });
            } else {
                setWeights({ ...weights, [`mavt${index}`]: null });
            }
        } else if (type === 'topsis') {
            if (!isNaN(value) && value >= 0 && value <= 1) {
                setWeights({ ...weights, [`topsis${index}`]: value });
            } else if (!isNaN(value) && value < 0) {
                setWeights({ ...weights, [`topsis${index}`]: 0 });
            } else if (!isNaN(value) && value > 1) {
                setWeights({ ...weights, [`topsis${index}`]: 1 });
            } else {
                setWeights({ ...weights, [`topsis${index}`]: null });
            }
        }
    }

    const convertExponential = (value) => {
        if (isNaN(value)) {
            return value;
        }
        let decimals = 0;
        if (Math.floor(value.valueOf()) === value.valueOf()) {
            decimals = 0;
        } else {
            decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
        }
        if (decimals > 2) {
            return value.toExponential(3);
        } else {
            return value;
        }
    }

    const calculateSaw = (value, index, total) => {
        if (valueTypeSAW === 'equal') {
            return convertExponential(value / total);
        } else {
            return convertExponential(value * weights[`saw${index}`]);
        }
    }

    const calculateMavt = (value, index, total) => {
        if (valueTypeMAVT === 'equal') {
            return convertExponential(value / total);
        } else {
            return convertExponential(value * weights[`mavt${index}`]);
        }
    }

    const getData = useCallback(async () => {
        const array = [localStorage.getItem("product"), ...selected];
        const lcas = [];
        const lccs = [];
        const performances = [];
        const risks = [];
        const totals = [];
        for (let i = 0; i < array.length; i++) {
            const result = await fetchLcaData(array[i]);
            const lcc = await fetchSelectedLcsaData(array[i]);
            const performance = await fetchSlcaPerformanceData(array[i]);
            const risk = await fetchSelectedSlcaRiskDatabaseData(array[i]);
            lcas.push(result);
            lccs.push(lcc);
            performances.push(performance);
            for (let j = 0; j < risk.length; j++) {
                totals.push(risk[j].database);
            }
        }
        setLcaList(lcas);
        setLccList(lccs);
        setSlcaPerformanceList(performances);
        const counts = {};
        const databases = [];
        totals.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        if ((counts["SHDB v5"] !== undefined && counts["SHDB v5"] === array.length) || (counts["PSILCA"] !== undefined && counts["PSILCA"] === array.length) ||
            (counts["RepRisk"] !== undefined && counts["RepRisk"] === array.length) || (counts["Others"] !== undefined && counts["Others"] === array.length)) {
            if (counts["SHDB v5"] !== undefined && counts["SHDB v5"] === array.length) {
                databases.push("SHDB v5");
            }
            if (counts["PSILCA"] !== undefined && counts["PSILCA"] === array.length) {
                databases.push("PSILCA");
            }
            if (counts["RepRisk"] !== undefined && counts["RepRisk"] === array.length) {
                databases.push("RepRisk");
            }
            if (counts["Others"] !== undefined && counts["Others"] === array.length) {
                databases.push("Others");
            }
        }
        await getLcaCategoryList(lcas);
        await getSlcaPerformanceList(performances);
        if (databases.length === 1) {
            for (let i = 0; i < array.length; i++) {
                const riskData = await fetchSelectedSlcaRiskData(array[i], databases[0]);
                risks.push(riskData);
            }
            setSlcaRiskList(risks);
            await getSlcaCategoryList(risks);
        }
    });

    const sumCategories = (options, lca, lcc, slca) => {
        let sum = 0;
        if (options.filter(e => e.value === 'lca').length > 0 && lca !== null && lca.length > 0) {
            sum += lca.length;
        }
        if (options.filter(e => e.value === 'lcc').length > 0 && lcc !== null && lcc.length > 0) {
            sum += lcc.length;
        }
        if (options.filter(e => e.value === 'slca').length > 0 && slca !== null && slca.length > 0) {
            sum += slca.length;
        }
        return sum;
    }

    const sawSum = () => {
        let sum = 0;
        for (let i = 0; i < scores[0].results.length; i++) {
            sum += weights[`saw${i}`] === null ? 0 : Number(weights[`saw${i}`]);
        }
        return sum;
    }

    const mavtSum = () => {
        let sum = 0;
        for (let i = 0; i < scores[0].results.length; i++) {
            sum += weights[`mavt${i}`] === null ? 0 : Number(weights[`mavt${i}`]);
        }
        return sum;
    }

    const topsisSum = () => {
        let sum = 0;
        for (let i = 0; i < scores[0].results.length; i++) {
            sum += weights[`topsis${i}`] === null ? 0 : Number(weights[`topsis${i}`]);
        }
        return sum;
    }

    const changeValueTypeSAW = (type) => {
        if (type === "equal") {
            const weights = {};
            for (let i = 0; i < scores[0].results.length; i++) {
                weights[`saw${i}`] = null;
            }
            setWeights(weights);
        }
        setValueTypeSAW(type);
    }

    const changeValueTypeMAVT = (type) => {
        if (type === "equal") {
            const weights = {};
            for (let i = 0; i < scores[0].results.length; i++) {
                weights[`saw${i}`] = null;
            }
            setWeights(weights);
        }
        setValueTypeMAVT(type);
    }

    const changeValueTypeTOPSIS = (type) => {
        if (type === "equal") {
            const weights = {};
            for (let i = 0; i < scores[0].results.length; i++) {
                weights[`topsis${i}`] = null;
            }
            setWeights(weights);
        }
        setValueTypeTOPSIS(type);
    }

    const calculateSingleScoreSaw = (values) => {
        if (valueTypeSAW === 'equal') {
            let sum = 0;
            for (let i = 0; i < values.length; i++) {
                sum += values[i].normalized_saw / values.length;
            }
            return convertExponential(sum);
        } else {
            let sum = 0;
            for (let i = 0; i < values.length; i++) {
                sum += values[i].normalized_saw * weights[`saw${i}`];
            }
            return convertExponential(sum);
        }
    }

    const calculateSingleScoreMavt = (values) => {
        if (valueTypeMAVT === 'equal') {
            let sum = 0;
            for (let i = 0; i < values.length; i++) {
                sum += values[i].normalized_mavt / values.length;
            }
            return convertExponential(sum);
        } else {
            let sum = 0;
            for (let i = 0; i < values.length; i++) {
                sum += values[i].normalized_mavt * weights[`mavt${i}`];
            }
            return convertExponential(sum);
        }
    }

    const circlesOverlapInY = (circle1, circle2) => {
        const distanceY = Math.abs(circle1.y - circle2.y);
        const sumOfRadii = circle1.radius + circle2.radius;
        return distanceY <= sumOfRadii;
    }

    useEffect(() => {
        document.getElementById("splash").style.display = "block";
        if (selected.length > 0) {
            getData();
        }
        document.getElementById("splash").style.display = "none";
    }, [selected, fetchLcaData]);

    return (
        <div className="card-body">
            <div className="mt-3 w-100">
                <div className="row">
                    <div className="col">
                        In this dashboard, first, a selection of the material indicators per domain must be conducted. Subsequent, the individual indicators for environmental, economic, and social domains are further aggregated into a single LCSA indicator using any of the selected MCDA methods. Likewise, some weights have been set by default, that can be modified to observe the impact of varying weights on the outcomes.
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <div className="alert alert-info">
                            <b>Relevant information:</b> The reference scale selected for the performance assessment in the social domain consists of a five-level (i.e., scores), from -2 to +2, expressing a
                            positive (qualitative) performance (i.e., the higher the better). However, the indicators in the environmental domain express a negative (quantitative)
                            performance (i.e., the higher the worse). To allow for the aggregation of the indicators of the different sustainability domains in a meaningful way,
                            the scales need to be harmonized. The reference scales used for the performance assessment of the social domain have been adapted in this dashboard
                            to follow a quantitative negative performance, following the logic of the environmental and, generally, economic domains.
                            The following equivalencies have been applied for the social performance: -2 (= 5); -1 (= 4); 0 (=3); 1( =2); (2 = 1).
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        Please select the domains you want to include in the integration:
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4">
                        <Select className="select-form" isMulti options={options} onChange={handleChange} />
                    </div>
                </div>
                {
                    selectedOptions !== null && selectedOptions.length > 0 && <>
                        <div className="row mt-4">
                            <div className="col">
                                Please select the indicators you want to include in the integration for each domain:
                            </div>
                        </div>
                        <div className="row mt-3">
                            {
                                selectedOptions !== null && selectedOptions.filter(e => e.value === 'lca').length > 0 &&
                                <div className="col-md-4">
                                    <label className="form-label">LCA</label>
                                    <Select isOptionDisabled={() => selectedOptionsLCA !== null && selectedOptionsLCA.length >= 3}
                                        className="select-form"
                                        isMulti
                                        options={lcaCategoryList}
                                        onChange={handleChangeLCA} />
                                </div>
                            }
                            {
                                selectedOptions !== null && selectedOptions.filter(e => e.value === 'lcc').length > 0 &&
                                <div className="col-md-4">
                                    <label className="form-label">LCC</label>
                                    <Select isDisabled={true} isMulti defaultValue={optionsLCC[0]} className="select-form" options={optionsLCC} />
                                </div>
                            }
                            {
                                selectedOptions !== null && selectedOptions.filter(e => e.value === 'slca').length > 0 &&
                                <div className="col-md-4">
                                    <label className="form-label">S-LCA</label>
                                    <Select
                                        className="select-form"
                                        options={slcaType}
                                        onChange={handleChangeSLCAType} />
                                    {
                                        selectedOptionsSLCAType !== null && <Select ref={selectInputRef} isOptionDisabled={() => selectedOptionsSLCA !== null && selectedOptionsSLCA.length >= 3}
                                            className="select-form mt-3"
                                            isMulti
                                            options={slcaCategoryList}
                                            onChange={handleChangeSLCA} />
                                    }
                                </div>
                            }
                        </div>
                        {
                            ((selectedOptions.filter(e => e.value === 'lca').length > 0 && selectedOptionsLCA !== null && selectedOptionsLCA.length > 0) ||
                                (selectedOptions.filter(e => e.value === 'lcc').length > 0 && optionsLCC !== null && optionsLCC.length > 0) ||
                                (selectedOptions.filter(e => e.value === 'slca').length > 0 && selectedOptionsSLCA !== null && selectedOptionsSLCA.length > 0)) &&
                            (sumCategories(selectedOptions, selectedOptionsLCA, optionsLCC, selectedOptionsSLCA) >= 2) &&
                            <>
                                <div className="row mt-4">
                                    <div className="col">
                                        Which MCDA method would you like to apply to aggregate the LCSA results?
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <Form.Select
                                            value={type}
                                            onChange={(e) => { changeValue(e.currentTarget.value) }}
                                        >
                                            {(() => {
                                                if (type === "-") {
                                                    return (
                                                        <option hidden disabled value="-"></option>
                                                    );
                                                }
                                            })()}
                                            {
                                                indicators.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item}>{item}</option>
                                                    );
                                                })
                                            }
                                        </Form.Select>
                                    </div>
                                </div>
                                {
                                    type === 'SAW' && <div className="row mt-4">
                                        <div className="col-md-2">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="title2 mt-3 mb-4">Weights</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col text-center">
                                                    <button type="button" className={valueTypeSAW === "equal" ? "btn btn-xs btn-primary" : "btn btn-xs btn-secondary"} onClick={() => changeValueTypeSAW("equal")}>By default</button>
                                                    <button type="button" className={valueTypeSAW === "custom" ? "btn btn-xs btn-primary ms-2" : "btn btn-xs btn-secondary ms-2"} onClick={() => changeValueTypeSAW("custom")}>Customised</button>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col">
                                                    <Table className="weight-table">
                                                        <tbody>
                                                            {
                                                                scores[0].results.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{item.category}</td>
                                                                            <td>
                                                                                {
                                                                                    valueTypeSAW === "equal" && <Form.Control
                                                                                        type="text"
                                                                                        value={`1/${scores[0].results.length}`}
                                                                                        disabled
                                                                                    />
                                                                                }
                                                                                {
                                                                                    valueTypeSAW === "custom" && <Form.Control
                                                                                        type="number"
                                                                                        min={0}
                                                                                        max={1}
                                                                                        step={0.01}
                                                                                        value={weights[`saw${index}`] || ''}
                                                                                        onChange={({ target: { value } }) => {
                                                                                            changeWeightValue('saw', index, value);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-md-1">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="title2 mt-3 mb-4">LCA, LCC and S-LCA single scores</div>
                                                </div>
                                            </div>
                                            {
                                                (valueTypeSAW === 'equal' || (sawSum() === 1)) && <div className="row mt-4">
                                                    <div className="col">
                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered">
                                                            <thead>
                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                    {
                                                                        scores[0].results.map((item, index) => {
                                                                            return (
                                                                                <th key={index} className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">{item.category}</th>
                                                                            );
                                                                        })
                                                                    }
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Single score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    scores.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                {
                                                                                    item.results.map((item2, index2) => {
                                                                                        return (
                                                                                            <td key={`${index}-${index2}`} className="p-0 pb-2 pt-2 align-middle text-center">{calculateSaw(item2.normalized_saw, index, item.results.length)}</td>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateSingleScoreSaw(item.results)}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    type === 'SAW' && (valueTypeSAW === 'equal' || (sawSum() === 1)) && <div className="row mt-4">
                                        <div className="col-md-3 offset-md-3">
                                            <div className="chart mt-4 mb-4">
                                                <div className="values">
                                                    <ul>
                                                        <li>1,00E+00</li>
                                                        <li>9,00E-01</li>
                                                        <li>8,00E-01</li>
                                                        <li>7,00E-01</li>
                                                        <li>6,00E-01</li>
                                                        <li>5,00E-01</li>
                                                        <li>4,00E-01</li>
                                                        <li>3,00E-01</li>
                                                        <li>2,00E-01</li>
                                                        <li>1,00E-01</li>
                                                        <li>0,00E-00</li>
                                                    </ul>
                                                </div>
                                                <div className="graph">
                                                    {(() => {
                                                        const results = [];
                                                        let i = 0;
                                                        const circles = [];
                                                        scores.map((item) => {
                                                            results.push((calculateSingleScoreSaw(item.results) * 400) - 25);
                                                        });

                                                        results.map((item, index) => {
                                                            const circle1 = { x: item, y: item + 50, radius: 50 };
                                                            let tots = 0;
                                                            results.forEach((item2, _) => {
                                                                const circle2 = { x: item2, y: item2 + 50, radius: 50 };
                                                                if (circlesOverlapInY(circle1, circle2)) {
                                                                    tots++;
                                                                }
                                                            })
                                                            if (tots > 1) {
                                                                let left = 0;
                                                                if (i === 0) {
                                                                    left = 20;
                                                                } else if (i === 1) {
                                                                    left = 80;
                                                                }
                                                                i++;
                                                                circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, left: `${left}px`, backgroundColor: colors[index] }}></div>);
                                                            } else {
                                                                circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, backgroundColor: colors[index] }}></div>);
                                                            }
                                                        })
                                                        return (
                                                            circles
                                                        );
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    type === 'MAVT' && errorMaut &&
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="alert alert-warning">
                                                Please, take into account that MAVT cannot be applied if two or more alternatives have an equal result for the same domain or criteria.
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    type === 'MAVT' && !errorMaut &&
                                    <div className="row mt-4">
                                        <div className="col-md-2">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="title2 mt-3 mb-4">Weights</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col text-center">
                                                    <button type="button" className={valueTypeMAVT === "equal" ? "btn btn-xs btn-primary" : "btn btn-xs btn-secondary"} onClick={() => changeValueTypeMAVT("equal")}>By default</button>
                                                    <button type="button" className={valueTypeMAVT === "custom" ? "btn btn-xs btn-primary ms-2" : "btn btn-xs btn-secondary ms-2"} onClick={() => changeValueTypeMAVT("custom")}>Customised</button>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col">
                                                    <Table className="weight-table">
                                                        <tbody>
                                                            {
                                                                scores[0].results.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{item.category}</td>
                                                                            <td>
                                                                                {
                                                                                    valueTypeMAVT === "equal" && <Form.Control
                                                                                        type="text"
                                                                                        value={`1/${scores[0].results.length}`}
                                                                                        disabled
                                                                                    />
                                                                                }
                                                                                {
                                                                                    valueTypeMAVT === "custom" && <Form.Control
                                                                                        type="number"
                                                                                        min={0}
                                                                                        max={1}
                                                                                        step={0.01}
                                                                                        value={weights[`mavt${index}`] || ''}
                                                                                        onChange={({ target: { value } }) => {
                                                                                            changeWeightValue('mavt', index, value);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-md-1">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="title2 mt-3 mb-4">LCA, LCC and S-LCA single scores</div>
                                                </div>
                                            </div>
                                            {
                                                (valueTypeMAVT === 'equal' || (mavtSum() === 1)) && <div className="row mt-4">
                                                    <div className="col">
                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered">
                                                            <thead>
                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                    {
                                                                        scores[0].results.map((item, index) => {
                                                                            return (
                                                                                <th key={index} className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">{item.category}</th>
                                                                            );
                                                                        })
                                                                    }
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Single score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    scores.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                {
                                                                                    item.results.map((item2, index2) => {
                                                                                        return (
                                                                                            <td key={`${index}-${index2}`} className="p-0 pb-2 pt-2 align-middle text-center">{calculateMavt(item2.normalized_mavt, index, item.results.length)}</td>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateSingleScoreMavt(item.results)}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    type === 'MAVT' && !errorMaut && (valueTypeMAVT === 'equal' || (mavtSum() === 1)) && <div className="row mt-4">
                                        <div className="col-md-3 offset-md-3">
                                            <div className="chart mt-4 mb-4">
                                                <div className="values">
                                                    <ul>
                                                        <li>1,00E+00</li>
                                                        <li>9,00E-01</li>
                                                        <li>8,00E-01</li>
                                                        <li>7,00E-01</li>
                                                        <li>6,00E-01</li>
                                                        <li>5,00E-01</li>
                                                        <li>4,00E-01</li>
                                                        <li>3,00E-01</li>
                                                        <li>2,00E-01</li>
                                                        <li>1,00E-01</li>
                                                        <li>0,00E-00</li>
                                                    </ul>
                                                </div>
                                                <div className="graph">
                                                    {(() => {
                                                        const results = [];
                                                        let i = 0;
                                                        const circles = [];
                                                        scores.map((item) => {
                                                            results.push((calculateSingleScoreMavt(item.results) * 400) - 25);
                                                        });

                                                        results.map((item, index) => {
                                                            const circle1 = { x: item, y: item + 50, radius: 50 };
                                                            let tots = 0;
                                                            results.forEach((item2, _) => {
                                                                const circle2 = { x: item2, y: item2 + 50, radius: 50 };
                                                                if (circlesOverlapInY(circle1, circle2)) {
                                                                    tots++;
                                                                }
                                                            })
                                                            if (tots > 1) {
                                                                let left = 0;
                                                                if (i === 0) {
                                                                    left = 20;
                                                                } else if (i === 1) {
                                                                    left = 80;
                                                                }
                                                                i++;
                                                                circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, left: `${left}px`, backgroundColor: colors[index] }}></div>);
                                                            } else {
                                                                circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, backgroundColor: colors[index] }}></div>);
                                                            }
                                                        })
                                                        return (
                                                            circles
                                                        );
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    type === 'TOPSIS' && <div className="row mt-4">
                                        <div className="col-md-2">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="title2 mt-3 mb-4">Weights</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col text-center">
                                                    <button type="button" className={valueTypeTOPSIS === "equal" ? "btn btn-xs btn-primary" : "btn btn-xs btn-secondary"} onClick={() => changeValueTypeTOPSIS("equal")}>By default</button>
                                                    <button type="button" className={valueTypeTOPSIS === "custom" ? "btn btn-xs btn-primary ms-2" : "btn btn-xs btn-secondary ms-2"} onClick={() => changeValueTypeTOPSIS("custom")}>Customised</button>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col">
                                                    <Table className="weight-table">
                                                        <tbody>
                                                            {
                                                                scores[0].results.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{item.category}</td>
                                                                            <td>
                                                                                {
                                                                                    valueTypeTOPSIS === "equal" && <Form.Control
                                                                                        type="text"
                                                                                        value={`1/${scores[0].results.length}`}
                                                                                        disabled
                                                                                    />
                                                                                }
                                                                                {
                                                                                    valueTypeTOPSIS === "custom" && <Form.Control
                                                                                        type="number"
                                                                                        min={0}
                                                                                        max={1}
                                                                                        step={0.01}
                                                                                        value={weights[`topsis${index}`] || ''}
                                                                                        onChange={({ target: { value } }) => {
                                                                                            changeWeightValue('topsis', index, value);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 offset-md-1">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="title2 mt-3 mb-4">LCA, LCC and S-LCA single scores</div>
                                                </div>
                                            </div>
                                            {
                                                (valueTypeTOPSIS === 'equal' || (topsisSum() === 1)) && <div className="row mt-4">
                                                    <div className="col">
                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered">
                                                            <thead>
                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                    {
                                                                        scores[0].results.map((item, index) => {
                                                                            return (
                                                                                <th key={index} className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">{item.category}</th>
                                                                            );
                                                                        })
                                                                    }
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">d+</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    scores.calculateTopsis().map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                {
                                                                                    item.results.map((item2, index2) => {
                                                                                        return (
                                                                                            <td key={`${index}-${index2}`} className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item2.max_d_topsis)}</td>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.max_d_topsis)}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered mt-4">
                                                            <thead>
                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                    {
                                                                        scores[0].results.map((item, index) => {
                                                                            return (
                                                                                <th key={index} className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">{item.category}</th>
                                                                            );
                                                                        })
                                                                    }
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">d-</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    scores.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                {
                                                                                    item.results.map((item2, index2) => {
                                                                                        return (
                                                                                            <td key={`${index}-${index2}`} className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item2.min_d_topsis)}</td>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.min_d_topsis)}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered mt-4">
                                                            <thead>
                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">RC</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    scores.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.single_score_topsis)}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    type === 'TOPSIS' && (valueTypeTOPSIS === 'equal' || (topsisSum() === 1)) && <div className="row mt-4">
                                        <div className="col-md-3 offset-md-3">
                                            <div className="chart mt-4 mb-4">
                                                <div className="values">
                                                    <ul>
                                                        <li>1,00E+00</li>
                                                        <li>9,00E-01</li>
                                                        <li>8,00E-01</li>
                                                        <li>7,00E-01</li>
                                                        <li>6,00E-01</li>
                                                        <li>5,00E-01</li>
                                                        <li>4,00E-01</li>
                                                        <li>3,00E-01</li>
                                                        <li>2,00E-01</li>
                                                        <li>1,00E-01</li>
                                                        <li>0,00E-00</li>
                                                    </ul>
                                                </div>
                                                <div className="graph">
                                                    {(() => {
                                                        const results = [];
                                                        let i = 0;
                                                        const circles = [];
                                                        scores.map((item) => {
                                                            results.push((item.single_score_topsis * 400) - 25);
                                                        });

                                                        results.map((item, index) => {
                                                            const circle1 = { x: item, y: item + 50, radius: 50 };
                                                            let tots = 0;
                                                            results.forEach((item2, _) => {
                                                                const circle2 = { x: item2, y: item2 + 50, radius: 50 };
                                                                if (circlesOverlapInY(circle1, circle2)) {
                                                                    tots++;
                                                                }
                                                            })
                                                            if (tots > 1) {
                                                                let left = 0;
                                                                if (i === 0) {
                                                                    left = 20;
                                                                } else if (i === 1) {
                                                                    left = 80;
                                                                }
                                                                i++;
                                                                circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, left: `${left}px`, backgroundColor: colors[index] }}></div>);
                                                            } else {
                                                                circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, backgroundColor: colors[index] }}></div>);
                                                            }
                                                        })
                                                        return (
                                                            circles
                                                        );
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
}