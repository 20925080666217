import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import ProductInfo from "../Layout/ProductInfo";
import './lca.css';
import Chart from 'chart.js/auto';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import { Bar, Doughnut, getElementsAtEvent, getElementAtEvent } from 'react-chartjs-2';
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as TbIcons from "react-icons/tb";
Chart.register(ChartjsPluginStacked100);

export default function LCAPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [product, setProduct] = useState(null);
    const [lca, setLca] = useState([]);
    const [error, setError] = useState(false);
    const [key, setKey] = useState('results');
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [dataGraph, setDataGraph] = useState(null);
    const [dataGraphBar, setDataGraphBar] = useState(null);
    const [total, setTotal] = useState(0);
    const [total2, setTotal2] = useState(0);
    const chartRf = useRef();
    const chartRf2 = useRef();
    const chartRfPrincipal = useRef();
    const [hotspotTitle, setHotspotTitle] = useState("");
    const [title, setTitle] = useState("");
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const options = {
        animation: {
            duration: 0
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (`${item.formattedValue}${item.dataset.unit[item.dataIndex]}`);
                    }
                }
            }
        }
    };
    const options2 = {
        indexAxis: "y",
        duration: 0,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        if (item.dataset.data[item.dataIndex] < 0.001) {
                            return (`${item.dataset.label}: ${item.formattedValue}% (${convertExponential(item.dataset.data[item.dataIndex])} ${item.dataset.unit[item.dataIndex]})`);
                        } else {
                            return (`${item.dataset.label}: ${item.formattedValue}% (${item.dataset.data[item.dataIndex]} ${item.dataset.unit[item.dataIndex]})`);
                        }
                    }
                },
            },
            stacked100: { enable: true },
        },
    };

    const options3 = {
        responsive: true,
        scales: {
            y: {
                ticks: {
                    callback: (val) => (convertExponential(val))
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        const index = item.dataset.data.findIndex((element) => element === item.raw);
                        if (item.raw < 0.001) {
                            return (`${item.dataset.label}: ${convertExponential(item.raw)} ${item.dataset.unit[index]}`);
                        } else {
                            return (`${item.dataset.label}: ${convertExponential(item.formattedValue)} ${item.dataset.unit[index]}`);
                        }
                    }
                },
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };

    const [selectedRow, setSelectedRow] = useState("");
    const [singleScore, setSingleScore] = useState("-");
    const [robustness, setRobustness] = useState("-");
    const [value, setValue] = useState("-");
    const [normalizedValue, setNormalizedValue] = useState("-");
    const [weightedValue, setWeightedValue] = useState("-");
    let categories = [];

    const fetchProduct = useCallback(async (id) => {
        const token = localStorage.getItem("token");
        axios.get(`${apiUrl}/products/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setProduct(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl]);

    const fetchLcaData = useCallback(async (id) => {
        const token = localStorage.getItem("token");
        axios.get(`${apiUrl}/products/${id}/lca`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            response.data = response.data.map(obj => ({ ...obj, isOpen: false }));
            setError(false);
            if ((response.data.filter(el => el.type === "Additional").length > 0 && response.data.map(el => el.weighValue).length >= 17) ||
                (response.data.filter(el => el.type === "Additional").length === 0 && response.data.map(el => el.weighValue).length >= 16)) {
                if (response.data.filter(el => el.category === "Land use, total").length > 0 &&
                    (response.data.filter(el => el.category === "Land use, biodiversity").length > 0 ||
                        response.data.filter(el => el.category === "Land use, soil quality").length > 0)) {
                    let total = response.data.reduce((n, { weighValue }) => n + weighValue, 0);
                    if (response.data.filter(el => el.category === "Land use, biodiversity").length > 0) {
                        total = total - response.data.filter(el => el.category === "Land use, biodiversity")[0].weighValue;
                    }
                    if (response.data.filter(el => el.category === "Land use, soil quality").length > 0) {
                        total = total - response.data.filter(el => el.category === "Land use, soil quality")[0].weighValue;
                    }
                    setSingleScore(convertExponential(total));
                } else {
                    setSingleScore(convertExponential(response.data.reduce((n, { weighValue }) => n + weighValue, 0)));
                }
            } else {
                setError(true);
            }
            setLca(response.data);
            createGraphs(response.data);
            createCharts(response.data);
        });
    }, [apiUrl]);

    const createGraphs = (results) => {
        const stages = categories.map(c => c);
        let datasets = [];
        for (let i = 0; i < stages.length; i++) {
            const dataset = { label: stages[i], data: [], unit: [], backgroundColor: colors[i] };
            for (let j = 0; j < results.length; j++) {
                let exist = false;
                for (let k = 0; k < results[j].items.length; k++) {
                    if (results[j].items[k].stage === stages[i]) {
                        if (results[j].items[k].weighValue === null) {
                            dataset.data.push(0);
                            dataset.unit.push("");
                        } else {
                            dataset.data.push(results[j].items[k].weighValue);
                            dataset.unit.push(results[j].items[k].unit);
                        }
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    dataset.data.push(0);
                    dataset.unit.push("");
                }
            }
            datasets.push(dataset);
        };
        let data = {
            labels: results.map(c => c.category),
            datasets: datasets
        }
        setDataGraph(data);
    }

    const createCharts = (results) => {
        let positives = results.filter(c => c.weighValue >= 0);
        let total = 0;
        if ((results.filter(el => el.type === "Additional").length > 0 && results.map(el => el.weighValue).length >= 17) ||
            (results.filter(el => el.type === "Additional").length === 0 && results.map(el => el.weighValue).length >= 16)) {
            if (results.filter(el => el.category === "Land use, total").length > 0 && (results.filter(el => el.category === "Land use, biodiversity").length > 0 || results.filter(el => el.category === "Land use, soil quality").length > 0)) {
                total = results.reduce((n, { weighValue }) => n + weighValue, 0);
                if (results.filter(el => el.category === "Land use, biodiversity").length > 0) {
                    total = total - results.filter(el => el.category === "Land use, biodiversity")[0].weighValue;
                    positives = positives.filter(el => el.category !== "Land use, biodiversity");
                }
                if (results.filter(el => el.category === "Land use, soil quality").length > 0) {
                    total = total - results.filter(el => el.category === "Land use, soil quality")[0].weighValue;
                    positives = positives.filter(el => el.category !== "Land use, soil quality");
                }
            } else {
                total = (positives.reduce((n, { weighValue }) => n + weighValue, 0));
            }
        } else {
            setError(true);
        }
        const newList = positives.filter(c => c.weighValue !== null);
        newList.sort((a, b) => (a.weighValue < b.weighValue) ? 1 : -1);
        let sum = 0;
        const data = {
            labels: [],
            datasets: [{
                data: [],
                unit: [],
                backgroundColor: []
            }]
        }
        for (let i = 0; i < newList.length; i++) {
            const percent = (newList[i].weighValue * 100) / total;
            sum = sum + percent;
            data.labels.push(newList[i].category);
            data.datasets[0].data.push(percent);
            data.datasets[0].unit.push("%");
            data.datasets[0].backgroundColor.push(colors[i]);
            if (sum >= 80) {
                setTotal(sum);
                break;
            }
        }
        setData(data);
    }

    const onClick = (event) => {
        const active = getElementsAtEvent(chartRf.current, event);
        if (active.length > 0) {
            const label = data.labels[active[0].index];
            setHotspotTitle(label);
            createCharts2(lca.filter(c => c.category === label)[0]);
        }
    }

    const onClickPrincipal = (event) => {
        const active = getElementAtEvent(chartRfPrincipal.current, event);
        if (active.length > 0) {
            const label = dataGraph.labels[active[0].index];
            setTitle(label);
            createChartsPrincipal(lca.filter(c => c.category === label)[0]);
        }
    }

    const createCharts2 = (result) => {
        const total = (result.items.reduce((n, { weighValue }) => n + weighValue, 0));
        const newList = result.items.filter(c => c.weighValue !== null);
        newList.sort((a, b) => (a.weighValue < b.weighValue) ? 1 : -1);
        let sum = 0;
        const data2 = {
            labels: [],
            datasets: [{
                data: [],
                unit: [],
                backgroundColor: []
            }]
        }
        for (let i = 0; i < newList.length; i++) {
            const percent = (newList[i].weighValue * 100) / total;
            sum = sum + percent;
            data2.labels.push(newList[i].stage);
            data2.datasets[0].data.push(percent);
            data2.datasets[0].unit.push("%");
            data2.datasets[0].backgroundColor.push(colors[i]);
            if (sum >= 80) {
                setTotal2(sum);
                break;
            }
        }
        setData2(data2);
    }

    const createChartsPrincipal = (result) => {
        const data = {
            labels: result.items.map(c => c.stage),
            datasets: [
                {
                    label: '',
                    data: result.items.map(x => x.value),
                    backgroundColor: colors,
                    unit: result.items.map(x => x.unit)
                },
            ],
        }
        setDataGraphBar(data);
    }

    const selected = (index, item) => {
        setSelectedRow(index);
        setRobustness(item.robustness ?? "-");
        setValue(convertExponential(item.value));
        setNormalizedValue(item.normalizedValue !== null ? convertExponential(item.normalizedValue) : "-");
        setWeightedValue(item.weighValue !== null ? convertExponential(item.weighValue) : "-");
    };

    const toggle = (type, category) => {
        const index = lca.findIndex(c => c.category === category && c.type === type);
        lca[index].isOpen = !lca[index].isOpen;
        setLca([...lca]);
    }

    const convertExponential = (value) => {
        if (value !== null) {
            let decimals = 0;
            if (Math.floor(value.valueOf()) === value.valueOf()) {
                decimals = 0;
            } else {
                decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
            }
            if (decimals > 2) {
                return value.toExponential(3);
            } else {
                return value;
            }
        }
        else {
            return "-"
        }
    }

    useEffect(() => {
        document.getElementById("splash").style.display = "block";
        const selected = localStorage.getItem("product");
        const token = localStorage.getItem("token");
        axios.get(`${apiUrl}/products/categories`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            categories = response.data;
            if (selected) {
                fetchProduct(selected);
                fetchLcaData(selected);
            }
            document.getElementById("splash").style.display = "none";
        }).catch(err => {
            console.log(err);
            document.getElementById("splash").style.display = "none";
        });
    }, [fetchProduct, fetchLcaData]);

    return (
        <>
            <ProductInfo product={product} />
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Life Cycle Assessment</div>
                        <p>
                            The potential environmental impacts of human actions on quality of natural environment, availability
                            of natural resources and human health are assessed with LCA. In this tool, the LCA is presented in 2
                            dashboards. In the results dashboard, the characterized results for all impact categories are
                            displayed, in addition to the normalized and weighted results in tabular and graphical format.
                            The hotspot’s analysis dashboard is based on the Product Environmental Footprint (PEF) 3.1 method (EC, 2021),
                            where the most relevant impact categories are presented, this means, the impact categories
                            contributing cumulatively to 80% of the total. In case one impact category is selected,
                            the life cycle stages contributing cumulatively to at least 80% of the selected impact category can be also displayed.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row pb-5 lca">
                <div className="col">
                    <Tabs id="controlled-tab" activeKey={key} onSelect={(k) => setKey(k)}>
                        <Tab eventKey="results" title="Results">
                            <div className="card card-flush h-md-100">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col vertical-tabs">
                                            <Tabs
                                                defaultActiveKey="data"
                                                id="vertical-tabs"
                                            >
                                                <Tab eventKey="data" title={<BsIcons.BsFillCalculatorFill />}>
                                                    {
                                                        error &&
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="alert alert-warning">
                                                                    At least 16 impact categories must be entered into the OOF for the single score to be calculated
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="row mt-4">
                                                        {
                                                            lca.filter(el => el.category === "Land use, total").length > 0 && <div className="col">
                                                                <p>
                                                                    In this dashboard, characterized values originate from the OOF. The single
                                                                    score result, normalized and weighted results, and robustness factors are
                                                                    calculated in this tool according to the PEF method 3.1 (EC, 2021).
                                                                </p>
                                                                <p>
                                                                    In normalization, characterized LCIA results are divided by normalization
                                                                    factors to calculate and to compare the magnitude of their contributions to
                                                                    the EF impact categories relative to a reference unit. In PEF, the
                                                                    normalization factors are expressed per capita based on a global value.
                                                                    Weighted results are created by multiplying the normalized results by a
                                                                    set of weighting factors which reflect the relative importance of considered
                                                                    life cycle impact categories. Weighted impact category results may be
                                                                    compared to assess their relative importance and aggregated
                                                                    to obtain a single score.
                                                                </p>
                                                            </div>
                                                        }
                                                        {
                                                            lca.filter(el => el.category === "Land use, total").length === 0 && <div className="col">
                                                                <p>
                                                                    This dashboard is built on the Product Environmental Footprint (PEF) method (EC, 2021),
                                                                    considering the updated land use impact assessment framework, in which, the land use is
                                                                    investigated through three independent indicators: biodiversity, biotic resources,
                                                                    and soil quality index. In this case, the normalization factors need to be introduced in the
                                                                    OOF, to be able to calculate the single score. Additional impact categories,
                                                                    which have not been considered previously, will also be displayed if they have been entered.
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-md-10">
                                                            {
                                                                <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 generalTable">
                                                                    <thead>
                                                                        <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                            <th className="p-0 pb-3 text-start"></th>
                                                                            <th className="p-0 pb-3 min-w-100px text-start">IMPACT CATEGORY</th>
                                                                            <th className="p-0 pb-3 min-w-100px text-start">UNIT</th>
                                                                            <th className="p-0 pb-3 min-w-100px text-center">CHARACTERISED VALUE</th>
                                                                            <th className="p-0 pb-3 min-w-100px text-center">NORMALIZED VALUE</th>
                                                                            <th className="p-0 pb-3 min-w-100px text-center">WEIGHTED VALUE</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(() => {
                                                                            const total = lca.filter(el => el.type === "Principal").length;
                                                                            return (
                                                                                lca.map((item, index) => {
                                                                                    const indx = `${index}`;
                                                                                    return [
                                                                                        <React.Fragment key={index}>
                                                                                            {(() => {
                                                                                                if (index === total) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                                <td colSpan="6"><hr></hr></td>
                                                                                                            </tr>
                                                                                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                                <td colSpan="6">ADDITIONAL IMPACT CATEGORIES</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            })()}
                                                                                            <tr className={selectedRow === indx && index === (total - 1) ? "active border-bottom-0" : index === (total - 1) ? "border-bottom-0" : selectedRow === indx ? "active" : ""} key={indx}>
                                                                                                <td className="p-0 pb-3 pt-3 ps-1 w-50px align-middle text-start">
                                                                                                    {(() => {
                                                                                                        if (item.isOpen) {
                                                                                                            return (
                                                                                                                <AiIcons.AiOutlineMinusSquare onClick={() => toggle(item.type, item.category)} />
                                                                                                            )
                                                                                                        }
                                                                                                        else {
                                                                                                            return (
                                                                                                                <AiIcons.AiOutlinePlusSquare onClick={() => toggle(item.type, item.category)} />
                                                                                                            )
                                                                                                        }
                                                                                                    })()}
                                                                                                </td>
                                                                                                {(() => {
                                                                                                    if (item.category === 'Climate change, biogenic' || item.category === 'Climate change, fossil' || item.category === 'Climate change, land use and land use change') {
                                                                                                        return (
                                                                                                            <td className="p-0 pb-3 pt-3 ps-2 min-w-100px align-middle text-start" onClick={() => selected(indx, item)}><TbIcons.TbPointFilled /> {item.category}</td>
                                                                                                        )
                                                                                                    }
                                                                                                    else {
                                                                                                        return (
                                                                                                            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start" onClick={() => selected(indx, item)}>{item.category}</td>
                                                                                                        )
                                                                                                    }
                                                                                                })()}

                                                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start" onClick={() => selected(indx, item)}>
                                                                                                    {item.items[0].unit.split(" ").map((text, i) => {
                                                                                                        if (text === "m3") {
                                                                                                            return <span key={`${indx}-${i}`}>m<sup>3</sup> </span>
                                                                                                        } else {
                                                                                                            let new_text = text.replace("eq", "<sub>eq</sub>");
                                                                                                            new_text = new_text.replace("CO2", "CO<sub>2</sub>");
                                                                                                            new_text = new_text.replace("U235", "U<sup>235</sup>");
                                                                                                            new_text = new_text.replace("m3", "m<sup>3</sup>");
                                                                                                            return (
                                                                                                                <span key={`${indx}-${i}`} dangerouslySetInnerHTML={{
                                                                                                                    __html: `${new_text} `
                                                                                                                }} />
                                                                                                            )
                                                                                                        }
                                                                                                    })}
                                                                                                </td>
                                                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-center" onClick={() => selected(indx, item)}>{item.value !== null ? convertExponential(item.value) : "-"}</td>
                                                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-center" onClick={() => selected(indx, item)}>{item.normalizedValue !== null ? convertExponential(item.normalizedValue) : "-"}</td>
                                                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-center" onClick={() => selected(indx, item)}>{item.weighValue !== null ? convertExponential(item.weighValue) : "-"}</td>
                                                                                            </tr>
                                                                                        </React.Fragment>,
                                                                                        <React.Fragment key={`${index}-sub`}>
                                                                                            {
                                                                                                item.isOpen && item.items.map((item2, index2) => {
                                                                                                    const indx2 = `${indx}-sub-${index2}`;
                                                                                                    return (
                                                                                                        <tr className={selectedRow === indx2 ? "active" : ""} key={indx2} onClick={() => selected(indx2, item2)}>
                                                                                                            <td className="w-50px"></td>
                                                                                                            <td className="p-0 ps-5 pb-3 pt-3 min-w-100px align-middle text-start">{item2.stage}</td>
                                                                                                            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item2.unit.split(" ").map((text, i) => {
                                                                                                                if (text === "m3") {
                                                                                                                    return <span key={`${index}-${i}`}>m<sup>3</sup></span>
                                                                                                                } else {
                                                                                                                    let new_text = text.replace("eq", "<sub>eq</sub>");
                                                                                                                    new_text = new_text.replace("CO2", "CO<sub>2</sub>");
                                                                                                                    new_text = new_text.replace("U235", "U<sup>235</sup>");
                                                                                                                    new_text = new_text.replace("m3", "m<sup>3</sup>");
                                                                                                                    return (
                                                                                                                        <span key={`${index}-${i}`} dangerouslySetInnerHTML={{
                                                                                                                            __html: `${new_text} `
                                                                                                                        }} />
                                                                                                                    )
                                                                                                                }
                                                                                                            })}</td>
                                                                                                            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-center">{item2.value !== null ? convertExponential(item2.value) : "-"}</td>
                                                                                                            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-center">{item2.normalizedValue !== null ? convertExponential(item2.normalizedValue) : "-"}</td>
                                                                                                            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-center">{item2.weighValue !== null ? convertExponential(item2.normalizedValue) : "-"}</td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </React.Fragment>
                                                                                    ]
                                                                                })
                                                                            )
                                                                        })()}
                                                                    </tbody>
                                                                </Table>
                                                            }
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="card card-flush h-md-100 bg-singlescore mt-3 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{singleScore}</span>
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Single score</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-flush h-md-100 bg-blue mt-5 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{robustness}</span>
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Robustness</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-flush h-md-100 bg-green mt-3 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{value}</span>
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Characterised value</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-flush h-md-100 bg-orange mt-3 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{normalizedValue}</span>
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Normalized value</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-flush h-md-100 bg-red mt-3 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{weightedValue}</span>
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Weighted value</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="graphs" title={<BsIcons.BsGraphUp />}>
                                                    <div className="row mt-4">
                                                        <div className="col">
                                                            In the following graph, the weighted results for the impact categories are visualised
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col ">
                                                            {
                                                                dataGraph !== null && <Bar className="mb-5" data={dataGraph} ref={chartRfPrincipal} options={options2} onClick={onClickPrincipal} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            If you select one impact category, the characterised results per life cycle stage will be shown:
                                                        </div>
                                                    </div>
                                                    <div className="row mt-5">
                                                        <div className="col-md-6">
                                                            {
                                                                dataGraphBar !== null && <div className="title2">{title}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {
                                                                dataGraphBar !== null && <Bar className="mt-5" options={options3} data={dataGraphBar} />
                                                            }
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab >
                        <Tab eventKey="hotspot_analysis" title="hotspot analysis">
                            <div className="card card-flush h-md-100 hotspot_analysis">
                                <div className="card-body">
                                    {
                                        error && <div className="col">
                                            <div className="card card-flush h-md-100">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="alert alert-warning">
                                                                At least 16 impact categories must be entered into the OOF for the hotspot to be calculated
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !error && <>
                                            <div className="row mt-4">
                                                <div className="col">
                                                    In PEF 3.1 (EC, 2021), hotspot analysis is defined as identification of
                                                    main contributing elements to the LCA results, based on normalized and weighted results.
                                                    Most relevant impact categories are those that together contribute to at least 80% of
                                                    the single overall score (starting from largest to smallest contributions).
                                                    The most relevant life cycle stages are those that together contribute more
                                                    than 80% to any of the most relevant impact categories
                                                    identified (starting from largest to smallest contributors).
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col">
                                                    <p>Following, the most relevant impact categories are presented, this means, the impact categories contributing cumulatively to 80% of the total normalized and weighted results:</p>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-3">
                                                    {
                                                        data !== null && <Doughnut ref={chartRf} data={data} options={options} onClick={onClick} />
                                                    }
                                                </div>
                                                <div className="col-md-9" style={{ position: 'relative' }}>
                                                    <div className="totals">
                                                        {total.toFixed(2)} %
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col">
                                                    <p>If you select one of those impact categories, the most relevant life cycle stages are presented, this means, those contributing cumulatively to at least 80% of the selected impact category:</p>
                                                </div>
                                            </div>
                                            {
                                                data2 !== null && <div className="row mt-4 mb-4">
                                                    <div className="col-md-3">
                                                        <div className="title2">{hotspotTitle}</div>
                                                        <Doughnut ref={chartRf2} data={data2} options={options} />
                                                    </div>
                                                    <div className="col-md-9" style={{ position: 'relative' }}>
                                                        <div className="totals">
                                                            {total2.toFixed(2)} %
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
}