import React, { useState, useEffect, useCallback } from "react";
import { Table, Form } from 'react-bootstrap';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import * as FaIcons from "react-icons/fa6";

export default function ProductComparisonCriticality({ product, products, categories, criticality, criticalityCrm, criticalityIndicators, selectedProductsCriticality, selectedProductsCriticalityCrm, selected }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [localSelected, setLocalSelected] = useState([]);
    const [indicatorCriticality, setIndicatorCriticality] = useState("-");
    const [dataCriticality, setDataCriticality] = useState(null);
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const [indicators, setIndicators] = useState([]);
    const [errors, setErrors] = useState([]);
    const [errorsCrm, setErrorsCrm] = useState([]);
    const optionsCriticality = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false
            }
        }
    };

    const changeIndicatorCriticality = (value) => {
        setIndicatorCriticality(value);
        createCriticalityGraphs(value);
    }

    const createCriticalityGraphs = (selected) => {
        const newList = criticality.filter(c => c.indicator === selected);
        let listItems = null;
        if (newList.length > 0) {
            listItems = newList[0].items;
            listItems.sort((a, b) => (a.position < b.position) ? -1 : 1);
        }
        const data = {
            labels: [],
            datasets: [{
                label: `${product.name} - ${product.scenario}`,
                data: [],
                unit: [],
                backgroundColor: []
            }]
        }
        for (let i = 0; i < categories.length; i++) {
            data.labels.push(categories[i]);
            if (listItems && listItems[i] && listItems[i].value) {
                data.datasets[0].data.push(listItems[i].value);
            } else {
                data.datasets[0].data.push(0);
            }
            if (listItems && listItems[i]) {
                data.datasets[0].unit.push(listItems[i].unit);
            }
            data.datasets[0].backgroundColor.push(colors[0]);
        }
        //add products to compare
        for (let i = 0; i < selectedProductsCriticality.length; i++) {
            const newListProduct = selectedProductsCriticality[i].data.filter(c => c.indicator === selected);
            let newListProductItems = null;
            if (newListProduct.length > 0) {
                newListProductItems = newListProduct[0].items;
                newListProductItems.sort((a, b) => (a.position < b.position) ? -1 : 1);
            }
            const pr = products.filter(x => x.id === selectedProductsCriticality[i].id)[0];
            const dataset = {
                label: `${pr.productName} - ${pr.scenario}`,
                data: [],
                unit: [],
                backgroundColor: []
            };
            for (let j = 0; j < categories.length; j++) {
                if (newListProductItems && newListProductItems[j] && newListProductItems[j].value) {
                    dataset.data.push(newListProductItems[j].value);
                } else {
                    dataset.data.push(0);
                }
                if (newListProductItems && newListProductItems[j]) {
                    dataset.unit.push(newListProductItems[j].unit);
                }
                dataset.backgroundColor.push(colors[i + 1]);
            }
            data.datasets.push(dataset);
        }
        setDataCriticality(data);
    }

    const getIndicators = useCallback(async (id, selected) => {
        const indicators = [];
        const errs = [];
        const indicator = await fetchIndicator(id);
        if (indicator.length === 0) {
            errs.push(`${product.name} - ${product.scenario}`);
        } else {
            for (let j = 0; j < indicator.length; j++) {
                indicators.push(indicator[j].indicator);
            }
        }
        for (let i = 0; i < selected.length; i++) {
            const indicatorOthers = await fetchIndicator(selected[i]);
            if (indicatorOthers.length === 0) {
                const pr = products.filter(x => x.id === selected[i])[0];
                errs.push(`${pr.productName} - ${pr.scenario}`);
            } else {
                for (let j = 0; j < indicatorOthers.length; j++) {
                    indicators.push(indicatorOthers[j].indicator);
                }
            }
        }
        const uniqueIndicators = [...new Set(indicators)];
        setIndicators(uniqueIndicators);
        setErrors(errs);
        if (uniqueIndicators.length === 1) {
            createCriticalityGraphs(uniqueIndicators[0]);
        }
    })

    const fetchIndicator = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/criticality/indicators`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (JSON.stringify(selected) !== JSON.stringify(localSelected)) {
            setIndicatorCriticality("-");
            setDataCriticality(null);
        }
        if (product !== null && selectedProductsCriticalityCrm.length > 0) {
            const errs = [];
            if (criticalityCrm.length === 0) {
                errs.push(`${product.name} - ${product.scenario}`);
            }
            for (let i = 0; i < selectedProductsCriticalityCrm.length; i++) {
                if (selectedProductsCriticalityCrm[i].data.length === 0) {
                    const pr = products.filter(x => x.id === selectedProductsCriticalityCrm[i].id)[0];
                    errs.push(`${pr.productName} - ${pr.scenario}`);
                }
            }
            setErrorsCrm(errs);
        }
        if (product !== null && selected.length > 0) {
            getIndicators(localStorage.getItem("product"), selected);
        }
        setLocalSelected(selected);
    }, [selected, localSelected, criticalityCrm, selectedProductsCriticalityCrm, criticality, selectedProductsCriticality]);

    return (
        <>
            {
                selected.length === 0 && <div className="card card-flush h-md-100">
                    <div className="card-body">
                        <div className="mt-3 w-100">
                            <div className="row">
                                <div className="col">No product has been selected to compare</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                selected.length > 0 && <div className="card card-flush h-md-100">
                    <div className="card-body">
                        <div className="mt-3 w-100">
                            {
                                indicators.length > 0 && <div className="row">
                                    <div className="col-md-6">Please, select one indicator:</div>
                                </div>
                            }
                            {
                                indicators.length > 0 && <div className="row mt-3">
                                    <div className="col-md-6">
                                        {
                                            indicators.length === 1 && <Form.Select value={indicatorCriticality} disabled>
                                                <option value={indicators[0]}>{indicators[0]}</option>
                                            </Form.Select>
                                        }
                                        {
                                            indicators.length > 1 && <Form.Select value={indicatorCriticality} onChange={e => changeIndicatorCriticality(e.target.value)}>
                                                {(() => {
                                                    if (indicatorCriticality === "-") {
                                                        return (
                                                            <option hidden disabled value="-"></option>
                                                        );
                                                    }
                                                })()}
                                                {
                                                    criticalityIndicators.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item}>{item}</option>
                                                        );
                                                    })
                                                }
                                            </Form.Select>
                                        }
                                    </div>
                                </div>
                            }
                            <div className={indicators.length > 0 ? "row mt-5" : "row"}>
                                {
                                    errors.length > 0 && errors.length === (selectedProductsCriticality.length + 1) &&
                                    <div className="col">
                                        <div className="alert alert-warning">
                                            There are no criticality indicator for these products
                                        </div>
                                    </div>
                                }
                                {
                                    errors.length > 0 && errors.length < (selectedProductsCriticality.length + 1) &&
                                    <div className="row">
                                        <div className="col">
                                            {
                                                errors.map((item, index) => {
                                                    return (
                                                        <div key={index} className="row">
                                                            <div className="col">
                                                                <div className="alert alert-warning" >
                                                                    There is no criticality indicator for product <b>{item}</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    dataCriticality !== null && errors.length < (selectedProductsCriticality.length + 1) &&
                                    <div className="row">
                                        <div className="col">
                                            <Bar options={optionsCriticality} data={dataCriticality} style={{ maxHeight: "450px" }} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <div className="title2">CRM LIST</div>
                                    {
                                        errorsCrm.length > 0 && errorsCrm.length === (selectedProductsCriticalityCrm.length + 1) && <div className="row">
                                            <div className="col">
                                                <div className="alert alert-warning">
                                                    These products do not contain any CRM
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        errorsCrm.length > 0 && errorsCrm.length < (selectedProductsCriticalityCrm.length + 1) && <div className="row">
                                            <div className="col">
                                                {
                                                    errorsCrm.map((item, index) => {
                                                        return (
                                                            <div key={index} className="row">
                                                                <div className="col">
                                                                    <div className="alert alert-warning" >
                                                                        The product <b>{item}</b> does not contain any CRM
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        errorsCrm.length < (selectedProductsCriticalityCrm.length + 1) && <Table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th style={{ backgroundColor: colors[0] }}>{`${product.name} - ${product.scenario}`}</th>
                                                    {
                                                        selectedProductsCriticalityCrm.map((item, index) => {
                                                            return (
                                                                <th style={{ backgroundColor: colors[index + 1] }} key={index}>{`${products.filter(x => x.id === item.id)[0].productName} - ${products.filter(x => x.id === item.id)[0].scenario}`}</th>
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(() => {
                                                    const datas = selectedProductsCriticalityCrm.map(x => x.data);
                                                    const common = datas.reduce((a, b) => [...a, ...b], []);
                                                    const datasCrm = common.map(x => x.crm);
                                                    const criticalityCrmCrm = criticalityCrm.map(x => x.crm);
                                                    const mergedArray = [...criticalityCrmCrm, ...datasCrm];
                                                    const setData = new Set(mergedArray);
                                                    const uniqueArray = Array.from(setData);
                                                    uniqueArray.sort((a, b) => (a.name < b.name) ? -1 : 1);
                                                    return (
                                                        uniqueArray.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item}</td>
                                                                    <td>{criticalityCrmCrm.includes(item) ? <FaIcons.FaRegCircleCheck /> : ''}</td>
                                                                    {
                                                                        selectedProductsCriticalityCrm.map((item2, index2) => {
                                                                            return (
                                                                                <td key={index2}>{item2.data.some(x => x.crm === item) ? <FaIcons.FaRegCircleCheck /> : ''}</td>
                                                                            );
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    );
                                                })()}
                                            </tbody>
                                        </Table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}