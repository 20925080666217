export default function LCATable({ row, index, categories, isChecked }) {
    return (
        <>
            {
                row.items.map((item2, index2) => {
                    const key = `${index}-${index2}`;
                    return (
                        item2.items.map((item3, index3) => {
                            const key2 = `${key}-${index3}`;
                            return (
                                <tr key={key2}>
                                    {
                                        index3 === 0 &&
                                        <td rowSpan={item2.items.length} className="p-0 pb-3 pt-3 min-w-100px text-center gray-background lcc-header fw-bold">{item2.indicatorType}</td>
                                    }
                                    {
                                        (() => {
                                            if (isChecked) {
                                                return (
                                                    <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start gray-background p-1 lcc-header fw-bold">{item3.indicator}</td>
                                                )
                                            } else {
                                                return (
                                                    item3.indicator !== 'A. Capital Costs' ?
                                                        <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start gray-background p-1 lcc-header fw-bold">{item3.indicator}</td> : null
                                                )
                                            }
                                        })()
                                    }
                                    {
                                        categories.map((item4, index4) => {
                                            const key3 = `${key2}-${index4}`;
                                            const value = item3.items.filter(z => z.position === item4.position);
                                            return (
                                                !isChecked && item3.indicator === 'A. Capital Costs' ? null :
                                                    <td key={key3} className="p-0 pb-3 pt-3 min-w-100px align-middle text-center">{value.length === 0 ? '-' : `${value[0].value.toFixed(2)}€`}</td>
                                            )
                                        })
                                    }
                                    {
                                        !isChecked && item3.indicator === 'A. Capital Costs' ? null :
                                            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-center gray-background">{item3.value !== null ? `${item3.value.toFixed(2)}€` : '-'}</td>
                                    }
                                </tr>
                            )
                        })

                    )
                })
            }
        </>
    );
}