import { Bar } from 'react-chartjs-2';
import { Form } from 'react-bootstrap';
import React, { useState, useEffect } from "react";

export default function ProductComparisonCircularity({ product, products, circularity, circularityMethodologies, circularityIndicators, selectedProductsCircularity, selectedProductsCircularityMethodologies, selected }) {
    const [dataCircularity, setDataCircularity] = useState(null);
    const [methodologies, setMethodologies] = useState([]);
    const [error, setError] = useState("");
    const [indicatorCircularity, setIndicatorCircularity] = useState("-");
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const optionsCircularity = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2
            }
        },
        responsive: true,
        tooltip: {
            callbacks: {
                label: (item) => {
                    return (`${item.dataset.label}: ${convertExponential(item.dataset.data[item.dataIndex])} ${item.dataset.unit[item.dataIndex]}`);
                }
            },
        },
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false
            }
        }
    };

    const convertExponential = (value) => {
        if (value !== null) {
            let decimals = 0;
            if (Math.floor(value.valueOf()) === value.valueOf()) {
                decimals = 0;
            } else {
                decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
            }
            if (decimals > 2) {
                return value.toExponential(3);
            } else {
                return value;
            }
        }
        else {
            return "-"
        }
    }

    const changeIndicatorCircularity = (value) => {
        setIndicatorCircularity(value);
        createCircularityGraphs(value);
    }

    const createCircularityGraphs = (selected) => {
        const data = {
            labels: [],
            datasets: [{
                label: `${product.name} - ${product.scenario}`,
                data: [],
                unit: [],
                backgroundColor: []
            }]
        }
        if (selected === "CTI") {
            data.labels.push("Circularity");
            data.labels.push("Water circularity");
            data.labels.push("Renewable energy");
            if (circularity.circularity) {
                data.datasets[0].data.push(circularity.circularity);
            } else {
                data.datasets[0].data.push(0);
            }
            if (circularity.water_circularity) {
                data.datasets[0].data.push(circularity.water_circularity);
            } else {
                data.datasets[0].data.push(0);
            }
            if (circularity.renewable_energy) {
                data.datasets[0].data.push(circularity.renewable_energy);
            } else {
                data.datasets[0].data.push(0);
            }
        } else if (selected === "MCI") {
            data.labels.push("Linear Flow Index");
            data.labels.push("Utility of a product");
            data.labels.push("MCI");
            if (circularity.linear_flow_index) {
                data.datasets[0].data.push(circularity.linear_flow_index);
            } else {
                data.datasets[0].data.push(0);
            }
            if (circularity.utility_product) {
                data.datasets[0].data.push(circularity.utility_product);
            } else {
                data.datasets[0].data.push(0);
            }
            if (circularity.material_circularity_indicator) {
                data.datasets[0].data.push(circularity.material_circularity_indicator);
            } else {
                data.datasets[0].data.push(0);
            }
        }
        data.datasets[0].backgroundColor.push(colors[0]);
        //add products to compare
        for (let i = 0; i < selectedProductsCircularity.length; i++) {
            const pr = products.filter(x => x.id === selectedProductsCircularity[i].id)[0];
            const dataset = {
                label: `${pr.productName} - ${pr.scenario}`,
                data: [],
                unit: [],
                backgroundColor: []
            };
            if (selected === "CTI") {
                if (selectedProductsCircularity[i].data.circularity) {
                    dataset.data.push(selectedProductsCircularity[i].data.circularity);
                } else {
                    dataset.data.push(0);
                }
                if (selectedProductsCircularity[i].data.water_circularity) {
                    dataset.data.push(selectedProductsCircularity[i].data.water_circularity);
                } else {
                    dataset.data.push(0);
                }
                if (selectedProductsCircularity[i].data.renewable_energy) {
                    dataset.data.push(selectedProductsCircularity[i].data.renewable_energy);
                } else {
                    dataset.data.push(0);
                }
            } else if (selected === "MCI") {
                if (selectedProductsCircularity[i].data.linear_flow_index) {
                    dataset.data.push(selectedProductsCircularity[i].data.linear_flow_index);
                } else {
                    dataset.data.push(0);
                }
                if (selectedProductsCircularity[i].data.utility_product) {
                    dataset.data.push(selectedProductsCircularity[i].data.utility_product);
                } else {
                    dataset.data.push(0);
                }
                if (selectedProductsCircularity[i].data.material_circularity_indicator) {
                    dataset.data.push(selectedProductsCircularity[i].data.material_circularity_indicator);
                } else {
                    dataset.data.push(0);
                }
            }
            dataset.backgroundColor.push(colors[i + 1]);
            data.datasets.push(dataset);
        }
        setDataCircularity(data);
    }

    useEffect(() => {
        if (!Array.isArray(circularity) && typeof(circularity) === "object" && selectedProductsCircularity.length > 0) {
            const totals = circularityMethodologies.map(e => e.methodology);
            for (let i = 0; i < selectedProductsCircularityMethodologies.length; i++) {
                for (let j = 0; j < selectedProductsCircularityMethodologies[i].data.length; j++) {
                    totals.push(selectedProductsCircularityMethodologies[i].data[j].methodology);
                }
            }
            const counts = {};
            const methodologies = [];
            totals.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
            if ((counts["CTI"] !== undefined && counts["CTI"] > 0) || (counts["MCI"] !== undefined && counts["MCI"] > 0)) {
                if (counts["CTI"] !== undefined && counts["CTI"] > 0) {
                    methodologies.push("CTI");
                }
                if (counts["MCI"] !== undefined && counts["MCI"] > 0) {
                    methodologies.push("MCI");
                }
                setMethodologies(methodologies);
                setDataCircularity(null);
                if (methodologies.length === 1) {
                    setIndicatorCircularity(methodologies[0]);
                    createCircularityGraphs(methodologies[0]);
                } else {
                    setIndicatorCircularity("-");
                }
            } else {
                setError("These cannot be compared as different databases have been used in each case")
            }
        }
    }, [circularity, selectedProductsCircularity]);

    return (
        <>
            {
                selected.length === 0 && <div className="card card-flush h-md-100">
                    <div className="card-body">
                        <div className="mt-3 w-100">
                            <div className="row">
                                <div className="col">No product has been selected to compare</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                selected.length > 0 && <div className="card card-flush h-md-100">
                    <div className="card-body">
                        <div className="mt-3 w-100">
                            <div className="row">
                                <div className="col-md-6">Please, select one indicator:</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    {
                                        methodologies.length === 1 && <Form.Select value={indicatorCircularity} disabled>
                                            <option value={methodologies}>{methodologies}</option>
                                        </Form.Select>
                                    }
                                    {
                                        (methodologies.length === 0 || methodologies.length > 1) && <Form.Select value={indicatorCircularity} onChange={e => changeIndicatorCircularity(e.target.value)}>
                                            {(() => {
                                                if (indicatorCircularity === "-") {
                                                    return (
                                                        <option hidden disabled value="-"></option>
                                                    );
                                                }
                                            })()}
                                            {
                                                methodologies.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item}>{item}</option>
                                                    );
                                                })
                                            }
                                        </Form.Select>
                                    }
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    {
                                        dataCircularity && <Bar options={optionsCircularity} data={dataCircularity} style={{ maxHeight: "450px" }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}