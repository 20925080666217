import React, { useState, useEffect } from 'react';
import * as BiIcons from "react-icons/bi";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

function User() {
    const [user, setUser] = useState("");
    const [role, setRole] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        let decodedToken = null;
        if (token) {
            decodedToken = jwtDecode(token);
            setRole(decodedToken.role);
            setUser(decodedToken.email);
        }
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('product');
        window.location.href = '/login';
    }

    return (
        <div className="user">
            <div className="dropdown">
                <div className="dropbtn">
                    <BiIcons.BiUser className='userIcon' />
                    {user}
                    <IoIcons.IoIosArrowDown />
                </div>
                <div className="dropdown-content">
                    {
                        role === "admin" && <Link to="/admin/users" className='menu-bars'>
                            <RiIcons.RiAdminLine /> Admin panel
                        </Link>
                    }
                    <Link to="/change-password" className='menu-bars'>
                        <RiIcons.RiLockPasswordLine /> Change password
                    </Link>
                    <Link className='menu-bars' onClick={logout}>
                        <BiIcons.BiExit /> Logout
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default User;