import { React, useState } from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import logo from '../../logo.png';
import background from '../../background.png';
import 'bootstrap/dist/css/bootstrap.css';
import './LoginPage.css';

export default function LoginPage() {
    const { handleSubmit, register, formState: { errors } } = useForm();
    const [error, setError] = useState('');

    const setAuthToken = token => {
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        else {
            delete axios.defaults.headers.common["Authorization"];
        }
    }

    const onSubmit = async data => {
        const loginPayload = {
            email: data.email,
            password: data.password
        };
        const apiUrl = process.env.REACT_APP_API_URL;
        axios.post(`${apiUrl}/users/login`, loginPayload).then(response => {
            //get token from response
            const token = response.data;
            //set JWT token to local
            localStorage.setItem("token", token.token);
            //redirect user to home page
            if (token.firstTime) {
                window.location.href = '/change-password';
            } else {
                window.location.href = '/general-info';
            }

            //set token to axios common header
            setAuthToken(token);
            setError('');
        }).catch(err => setError(err.response.data.detail));
    }
    return (
        <>
            <div className="d-flex flex-column flex-root" id="login_root">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid ">
                    <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                        <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                            <div className="d-flex flex-row-fluid flex-column text-center p-5 p-lg-10 pt-lg-20">
                                <img alt="Logo" src={logo} className="theme-light-show h-80px h-lg-100px" />
                                <div className="w-lg-500px p-10">
                                    <form id="loginContainer" className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="loginUI">
                                            <div className="loginUI_title">Sign in</div>
                                            <div className="form-group mt-3">
                                                <input
                                                    type="email"
                                                    className="form-control mt-2"
                                                    placeholder="Enter email"
                                                    {...register("email", {
                                                        required: "Required",
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: "Entered value does not match email format",
                                                        }
                                                    })}
                                                />
                                                {errors.email && <div className="d-grid gap-2 mt-1 error">{errors.email.message}</div>}
                                            </div>
                                            <div className="form-group mt-1">
                                                <input
                                                    type="password"
                                                    className="form-control mt-2"
                                                    placeholder="Enter password"
                                                    {...register("password", {
                                                        required: "Required",
                                                        pattern: {
                                                            value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                                                            message: "Password requirements: 8-20 characters, 1 number, 1 letter, 1 symbol."
                                                        }
                                                    })}
                                                />
                                                {errors.password && <div className="d-grid gap-2 mt-1 error">{errors.password.message}</div>}
                                            </div>
                                            <div className="forgot gap-2 mt-2 text-end">
                                                <Link to="/forgot-password">Forgot password?</Link>
                                            </div>
                                            <div className="d-grid gap-2 mt-4">
                                                <button type="submit" className="btn submit">
                                                    Submit
                                                </button>
                                            </div>
                                            <div className="not-member gap-2 mt-3">
                                                Not a Member yet? <Link to="/sign-up">Sign up</Link>
                                            </div>

                                            {error !== '' &&
                                                <div className="d-grid gap-2 mt-2 error">
                                                    {error === "User.InvalidCredentials" ? "Invalid email or password" : error}
                                                </div>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 background" style={{ backgroundImage: `url(${background})` }}>
                        <div className="d-flex flex-center flex-column flex-lg-row-fluid"></div>
                    </div>
                </div>
            </div>
        </>
    )
}