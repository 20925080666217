import { React, useState } from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import logo from '../../logo.png';
import background from '../../background.png';
import 'bootstrap/dist/css/bootstrap.css';
import './CreateUserPage.css';
import * as FaIcons from "react-icons/fa";

export default function CreateUserPage() {
    const { handleSubmit, register, formState: { errors } } = useForm();
    const [error, setError] = useState('');

    const onSubmit = async data => {
        const loginPayload = {
            name: data.name,
            surname: data.surname,
            email: data.email
        };
        const apiUrl = process.env.REACT_APP_API_URL;
        axios.post(`${apiUrl}/users`, loginPayload).then(response => {
            //redirect user to home page
            window.location.href = '/login';
            setError('');
        }).catch(err => setError(err.response.data.detail));
    }
    return (
        <>
            <div className="d-flex flex-column flex-root" id="login_root">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid ">
                    <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                        <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                            <div className="d-flex flex-row-fluid flex-column text-center p-5 p-lg-10 pt-lg-20">
                                <img alt="Logo" src={logo} className="theme-light-show h-80px h-lg-100px" />
                                <div className="w-lg-500px p-10">
                                    <form id="loginContainer" className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="loginUI">
                                            <div className="loginUI_title">Sign Up</div>
                                            <div className="form-group mt-3">
                                                <input
                                                    type="text"
                                                    className="form-control mt-2"
                                                    placeholder="Enter name"
                                                    {...register("name", {
                                                        required: "Required",
                                                    })}
                                                />
                                                {errors.name && <div className="d-grid gap-2 mt-1 error">{errors.name.message}</div>}
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control mt-2"
                                                    placeholder="Enter surname"
                                                    {...register("surname", {
                                                        required: "Required"
                                                    })}
                                                />
                                                {errors.surname && <div className="d-grid gap-2 mt-1 error">{errors.surname.message}</div>}
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control mt-2"
                                                    placeholder="Enter email"
                                                    {...register("email", {
                                                        required: "Required",
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: "Entered value does not match email format",
                                                        }
                                                    })}
                                                />
                                                {errors.email && <div className="d-grid gap-2 mt-1 error">{errors.email.message}</div>}
                                            </div>
                                            <div className="form-group mt-3 send-email">
                                                *The password will be sent to your email.
                                            </div>
                                            <div className="d-grid gap-2 mt-3">
                                                <button type="submit" className="btn submit">
                                                    Sign up
                                                </button>
                                            </div>
                                            <div className="back gap-2 mt-3 text-start">
                                                <Link to="/login"><FaIcons.FaLongArrowAltLeft /> Back</Link>
                                            </div>

                                            {error !== '' &&
                                                <div className="d-grid gap-2 mt-2 error">
                                                    {error}
                                                </div>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 background" style={{ backgroundImage: `url(${background})` }}>
                        <div className="d-flex flex-center flex-column flex-lg-row-fluid"></div>
                    </div>
                </div>
            </div>
        </>
    )
}