import Accordion from 'react-bootstrap/Accordion';
import './glossary.css';

export default function GlossaryPage() {
    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Glossary</div>
                    </div>
                </div>
            </div>
            <div className="row pb-5 glossary">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Circularity transition indicators (CTI) framework</Accordion.Header>
                                    <Accordion.Body>
                                        Developed by the World Business Council for Sustainable Development (WBCSD), is a simple, objective, and quantitative framework to measure circularity. It helps companies respond to this growing demand by providing a simple and sector-agnostic way to measure circularity and giving insights into how to reduce primary resource use and waste generation.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Critical Raw Materials (CRMs)</Accordion.Header>
                                    <Accordion.Body>
                                        These are raw materials of high importance to the economy of a region (e.g., the EU) that are associated with high risk of supply disruption.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>European Union Critical Raw Materials (EUCRM)</Accordion.Header>
                                    <Accordion.Body>
                                        Nomenclature given to ORIENTING indicators under the "impact category" of criticality; EUCRM SR = European Union Critical Raw Materials - Supply Risk. EUCRM EI = European Union Critical Raw Materials - Economic Importance;  EUCRM = European Union Critical Raw Materials (criticality).
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Functional unit (FU)</Accordion.Header>
                                    <Accordion.Body>
                                        Quantified performance of a product system for use as a reference unit (ISO 14040).
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>GeoPolRisk</Accordion.Header>
                                    <Accordion.Body>
                                        Methodology to an import-based indicator for the geopolitical supply risk of resources in LCSA). The method includes features similar to the EC-CA, but adapted to the LC(S)A perspective. The supply risk of raw materials is primarily determined from the perspective of the resource demanding country, considering the import share of the demanding-country from the supplying country, the global share of a supplying country in the production of a certain commodity and the geopolitical stability of that country. Thus, the indicator is at a country level, employing global shares.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Impact category</Accordion.Header>
                                    <Accordion.Body>
                                        Class representing environmental issues of concern to which life cycle inventory analysis results may be assigned (ISO 14040), for example climate change or acidification.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Life cycle assessment (LCA)</Accordion.Header>
                                    <Accordion.Body>
                                        Compilation and evaluation of the inputs, outputs, and the potential environmental impacts of a product system throughout its life cycle.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>Life cycle costing</Accordion.Header>
                                    <Accordion.Body>
                                        A stand-alone assessment (i.e., no alignment with environmental LCA) of internal costs (i.e., private cash flows) that is carried out from the perspective of a single stakeholder.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>Life cycle sustainability assessment (LCSA)</Accordion.Header>
                                    <Accordion.Body>
                                        A life cycle-based assessment (of products) that takes into account all sustainability domains. In the framework of ORIENTING project, LCSA covers relevant life cycle impacts and benefits related to all assessed sustainability domains (environmental, economic, and social) and analyses them in relation to each other. ORIENTING’s LCSA also addresses the lifetime and circularity of products as well as the criticality of materials.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>Materials’ criticality</Accordion.Header>
                                    <Accordion.Body>
                                        Corresponds to the output of a material criticality assessment. It refers to materials that are critical (i.e., critical raw materials, CRMs) to a product system. Criticality is evaluated from the point of view of supply risks. Economic considerations may also play a role.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>Material Circularity Indicator (MCI) tool</Accordion.Header>
                                    <Accordion.Body>
                                        Developed by The Ellen MacArthur Foundation and Granta Design, allows companies to identify additional, circular value from their products and materials, and mitigate risks from material price volatility and material supply.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>Normalisation</Accordion.Header>
                                    <Accordion.Body>
                                        Calculating the magnitude of category indicator results relative to reference information (ISO 14044; ISO/DTS 14074:2021).
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header>Robustness</Accordion.Header>
                                    <Accordion.Body>
                                        Robustness refers to the scientific quality and reliability of a method and its underlying model assessing an impact category and is given on a scale from 1 to 3 whereby 1 is most robust.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                    <Accordion.Header>Social life cycle assessment (S-LCA)</Accordion.Header>
                                    <Accordion.Body>
                                        It is a methodology to assess the social impacts of products and services across their life cycle (e.g., from extraction of raw material to the end-of-life phase, e.g., disposal). It offers a systematic assessment framework that combines quantitative as well as qualitative data. S-LCA provides information on social and socio-economic aspects for decision-making, in the prospect to improve the social performance of an organization and ultimately the well-being of stakeholders.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                    <Accordion.Header>Product classification (CPA)</Accordion.Header>
                                    <Accordion.Body>
                                        Product nomenclature used in the European Economic Community and now in use in the EU, a European version of the CPC.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                    <Accordion.Header>Weighting</Accordion.Header>
                                    <Accordion.Body>
                                        Converting and possibly aggregating indicator results across impact categories using weighting factors based on value choices (ISO/DTS 14074:2021). The process converts different indicator results across impact categories into unit formats addressing specific issues (e.g., monetary value) (UNEP/SETAC Life Cycle Initiative, 2011b). To obtain single scores from a multi-impact approach, weighting needs to be applied.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}