import { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import * as FaIcons from "react-icons/fa6";

export default function ProductComparisonSLCAPerformance({ product, products, slcaPerformance, selectedProductsSLCAPerformance, selected }) {
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const getColor = (rowValue) => {
        switch (rowValue) {
            case null:
                return 'white';
            case -2:
                return 'red';
            case -1:
                return '#ffc000';
            case 0:
                return '#dddddd';
            case 1:
                return '#92d050'
            case 2:
                return '#00b050'
            default:
                break;
        }
    }
    return (
        <>
            {
                selected.length === 0 &&
                <div className="mt-3 w-100">
                    <div className="row">
                        <div className="col">No product has been selected to compare</div>
                    </div>
                </div>
            }
            {
                selected.length > 0 &&
                <div className="row">
                    <div className="col-md-9">
                        <Table className="table table-bordered align-middle gs-0 gy-3 my-0 generalTableSLCA">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th style={{ backgroundColor: colors[0] }}><span style={{ fontWeight: '400', color: 'black' }}>Aggregated score of</span><br />{`${product.name} - ${product.scenario}`}</th>
                                    {
                                        selectedProductsSLCAPerformance.map((item, index) => {
                                            return (
                                                <th style={{ backgroundColor: colors[index + 1] }} key={`header-${index}`}><span style={{ fontWeight: '400', color: 'black' }}>Aggregated score of</span><br />{`${products.filter(x => x.id === item.id)[0].productName} - ${products.filter(x => x.id === item.id)[0].scenario}`}</th>
                                            );
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    slcaPerformance.map((item, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <tr>
                                                    <td colSpan={(selectedProductsSLCAPerformance.length + 2)}><b>{item.stakeholder}</b></td>
                                                </tr>
                                                {
                                                    item.items.map((item2, index2) => {
                                                        return (
                                                            <tr key={`${index}-${index2}`}>
                                                                <td key={`${index}-${index2}-a`}>{item2.topic} {
                                                                    (() => {
                                                                        if (item2.critical) {
                                                                            return (
                                                                                <p className="critical">(significant)</p>
                                                                            );
                                                                        }
                                                                    })()
                                                                }</td>
                                                                <td key={`${index}-${index2}-as`} className="p-0 pb-3 pt-3 min-w-100px align-middle text-center" style={{ backgroundColor: getColor(item2.aggregatedScore) }}><strong>{item2.aggregatedScore}</strong>
                                                                    {(() => {
                                                                        if (item2.flag === "red") {
                                                                            return (
                                                                                <FaIcons.FaFlag style={{ marginLeft: "5px", color: "red" }} />
                                                                            );
                                                                        } else if (item2.flag === "green") {
                                                                            return (
                                                                                <FaIcons.FaFlag style={{ marginLeft: "5px", color: "green" }} />
                                                                            );
                                                                        }
                                                                    })()}
                                                                </td>
                                                                {
                                                                    selectedProductsSLCAPerformance.map((item3, index3) => {
                                                                        return (
                                                                            (() => {
                                                                                const groups = item3.data.filter(x => x.stakeholder === item.stakeholder)[0];
                                                                                if (groups) {
                                                                                    const topic = groups.items.filter(x => x.topic === item2.topic)[0];
                                                                                    if (topic) {
                                                                                        return (
                                                                                            <td key={`${index}-${index2}-as-${index3}`} className="p-0 pb-3 pt-3 min-w-100px align-middle text-center" style={{ backgroundColor: getColor(topic.aggregatedScore) }}><strong>{topic.aggregatedScore}</strong>
                                                                                                {(() => {
                                                                                                    if (topic.flag === "red") {
                                                                                                        return (
                                                                                                            <FaIcons.FaFlag style={{ marginLeft: "5px", color: "red" }} />
                                                                                                        );
                                                                                                    } else if (topic.flag === "green") {
                                                                                                        return (
                                                                                                            <FaIcons.FaFlag style={{ marginLeft: "5px", color: "green" }} />
                                                                                                        );
                                                                                                    }
                                                                                                })()}
                                                                                            </td>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <td key={`${index}-${index2}-as-${index3}`} className="p-0 pb-3 pt-3 min-w-100px align-middle text-center">-</td>
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    return (
                                                                                        <td key={`${index}-${index2}-as-${index3}`} className="p-0 pb-3 pt-3 min-w-100px align-middle text-center">-</td>
                                                                                    );
                                                                                }
                                                                            })()
                                                                        );
                                                                    })
                                                                }
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-md-3">
                        <div className="card reference">
                            <div className="card-header align-items-center border-0">
                                <h3 className="card-title">Referece scale</h3>
                                <span className="text-muted mt-1 fw-semibold fs-7">5-point reference scale scores</span>
                            </div>
                            <div className="card-body pt-3">
                                <div className="align-items-sm-center mb-7">
                                    <div className="symbol me-4">
                                        <div className="symbol-label" style={{ backgroundColor: 'red' }}>-2</div>
                                        <div className="symbol-text">
                                            Unacceptable situation
                                        </div>
                                    </div>

                                </div>
                                <div className="align-items-sm-center mb-7 mt-3">
                                    <div className="symbol me-4">
                                        <div className="symbol-label" style={{ backgroundColor: '#ffc000' }}>-1</div>
                                        <div className="symbol-text">
                                            Unacceptable situation with signs of improvement
                                        </div>
                                    </div>

                                </div>
                                <div className="align-items-sm-center mb-7 mt-3">
                                    <div className="symbol me-4">
                                        <div className="symbol-label" style={{ backgroundColor: '#dddddd' }}>0</div>
                                        <div className="symbol-text">
                                            Acceptable situation
                                        </div>
                                    </div>

                                </div>
                                <div className="align-items-sm-center mb-7 mt-3">
                                    <div className="symbol me-4">
                                        <div className="symbol-label" style={{ backgroundColor: '#92d050' }}>+1</div>
                                        <div className="symbol-text">
                                            Beyond acceptable
                                        </div>
                                    </div>
                                </div>
                                <div className="align-items-sm-center mb-7 mt-3">
                                    <div className="symbol me-4">
                                        <div className="symbol-label" style={{ backgroundColor: '#00b050' }}>+2</div>
                                        <div className="symbol-text">
                                            Ideal performance
                                        </div>
                                    </div>
                                </div>
                                <div className="align-items-sm-center mb-7 mt-3">
                                    <div className="mt-3 mb-3" style={{ height: '1px', width: '100%', backgroundColor: '#e9e9e9', float: 'left' }}></div>
                                </div>
                                <div className="align-items-sm-center mb-3 mt-3">
                                    <div className="symbol flags me-4 mt-3">
                                        <div className="symbol-label"><FaIcons.FaFlag style={{ color: "red" }} /></div>
                                        <div className="symbol-text">
                                            In an overall positive score, the red flag indicates the presence of one or more negative scores (or risks) within the disaggregated scores.
                                        </div>
                                    </div>
                                </div>
                                <div className="align-items-sm-center mb-3">
                                    <div className="symbol flags me-4 mt-3">
                                        <div className="symbol-label"><FaIcons.FaFlag style={{ color: "green" }} /></div>
                                        <div className="symbol-text">
                                            In an overall negative score, the green flag indicates the presence of one or more positive scores within the disaggregated scores.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}