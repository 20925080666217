import React, { useState, useEffect, useCallback } from "react";
import ProductInfo from "../Layout/ProductInfo";
import axios from 'axios';
import { Tab, Tabs, Table, Button, Form } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import "./ProductComparisonPage.css";
import ProductComparisonLCC from "./ProductComparisonLCC";
import ProductComparisonCircularity from "./ProductComparisonCircularity";
import ProductComparisonCriticality from "./ProductComparisonCriticality";
import ProductComparisonSLCAPerformance from "./ProductComparisonSLCAPerformance";
import ProductComparisonSLCARisk from "./ProductComparisonSLCARisk";

export default function ProductComparisonPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [key, setKey] = useState('lca');
    const [product, setProduct] = useState(null);
    const [categories, setCategories] = useState([]);
    const [circularityIndicators, setCircularityIndicators] = useState([]);
    const [criticalityIndicators, setCriticalityIndicators] = useState([]);
    const [products, setProducts] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedProductsLCA, setSelectedProductsLCA] = useState([]);
    const [selectedProductsLCC, setSelectedProductsLCC] = useState([]);
    const [selectedProductsLCCTotals, setSelectedProductsLCCTotals] = useState([]);
    const [selectedProductsSLCAPerformance, setSelectedProductsSLCAPerformance] = useState([]);
    const [selectedProductsSLCARisk, setSelectedProductsSLCARisk] = useState([]);
    const [selectedProductsLCSA, setSelectedProductsLCSA] = useState([]);
    const [selectedProductsCircularity, setSelectedProductsCircularity] = useState([]);
    const [selectedProductsCircularityMethodologies, setSelectedProductsCircularityMethodologies] = useState([]);
    const [selectedProductsCriticality, setSelectedProductsCriticality] = useState([]);
    const [selectedProductsCriticalityCrm, setSelectedProductsCriticalityCrm] = useState([]);
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const [categoriesLCA, setCategoriesLCA] = useState([]);
    const [categoryLCA, setCategoryLCA] = useState("-");
    const [categoriesLCC, setCategoriesLCC] = useState([]);
    const [lca, setLca] = useState([]);
    const [lcc, setLcc] = useState([]);
    const [lccTotals, setLccTotals] = useState([]);
    const [slcaPerformance, setSlcaPerformance] = useState([]);
    const [slcaRisk, setSlcaRisk] = useState([]);
    const [lcsa, setLcsa] = useState([]);
    const [circularity, setCircularity] = useState([]);
    const [circularityMethodologies, setCircularityMethodologies] = useState([]);
    const [criticality, setCriticality] = useState([]);
    const [criticalityCrm, setCriticalityCrm] = useState([]);
    const [dataLCA, setDataLCA] = useState(null);
    const [dataSingleScoreLCA, setDataSingleScoreLCA] = useState(null);
    const [valueTypeLCA, setValueTypeLCA] = useState("value");
    const [tab, setTab] = useState("performance");

    const getData = async (selected) => {
        if (selected.length === 0) {
            setSelected([]);
            setSelectedProductsLCA([]);
            setSelectedProductsLCC([]);
            setSelectedProductsLCCTotals([]);
            setSelectedProductsSLCAPerformance([]);
            setSelectedProductsSLCARisk([]);
            setSelectedProductsCircularity([]);
            setSelectedProductsCircularityMethodologies([]);
            setSelectedProductsCriticality([]);
            setSelectedProductsCriticalityCrm([]);
            setSelectedProductsLCSA([]);
        } else {
            setCategoryLCA("-");
            setValueTypeLCA("value");
            setDataLCA(null);
            if (selected.length > 0) {
                selected = [...new Set(selected)];
                const objectsLCA = [];
                const objectsCircularity = [];
                const objectsCircularityMethodologies = [];
                const objectsCriticality = [];
                const objectsCriticalityCrm = [];
                const objectsLCC = [];
                const objectsLCCTotals = [];
                const objectsSLCAPerformance = [];
                const objectsSLCARisk = [];
                const objectsLCSA = [];
                for (let i = 0; i < selected.length; i++) {
                    const objLCA = await fetchSelectedLcaData(selected[i]);
                    objectsLCA.push(objLCA);
                    const objCircularity = await fetchSelectedCircularityData(selected[i]);
                    objectsCircularity.push(objCircularity);
                    const objCircularityMethodologies = await fetchSelectedCircularityMethodologiesData(selected[i]);
                    objectsCircularityMethodologies.push(objCircularityMethodologies);
                    const objCriticality = await fetchSelectedCriticalityData(selected[i]);
                    objectsCriticality.push(objCriticality);
                    const objCriticalityCrm = await fetchSelectedCriticalityCrmData(selected[i]);
                    objectsCriticalityCrm.push(objCriticalityCrm);
                    const objLCC = await fetchSelectedLccData(selected[i]);
                    objectsLCC.push(objLCC);
                    const objLCCTotals = await fetchSelectedLccTotalsData(selected[i]);
                    objectsLCCTotals.push(objLCCTotals);
                    const objSLCAPerformance = await fetchSelectedSlcaPerformanceData(selected[i]);
                    objectsSLCAPerformance.push(objSLCAPerformance);
                    const objSLCARisk = await fetchSelectedSlcaRiskData(selected[i]);
                    objectsSLCARisk.push(objSLCARisk);
                    const objLCSA = await fetchSelectedLcsaData(selected[i]);
                    objectsLCSA.push(objLCSA);
                }
                setSelectedProductsLCA(objectsLCA);
                setSelectedProductsCircularity(objectsCircularity);
                setSelectedProductsCircularityMethodologies(objectsCircularityMethodologies);
                setSelectedProductsCriticality(objectsCriticality);
                setSelectedProductsCriticalityCrm(objectsCriticalityCrm);
                setSelectedProductsLCC(objectsLCC);
                setSelectedProductsLCCTotals(objectsLCCTotals);
                setSelectedProductsSLCAPerformance(objectsSLCAPerformance);
                setSelectedProductsSLCARisk(objectsSLCARisk);
                setSelectedProductsLCSA(objectsLCSA);
                setKey('lca');
                createLCASingleScoreGraphs(objectsLCA);
            }
        }
    };

    const fetchProduct = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setProduct(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchCategories = async () => {
        axios.get(`${apiUrl}/products/categories`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setCategories(response.data);
        }).catch(err => {
            console.log(err);
        });
    };

    const fetchCircularityIndicators = async () => {
        await axios.get(`${apiUrl}/products/circularity-indicators`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setCircularityIndicators(response.data);
        }).catch(err => {
            console.log(err);
        });
    };

    const fetchCriticalityIndicators = async () => {
        await axios.get(`${apiUrl}/products/criticality-indicators`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setCriticalityIndicators(response.data);
        }).catch(err => {
            console.log(err);
        });
    };

    const fetchSelectedLcaData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lca`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedCircularityData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/circularity`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedCircularityMethodologiesData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/circularity/methodologies`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedCriticalityData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/criticality`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedLccData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lcc`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedLccTotalsData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lcc/totals`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedSlcaPerformanceData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/performance`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedSlcaRiskData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/risk/databases`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedLcsaData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lcsa`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedCriticalityCrmData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/criticality/crm`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchCategoriesLCA = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/lca/categories`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setCategoriesLCA(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchCategoriesLCC = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/lcc/categories`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setCategoriesLCC(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchProducts = useCallback(async () => {
        axios.get(`${apiUrl}/products`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setProducts(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const optionsLCA = {
        responsive: true,
        scales: {
            y: {
                ticks: {
                    callback: (val) => (convertExponential(val))
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        const index = item.dataset.data.findIndex((element) => element === item.raw);
                        if (item.raw < 0.001) {
                            return (`${item.dataset.label}: ${convertExponential(item.raw)} ${item.dataset.unit[index]}`);
                        } else {
                            return (`${item.dataset.label}: ${convertExponential(item.formattedValue)} ${item.dataset.unit[index]}`);
                        }
                    }
                },
            },
            legend: {
                position: 'top'
            },
            title: {
                display: false
            }
        }
    };

    const optionsSingleScoreLCA = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                position: 'right'
            },
            title: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (`${item.dataset.label}: ${item.formattedValue}%`);
                    }
                }
            }
        }
    };

    const fetchLcaData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/lca`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setLca(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchLccData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/lcc`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setLcc(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchLccDataTotals = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/lcc/totals`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setLccTotals(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchSlcaPerformanceData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/slca/performance`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setSlcaPerformance(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchSlcaRiskData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/slca/risk/databases`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setSlcaRisk(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchLcsaData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/lcsa`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setLcsa(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchCircularityData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/circularity`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setCircularity(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchCircularityMethodologiesData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/circularity/methodologies`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setCircularityMethodologies(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchCriticalityData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/criticality`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setCriticality(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchCriticalityCrmData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/criticality/crm`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setCriticalityCrm(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const changeTab = (tab) => {
        const selected = localStorage.getItem("product");
        if (tab === "lca") {
            fetchLcaData(selected);
            fetchLcsaData(selected);
        } else if (tab === "lcc") {
            fetchLccData(selected);
            fetchLccDataTotals(selected);
        } else if (tab === "slca") {
            fetchSlcaPerformanceData(selected);
            fetchSlcaRiskData(selected);
            fetchLcsaData(selected);
        } else if (tab === "circularity") {
            fetchCircularityData(selected);
            fetchCircularityMethodologiesData(selected);
        } else if (tab === "criticality") {
            fetchCriticalityData(selected);
            fetchCriticalityCrmData(selected);
        }
        setKey(tab);
    }

    const changeCategoryLCA = (value) => {
        setCategoryLCA(value);
        createLCAGraphs(value, "value");
    }

    const changeLCAValueType = (value) => {
        setValueTypeLCA(value);
        createLCAGraphs(categoryLCA, value);
    }

    const createLCAGraphs = (selected, valueType) => {
        const newList = lca.filter(c => c.category === selected);
        let listItems = null;
        if (newList.length > 0) {
            listItems = newList[0].items;
            listItems.sort((a, b) => (a.position < b.position) ? -1 : 1);
        }
        const data = {
            labels: [],
            datasets: [{
                label: `${product.name} - ${product.scenario}`,
                data: [],
                unit: [],
                backgroundColor: []
            }]
        }
        for (let i = 0; i < categories.length; i++) {
            data.labels.push(categories[i]);
            if (valueType === "value") {
                if (listItems && listItems[i] && listItems[i].value) {
                    data.datasets[0].data.push(listItems[i].value);
                } else {
                    data.datasets[0].data.push(0);
                }
            } else if (valueType === "normalized_value") {
                if (listItems && listItems[i] && listItems[i].normalizedValue) {
                    data.datasets[0].data.push(listItems[i].normalizedValue);
                } else {
                    data.datasets[0].data.push(0);
                }
            } else if (valueType === "single_score") {
                if (listItems && listItems[i] && listItems[i].weighValue) {
                    data.datasets[0].data.push(listItems[i].weighValue);
                } else {
                    data.datasets[0].data.push(0);
                }
            }
            if (listItems && listItems[i]) {
                data.datasets[0].unit.push(listItems[i].unit);
            }
            data.datasets[0].backgroundColor.push(colors[0]);
        }
        //add products to compare
        for (let i = 0; i < selectedProductsLCA.length; i++) {
            const newListProduct = selectedProductsLCA[i].data.filter(c => c.category === selected);
            let newListProductItems = null;
            if (newListProduct.length > 0) {
                newListProductItems = newListProduct[0].items;
                newListProductItems.sort((a, b) => (a.position < b.position) ? -1 : 1);
            }
            const pr = products.filter(x => x.id === selectedProductsLCA[i].id)[0];
            const dataset = {
                label: `${pr.productName} - ${pr.scenario}`,
                data: [],
                unit: [],
                backgroundColor: []
            };
            for (let j = 0; j < categories.length; j++) {
                if (valueType === "value") {
                    if (newListProductItems && newListProductItems[j] && newListProductItems[j].value) {
                        dataset.data.push(newListProductItems[j].value);
                    } else {
                        dataset.data.push(0);
                    }
                } else if (valueType === "normalized_value") {
                    if (newListProductItems && newListProductItems[j] && newListProductItems[j].normalizedValue) {
                        dataset.data.push(newListProductItems[j].normalizedValue);
                    } else {
                        dataset.data.push(0);
                    }
                } else if (valueType === "single_score") {
                    if (newListProductItems && newListProductItems[j] && listItems[j].weighValue) {
                        dataset.data.push(newListProductItems[j].weighValue);
                    } else {
                        dataset.data.push(0);
                    }
                }
                if (newListProductItems && newListProductItems[j]) {
                    dataset.unit.push(newListProductItems[j].unit);
                }
                dataset.backgroundColor.push(colors[i + 1]);
            }
            data.datasets.push(dataset);
        }
        setDataLCA(data);
    }

    const createLCASingleScoreGraphs = (selectedProducts) => {
        const data = {
            labels: [],
            datasets: [{
                label: `${product.name} - ${product.scenario}`,
                data: [],
                unit: [],
                backgroundColor: []
            }]
        }

        //filter categories
        const lcaCategoryList = lca.filter(e => e.normalizedValue !== null).map(e => e.category);
        for (let i = 0; i < selectedProducts.length; i++) {
            const lcaTemp = selectedProducts[i].data.filter(e => e.normalizedValue !== null).map(x => x.category);
            for (let j = 0; j < lcaTemp.length; j++) {
                lcaCategoryList.push(lcaTemp[j]);
            }
        }

        const counts = {};
        lcaCategoryList.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        const entriesList = Object.entries(counts);
        data.labels = entriesList.filter(([_, val]) => val === (selectedProducts.length + 1)).map(([key, _]) => key);

        for (let i = 0; i < data.labels.length; i++) {
            const lcatemp = lca.filter(x => x.category === data.labels[i])[0];
            if (lcatemp && lcatemp.normalizedValue) {
                data.datasets[0].data.push(100);
            } else {
                data.datasets[0].data.push(0);
            }
            if (lcatemp && lcatemp.items && lcatemp.items.length > 0) {
                data.datasets[0].unit.push("%");
            }
            data.datasets[0].backgroundColor.push(colors[0]);
        }
        //add products to compare
        for (let i = 0; i < selectedProducts.length; i++) {
            const pr = products.filter(x => x.id === selectedProducts[i].id)[0];
            const dataset = {
                label: `${pr.productName} - ${pr.scenario}`,
                data: [],
                unit: [],
                backgroundColor: []
            };
            for (let j = 0; j < selectedProducts[i].data.length; j++) {
                for (let k = 0; k < data.labels.length; k++) {
                    const lcatemp = selectedProducts[i].data.filter(x => x.category === data.labels[k])[0];
                    if (lcatemp && lcatemp.normalizedValue) {
                        const lcaFirstTemp = lca.filter(x => x.category === data.labels[k])[0];
                        if (lcaFirstTemp && lcaFirstTemp.normalizedValue) {
                            const result = (lcatemp.normalizedValue * 100 / lcaFirstTemp.normalizedValue);
                            dataset.data.push(result);
                        } else {
                            data.datasets[0].data.push(0);
                        }
                    } else {
                        dataset.data.push(0);
                    }
                    if (lcatemp && lcatemp.items && lcatemp.items.length > 0) {
                        data.datasets[0].unit.push("%");
                    }
                    dataset.backgroundColor.push(colors[i + 1]);
                }
            }
            data.datasets.push(dataset);
        }
        setDataSingleScoreLCA(data);
    }

    const convertExponential = (value) => {
        if (value !== null) {
            let decimals = 0;
            if (Math.floor(value.valueOf()) === value.valueOf()) {
                decimals = 0;
            } else {
                decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
            }
            if (decimals > 2) {
                return value.toExponential(3);
            } else {
                return value;
            }
        }
        else {
            return "-"
        }
    }

    useEffect(() => {
        document.getElementById("splash").style.display = "block";
        fetchProducts();
        fetchCategories();
        fetchCircularityIndicators();
        fetchCriticalityIndicators();
        const selected = localStorage.getItem("product");
        if (selected) {
            fetchProduct(selected);
            fetchCategoriesLCA(selected);
            fetchCategoriesLCC(selected);
            fetchLcaData(selected);
            fetchLcsaData(selected);
        }
        document.getElementById("splash").style.display = "none";
    }, [fetchProduct]);

    return (
        <>
            <ProductInfo product={product} />
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Select from the following list of products those you want to compare with the
                            current product (note that up to 3 products can be compared at the same time):</div>
                    </div>
                </div>
            </div>
            <div className="row pb-3">
                <div className="col">
                    <div className="card card-flush h-md-100">
                        <div className="card-body">
                            <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 generalTable">
                                <thead>
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3 min-w-100px text-start"></th>
                                        <th className="p-0 pb-3 min-w-100px text-start">NAME</th>
                                        <th className="p-0 pb-3 w-100px text-start pe-7">MODEL</th>
                                        <th className="p-0 pb-3 min-w-100px text-start">COMPANY</th>
                                        <th className="p-0 pb-3 min-w-100px text-start">LOCATION</th>
                                        <th className="p-0 pb-3 w-125px text-start pe-5">LIFETIME</th>
                                        <th className="p-0 pb-3 w-100px text-start pe-7">SCENARIO</th>
                                        <th className="p-0 pb-3 min-w-100px text-start ps-3">OPTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(() => {
                                        if (products.length === 0) {
                                            return (
                                                <tr>
                                                    <td colSpan='8'>
                                                        No product found
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        else {
                                            return (
                                                products.sort((a, b) => (a.productName > b.productName) ? 1 : (a.productName === b.productName) ? ((a.scenario > b.scenario) ? 1 : -1) : -1).map((item, index) => {
                                                    if (item.id !== localStorage.getItem("product")) {
                                                        return (
                                                            <tr key={index} className={selected.findIndex((element) => element === item.id) >= 0 ? 'selected' : 'odd'}>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">
                                                                    {
                                                                        selected.findIndex((element) => element === item.id) >= 0 &&
                                                                        <div style={{ width: '15px', height: '15px', marginLeft: '10px', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                    }
                                                                </td>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item.productName}</td>
                                                                <td className="p-0 pb-3 pt-3 w-100px text-start align-middle pe-7">{item.productModel}</td>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item.company}</td>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item.location}</td>
                                                                <td className="p-0 pb-3 pt-3 w-125px align-middle text-start pe-5">{item.lifeTime}</td>
                                                                <td className="p-0 pb-3 pt-3 w-100px align-middle text-start pe-7">{item.scenario}</td>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px text-start align-middle">
                                                                    {(() => {
                                                                        if (selected.findIndex((element) => element === item.id) >= 0) {
                                                                            return (
                                                                                <button className="btn btn-sm btn-icon btn-danger delete" onClick={() => {
                                                                                    const sltd = selected;
                                                                                    if (sltd.includes(item.id)) {
                                                                                        const index = sltd.indexOf(item.id);
                                                                                        sltd.splice(index, 1);
                                                                                        setSelected(sltd);
                                                                                        getData(selected);
                                                                                    }
                                                                                }}>Unselect</button>
                                                                            )
                                                                        } else if (selected.length < 2) {
                                                                            return (
                                                                                <Button variant="primary" className="select-button ms-3" size="sm" onClick={() => {
                                                                                    const sltd = selected;
                                                                                    if (sltd.length < 3) {
                                                                                        sltd.push(item.id);
                                                                                        setSelected(sltd);
                                                                                        getData(sltd);
                                                                                    }
                                                                                }}>Select</Button>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                })
                                            )
                                        }
                                    })()}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Product comparison</div>
                    </div>
                </div>
            </div>
            <div className="row pb-5 product-comparison">
                <div className="col">
                    <Tabs id="controlled-tab" activeKey={key} onSelect={(k) => changeTab(k)}>
                        <Tab eventKey="lca" title="LCA">
                            {
                                selected.length === 0 && <div className="card card-flush h-md-100">
                                    <div className="card-body">
                                        <div className="mt-3 w-100">
                                            <div className="row">
                                                <div className="col">No product has been selected to compare</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                selected.length > 0 && <div className="card card-flush h-md-100">
                                    <div className="card-body">
                                        <div className="mt-3 w-100">
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <Table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th style={{ backgroundColor: colors[0] }}>{`${product.name} - ${product.scenario}`}</th>
                                                                {
                                                                    selectedProductsLCA.map((item, index) => {
                                                                        return (
                                                                            <th style={{ backgroundColor: colors[index + 1] }} key={index}>{`${products.filter(x => x.id === item.id)[0].productName} - ${products.filter(x => x.id === item.id)[0].scenario}`}</th>
                                                                        );
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><b>Single score</b></td>
                                                                <td><b>{lcsa.lcaSingleScore ? convertExponential(lcsa.lcaSingleScore) : '-'}</b></td>
                                                                {
                                                                    selectedProductsLCSA.map((item, index) => {
                                                                        return (
                                                                            <td key={index}><b>{lcsa.lcaSingleScore ? convertExponential(item.data.lcaSingleScore) : '-'}</b></td>
                                                                        );
                                                                    })
                                                                }
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3 w-100">
                                            <div className="row mt-3">
                                                <div className="col-md-10">
                                                    {
                                                        dataSingleScoreLCA && <Bar options={optionsSingleScoreLCA} data={dataSingleScoreLCA} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3 w-100">
                                            <div className="row">
                                                <div className="col-md-6">Please, select one impact category:</div>
                                                <div className="col-md-6">Value:</div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-6">
                                                    <Form.Select value={categoryLCA} onChange={e => changeCategoryLCA(e.target.value)}>
                                                        {(() => {
                                                            if (categoryLCA === "-") {
                                                                return (
                                                                    <option hidden disabled value="-"></option>
                                                                );
                                                            }
                                                        })()}
                                                        <optgroup label="Principal">
                                                            {
                                                                categoriesLCA.map((item, index) => {
                                                                    if (item.type === "Principal") {
                                                                        return (
                                                                            <option key={index} value={item.category}>{item.category}</option>
                                                                        );
                                                                    }

                                                                })
                                                            }
                                                        </optgroup>
                                                        <optgroup label="Additional">
                                                            {
                                                                categoriesLCA.map((item, index) => {
                                                                    if (item.type === "Additional") {
                                                                        return (
                                                                            <option key={index} value={item.category}>{item.category}</option>
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </optgroup>
                                                    </Form.Select>
                                                </div>
                                                <div className="col-md-6">
                                                    <button type="button" className={valueTypeLCA === "value" ? "btn btn-primary" : "btn btn-secondary"} onClick={() => changeLCAValueType("value")}>Characterised value</button>
                                                    <button type="button" className={valueTypeLCA === "normalized_value" ? "btn btn-primary ms-2" : "btn btn-secondary ms-2"} onClick={() => changeLCAValueType("normalized_value")}>Normalized value</button>
                                                    <button type="button" className={valueTypeLCA === "single_score" ? "btn btn-primary ms-2" : "btn btn-secondary ms-2"} onClick={() => changeLCAValueType("single_score")}>Weighted value</button>
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col">
                                                    {
                                                        dataLCA && <Bar options={optionsLCA} data={dataLCA} style={{ maxHeight: "450px" }} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Tab>
                        <Tab eventKey="lcc" title="LCC">
                            <ProductComparisonLCC
                                product={product} products={products}
                                lcc={lcc} lccTotals={lccTotals} categoriesLCC={categoriesLCC}
                                selectedProductsLCC={selectedProductsLCC} selectedProductsLCCTotals={selectedProductsLCCTotals}
                                selected={selected} lcsa={lcsa} selectedProductsLCSA={selectedProductsLCSA}
                            />
                        </Tab>
                        <Tab eventKey="slca" title="S-LCA">
                            <div className="card card-flush h-md-100">
                                <div className="card-body productcomparison-slca">
                                    {
                                        selected.length > 0 && <ul className="assesment nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold mb-4">
                                            <li className="tabButton nav-item mt-2">
                                                <span className={tab === "performance" ? "active nav-link text-active-primary ms-0 me-10" : "nav-link text-active-primary ms-0 me-10"} onClick={() => setTab("performance")}>Performance assesment</span>
                                            </li>
                                            <li className="tabButton nav-item mt-2">
                                                <span className={tab === "risk" ? "active nav-link text-active-primary ms-0 me-10" : "nav-link text-active-primary ms-0 me-10"} onClick={() => setTab("risk")}>Risk assesment</span>
                                            </li>
                                        </ul>
                                    }
                                    {
                                        tab === 'performance' && <ProductComparisonSLCAPerformance
                                            product={product} products={products}
                                            slcaPerformance={slcaPerformance}
                                            selectedProductsSLCAPerformance={selectedProductsSLCAPerformance}
                                            selected={selected}
                                        />
                                    }
                                    {
                                        tab === 'risk' && <ProductComparisonSLCARisk
                                            product={product} products={products}
                                            slcaRisk={slcaRisk}
                                            selectedProductsSLCARisk={selectedProductsSLCARisk}
                                            selected={selected} lcsa={lcsa} selectedProductsLCSA={selectedProductsLCSA}
                                            categories={categories}
                                        />
                                    }
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="circularity" title="Circularity">
                            <ProductComparisonCircularity
                                product={product} products={products}
                                circularity={circularity} circularityMethodologies={circularityMethodologies} circularityIndicators={circularityIndicators}
                                selectedProductsCircularity={selectedProductsCircularity} selectedProductsCircularityMethodologies={selectedProductsCircularityMethodologies}
                                selected={selected} />
                        </Tab>
                        <Tab eventKey="criticality" title="Criticality">
                            <ProductComparisonCriticality
                                product={product} products={products}
                                categories={categories} criticality={criticality}
                                criticalityCrm={criticalityCrm} criticalityIndicators={criticalityIndicators}
                                selectedProductsCriticality={selectedProductsCriticality}
                                selectedProductsCriticalityCrm={selectedProductsCriticalityCrm}
                                selected={selected}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
}