import React from "react";
import './App.css';
import { Navigate, Routes, Route } from 'react-router-dom';
import RouteGuard from "./auth/RouteGuard";
import RoleGuard from "./auth/RoleGuard";
import LoginPage from "./pages/Login/LoginPage";
import CreateUserPage from "./pages/CreateUser/CreateUserPage";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import Layout from "./pages/Layout/Layout";
import AdminLayout from "./pages/AdminLayout/AdminLayout";

function App() {
    return (
        <>
            <div id="splash" data-visible="true">
                <div className="lds-ripple"><div></div><div></div></div>
            </div>
            <div id="page">
                <Routes>
                    <Route element={<RouteGuard />}>
                        <Route path="/" element={<Layout />} exact />
                        <Route path="/general-info" element={<Layout />} exact />
                        <Route path="/lca" element={<Layout />} exact />
                        <Route path="/circularity" element={<Layout />} exact />
                        <Route path="/criticality" element={<Layout />} exact />
                        <Route path="/lcc" element={<Layout />} exact />
                        <Route path="/s-lca" element={<Layout />} exact />
                        <Route path="/lcsa" element={<Layout />} exact />
                        <Route path="/product-comparison" element={<Layout />} exact />
                        <Route path="/glossary" element={<Layout />} exact />
                        <Route path="/change-password" element={<Layout />} exact />
                        <Route element={<RoleGuard allowedRoles={["admin"]} />}>
                            <Route path="/admin/users" element={<AdminLayout />} exact />
                        </Route>
                    </Route>
                    <Route path="/login" element={<LoginPage />} exact />
                    <Route path="/sign-up" element={<CreateUserPage />} exact />
                    <Route path="/forgot-password" element={<ForgotPasswordPage />} exact />
                    <Route path='*' element={<Navigate to='/' replace={true} />} />
                </Routes>
            </div>
        </>
    );
}

export default App;
