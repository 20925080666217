import React, { useState, useEffect, useCallback } from "react";
import { Table } from 'react-bootstrap';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Chart from 'chart.js/auto';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import { Pie } from 'react-chartjs-2';
import * as FaIcons from "react-icons/fa6";
import ProductInfo from "../Layout/ProductInfo";
Chart.register(ChartjsPluginStacked100);

export default function CriticalityPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [product, setProduct] = useState(null);
    const [results, setResults] = useState(null);
    const [indicators, setIndicators] = useState([]);
    const [crms, setCrms] = useState([]);
    const [allCrm, setAllCrm] = useState([]);
    const [type, setType] = useState('-');
    const [criticality, setCriticality] = useState([]);
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    let criticalities = [];

    const options = {
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                position: 'right'
            }
        },
        maintainAspectRatio: true,
        aspectRatio: 2
    }

    const createCharts = (type) => {
        let crits = criticalities;
        if (crits.length === 0) {
            crits = criticality;
        }
        if (crits.length > 0) {
            const items = crits.filter(x => x.indicator === type)[0].items;
            items.sort((a, b) => (a.position < b.position) ? -1 : 1);
            const data = {
                labels: items.map(x => x.stage),
                datasets: [
                    {
                        label: '',
                        data: items.map(x => x.value),
                        backgroundColor: colors,
                    },
                ],
            }
            setResults(data);
        }
    }

    const fetchProduct = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setProduct(res.data);
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchCriticality = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/criticality`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            criticalities = res.data;
            setCriticality(res.data);
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchCriticalityCrm = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/criticality/crm`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setCrms(res.data);
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchCriticalityCrmAll = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/criticality/crm/all`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setAllCrm(res.data);
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchCriticalityIndicators = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/criticality/indicators`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setIndicators(res.data);
            await fetchCriticality(id);
            await fetchCriticalityCrm(id);
            await fetchCriticalityCrmAll(id);
            if (res.data.length === 1) {
                setType(res.data[0].indicator);
                createCharts(res.data[0].indicator);
            }
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token, fetchCriticality, fetchCriticalityCrm, fetchCriticalityCrmAll]);

    const changeValue = (type) => {
        setType(type);
        createCharts(type);
    }

    useEffect(() => {
        document.getElementById("splash").style.display = "block";
        const selected = localStorage.getItem("product");
        if (selected) {
            fetchProduct(selected);
            fetchCriticalityIndicators(selected);
        }
        document.getElementById("splash").style.display = "none";
    }, [fetchProduct, fetchCriticalityIndicators]);

    return (
        <>
            <ProductInfo product={product} />
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Criticality Assessment</div>
                        <p>
                            The criticality of materials is another topic of focus for ORIENTING. Raw materials are
                            essential to produce a broad range of goods and for the development of eco-efficient
                            technologies. The high dependency on raw materials imports (e.g., into the EU)
                            and the increasing global demand for those materials, highlight the concerns
                            over the risks of supply disruption. In ORIENTING, the EC-CA methodology and
                            GeoPolRisk are the recommended methods to assess criticality. In this dashboard,
                            on the one hand, calculations of different criticality indicators are presented,
                            and on the other hand, a list with the CRM contained in the product and the link to the European
                            Commission's list of strategic raw materials is visualised.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10">
                        <div className="card-body d-flex align-items-end pt-0">
                            <div className="mt-3 w-100">
                                <div className="row">
                                    {
                                        indicators.length === 0 && <div className="col">
                                            <div className="alert alert-warning">
                                                There is no criticality indicator for this product.
                                            </div>
                                        </div>
                                    }
                                    {
                                        indicators.length === 1 && <div className="col">
                                            This is the selected criticality indicator:
                                        </div>
                                    }
                                    {
                                        indicators.length > 1 && <div className="col">
                                            Please, select one criticality indicator to see the results per life cycle stage:
                                        </div>
                                    }
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        {
                                            indicators.length === 1 && <Form.Select value={type} disabled>
                                                <option value={indicators[0].indicator}>{indicators[0].indicator}</option>
                                            </Form.Select>
                                        }
                                        {
                                            indicators.length > 1 && <Form.Select
                                                value={type}
                                                onChange={(e) => { changeValue(e.currentTarget.value) }}
                                            >
                                                {(() => {
                                                    if (type === "-") {
                                                        return (
                                                            <option hidden disabled value="-"></option>
                                                        );
                                                    }
                                                })()}
                                                {
                                                    indicators.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.indicator}>{item.indicator}</option>
                                                        );
                                                    })
                                                }
                                            </Form.Select>
                                        }
                                    </div>
                                </div>
                                {
                                    results !== null && <div className="row mt-5">
                                        <div className="col-md-7">
                                            <Pie data={results} options={options} />
                                        </div>
                                    </div>
                                }
                                <div className="row mt-5">
                                    <div className="col">
                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 generalTable">
                                            <thead>
                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                    <th className="p-0 pb-1 min-w-100px text-start ps-3">CRM LIST</th>
                                                </tr>
                                                <tr className="fs-7 text-gray-700 border-bottom-0">
                                                    <th className="p-0 pb-3 min-w-100px text-start ps-3">The following raw materials are nor longer classified as CRMS (EC, 2023) and are not included in the OOF despite being listed in D2.7: natural rubber and indium.
                                                        Arsenic, Feldspar, Helium, Manganese, Cooper and Nickel, classified as CRMs (EC, 2023), are included in the OOF although not listed in D2.7. Aluminium and bauxite are analysed as one material in the OOF (EC, 2023)</th>
                                                </tr>
                                                <tr className="fs-7 text-gray-700 border-bottom-0">
                                                    <th className="p-0 pb-3 min-w-100px text-end ps-3"><a style={{ color: '#3894ad' }} href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A52023PC0160" target="_blank">EU Strategic Raw Materials List 2023</a></th>
                                                </tr>
                                            </thead>
                                            {
                                                crms.length > 0 && <tbody>
                                                    {
                                                        allCrm.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    {(() => {
                                                                        const memberExists = crms.some(crm => crm.crm === item.crm);
                                                                        if (memberExists) {
                                                                            return (
                                                                                <td className="p-0 ps-3 pb-3 pt-3 min-w-100px align-middle text-start">{item.crm} <FaIcons.FaRegCircleCheck style={{marginTop: '-1px'}} /></td>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            }
                                        </Table>
                                        {
                                            crms.length === 0 && <div className="row">
                                                <div className="col">
                                                    <div className="alert alert-warning">
                                                        This product does not contain any CRM.
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}