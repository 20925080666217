import React, { useEffect, useState, useRef, useCallback } from "react";
import { Table, Form } from 'react-bootstrap';
import axios from 'axios';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

export default function ProductComparisonSLCARisk({ product, products, slcaRisk, selectedProductsSLCARisk, selected, lcsa, selectedProductsLCSA, categories }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const [type, setType] = useState("-");
    const [error, setError] = useState("");
    const [databases, setDatabases] = useState([]);
    const [dataGraph, setDataGraph] = useState(null);
    const [title, setTitle] = useState("");
    const [dataGraphBar, setDataGraphBar] = useState(null);
    const [data, setData] = useState([]);
    const chartRf = useRef();

    const options2 = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (`${item.dataset.label}: ${convertExponential(item.dataset.data[item.dataIndex])} ${item.dataset.unit[item.dataIndex]}`);
                    }
                },
            },
            legend: {
                position: 'right'
            },
            title: {
                display: false
            }
        }
    };

    const options3 = {
        responsive: true,
        scales: {
            y: {
                ticks: {
                    callback: (val) => (convertExponential(val))
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        const index = item.dataset.data.findIndex((element) => element === item.raw);
                        if (item.raw < 0.001) {
                            return (`${item.dataset.label}: ${convertExponential(item.raw)} ${item.dataset.unit[index]}`);
                        } else {
                            return (`${item.dataset.label}: ${convertExponential(item.formattedValue)} ${item.dataset.unit[index]}`);
                        }
                    }
                }
            },
            title: {
                display: false
            }
        }
    };

    const convertExponential = (value) => {
        if (value !== null) {
            let decimals = 0;
            if (Math.floor(value.valueOf()) === value.valueOf()) {
                decimals = 0;
            } else {
                decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
            }
            if (decimals > 2) {
                return value.toExponential(3);
            } else {
                return value;
            }
        }
        else {
            return "-"
        }
    }

    const changeValue = async (type) => {
        setType(type);
    }

    const onClick2 = (event) => {
        const active = getElementAtEvent(chartRf.current, event);
        if (active.length > 0) {
            const label = dataGraph.labels[active[0].index];
            setTitle(label);
            createCharts(label);
        }
    }

    const createCharts = (result) => {
        const topics = [];
        for (let i = 0; i < data.length; i++) {
            const newData = data[i].data.filter(c => c.topic === result)[0].items;
            topics.push({ id: data[i].id, data: newData });
        }

        const tops = [];
        for (let i = 0; i < topics.length; i++) {
            const newTopics = topics[i].data.map(c => c.stage);
            for (let j = 0; j < newTopics.length; j++) {
                tops.push(newTopics[j]);
            }
        }
        const uniqueTopics = [...new Set(tops)];
        const datasets = [];
        for (let i = 0; i < topics.length; i++) {
            const pr = products.filter(x => x.id === topics[i].id)[0];
            const dataset = { label: `${pr.productName} - ${pr.scenario}`, data: [], unit: [], backgroundColor: colors[i] };
            for (let j = 0; j < uniqueTopics.length; j++) {
                let exist = false;
                for (let k = 0; k < topics[i].data.length; k++) {
                    if (topics[i].data[k].stage === uniqueTopics[j]) {
                        dataset.data.push(topics[i].data[k].value);
                        dataset.unit.push(topics[i].data[k].unit);
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    dataset.data.push(0);
                }
            }
            datasets.push(dataset);
        }

        const dataGraph = {
            labels: uniqueTopics,
            datasets: datasets
        }

        setDataGraphBar(dataGraph);
    }

    const getData = useCallback(async (id, selected, database) => {
        const datas = [];
        const data = await fetchSlcaRiskData(id, database);
        const newData = data.map(c => c.items).flat(2);
        newData.sort((a, b) => a.topic.localeCompare(b.topic));
        datas.push({ id: id, data: newData });
        for (let i = 0; i < selected.length; i++) {
            const dataOthers = await fetchSlcaRiskData(selected[i], database);
            const newDataOthers = dataOthers.map(c => c.items).flat(2);
            newDataOthers.sort((a, b) => a.topic.localeCompare(b.topic));
            datas.push({ id: selected[i], data: newDataOthers });
        }
        setData(datas);
        const tops = [];
        for (let i = 0; i < datas.length; i++) {
            const newTopics = datas[i].data.map(c => c.topic);
            for (let j = 0; j < newTopics.length; j++) {
                tops.push(newTopics[j]);
            }
        }
        const uniqueTopics = [...new Set(tops)];

        const datasets = [];
        for (let i = 0; i < datas.length; i++) {
            const pr = products.filter(x => x.id === datas[i].id)[0];
            const dataset = { label: `${pr.productName} - ${pr.scenario}`, data: [], unit: [], backgroundColor: colors[i] };
            for (let j = 0; j < uniqueTopics.length; j++) {
                let exist = false;
                for (let k = 0; k < datas[i].data.length; k++) {
                    if (datas[i].data[k].topic === uniqueTopics[j]) {
                        dataset.data.push(datas[i].data[k].value);
                        dataset.unit.push(datas[i].data[k].unit);
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    dataset.data.push(0);
                }
            }
            datasets.push(dataset);
        };

        const dataGraph = {
            labels: uniqueTopics,
            datasets: datasets
        }

        setDataGraph(dataGraph);
    })

    const fetchSlcaRiskData = async (id, database) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/risk/databases/${database}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (slcaRisk.length > 0 && selectedProductsSLCARisk.length > 0) {
            const totals = slcaRisk.map(e => e.database);
            for (let i = 0; i < selectedProductsSLCARisk.length; i++) {
                for (let j = 0; j < selectedProductsSLCARisk[i].data.length; j++) {
                    totals.push(selectedProductsSLCARisk[i].data[j].database);
                }
            }
            const counts = {};
            const databases = [];
            totals.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
            if ((counts["SHDB v5"] !== undefined && counts["SHDB v5"] > 1) || (counts["PSILCA"] !== undefined && counts["PSILCA"] > 1) ||
                (counts["RepRisk"] !== undefined && counts["RepRisk"] > 1) || (counts["Others"] !== undefined && counts["Others"] > 1)) {
                if (counts["SHDB v5"] !== undefined && counts["SHDB v5"] > 1) {
                    databases.push("SHDB v5");
                }
                if (counts["PSILCA"] !== undefined && counts["PSILCA"] > 1) {
                    databases.push("PSILCA");
                }
                if (counts["RepRisk"] !== undefined && counts["RepRisk"] > 1) {
                    databases.push("RepRisk");
                }
                if (counts["Others"] !== undefined && counts["Others"] > 1) {
                    databases.push("Others");
                }
                setDatabases(databases);
                if (databases.length === 1) {
                    getData(localStorage.getItem("product"), selected, databases[0])
                }
            } else {
                setError("These cannot be compared as different databases have been used in each case")
            }
        }
    }, [slcaRisk, selectedProductsSLCARisk]);

    return (
        <>
            {
                selected.length > 0 &&
                <>
                    <div className="row mt-3">
                        <div className="col">
                            <Table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style={{ backgroundColor: colors[0] }}>{`${product.name} - ${product.scenario}`}</th>
                                        {
                                            selectedProductsSLCARisk.map((item, index) => {
                                                return (
                                                    <th style={{ backgroundColor: colors[index + 1] }} key={index}>{`${products.filter(x => x.id === item.id)[0].productName} - ${products.filter(x => x.id === item.id)[0].scenario}`}</th>
                                                );
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>Single score</b></td>
                                        <td><b>{lcsa.slcaSingleScore ? convertExponential(lcsa.slcaSingleScore) : '-'}</b></td>
                                        {
                                            selectedProductsLCSA.map((item, index) => {
                                                return (
                                                    <td key={index}><b>{lcsa.slcaSingleScore ? convertExponential(item.data.slcaSingleScore) : '-'}</b></td>
                                                );
                                            })
                                        }
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {databases.length === 1 && <div className="col">
                            This is the selected database:
                        </div>}
                        {(databases.length > 1) && <div className="col">
                            Please, select one database:
                        </div>}
                    </div>
                    {
                        error !== "" && <div className="row mt-3">
                            <div className="col">
                                <div className="alert alert-warning">
                                    {error}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row mt-3">
                        <div className="col-md-6">
                            {
                                databases.length === 1 && <Form.Select disabled value={type}>
                                    <option value={databases[0]}>{databases[0]}</option>
                                </Form.Select>
                            }
                            {
                                (databases.length === 0 || databases.length > 1) && <Form.Select
                                    value={type}
                                    onChange={(e) => { changeValue(e.currentTarget.value) }}
                                >
                                    {(() => {
                                        if (type === "-") {
                                            return (
                                                <option hidden disabled defaultValue={"-"} value="-"></option>
                                            );
                                        }
                                    })()}
                                    {
                                        databases.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            );
                                        })
                                    }
                                </Form.Select>
                            }
                        </div>
                    </div>
                    {
                        dataGraph !== null && databases.length > 0 &&
                        <div className="row mt-5">
                            <div className="col-md-10">
                                <Bar className="mb-5" style={{ height: "auto" }} data={dataGraph} ref={chartRf} options={options2} onClick={onClick2} />
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col">
                            If you select one social indicator, the results per life cycle stage will be shown:
                        </div>
                    </div>
                    {
                        dataGraphBar !== null && <><div className="row mt-5">
                            <div className="col-md-6">
                                <div className="title2">{title}</div>

                            </div>
                        </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Bar className="mt-5" options={options3} data={dataGraphBar} />
                                </div>
                            </div>
                        </>
                    }

                </>
            }
        </>
    );
}