import React, { useState, useEffect, useCallback } from "react";
import * as FaIcons from "react-icons/fa6";
import axios from 'axios';
import { Table, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function GeneralInfoPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [products, setProducts] = useState([]);
    const [query, setQuery] = useState('')
    const [showDelete, setShowDelete] = useState(false);
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const token = localStorage.getItem("token");
    const fetchProducts = useCallback(async () => {
        axios.get(`${apiUrl}/products`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.data.length === 0) {
                localStorage.removeItem("product");
            }
            setProducts(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const getSelectedProduct = useCallback(async () => {
        const selected = localStorage.getItem("product");
        if (selected !== null) {
            setSelected(selected);
        }
    }, []);

    const handleShowDeleteModal = () => setShowDelete(true);
    const handleCloseDeleteModal = () => setShowDelete(false);

    const handleClickDeleteModal = (id) => {
        setDeleteId(id);
        handleShowDeleteModal();
    }

    const handleChange = (e) => {
        setQuery(e.target.value)
    }

    const handleDeleteClick = () => {
        const token = localStorage.getItem("token");
        axios.delete(`${apiUrl}/products/${deleteId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async () => {
            toast.success('Product successfully deleted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            if (localStorage.getItem('product') === deleteId) {
                localStorage.removeItem('product');
                window.location.reload(false);
            }
            await fetchProducts();
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setDeleteId("");
            handleCloseDeleteModal();
        });
    }

    const handleCloseModal = () => {
        sessionStorage.setItem('firstTime', false);
        setShow(false);
    }

    const update = async () => {
        document.getElementById("splash").style.display = "block";
        await fetchProducts();
        document.getElementById("splash").style.display = "none";
    }

    const selectProduct = (id) => {
        const prd = localStorage.getItem('product');
        localStorage.setItem("product", id);
        setSelected(id);
        if (prd === null) {
            window.location.reload(false);
        }
    }

    useEffect(() => {
        document.getElementById("splash").style.display = "block";
        fetchProducts();
        const firstTime = sessionStorage.getItem('firstTime');
        if (firstTime === null || firstTime === 'true') {
            setShow(true);
        }
        getSelectedProduct();
        document.getElementById("splash").style.display = "none";
    }, [fetchProducts, getSelectedProduct]);

    return (
        <>
            <Modal show={showDelete} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete product</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDeleteClick}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" centered show={show} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>Welcome to the ORIENTING LCSA tool</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Under the framework of the ORIENTING project, a user-friendly, hands-on, and free-of-charge tool
                        to visualise and communicate Life Cycle Sustainability (LCSA) results in a dynamic, versatile,
                        and easy to understand manner has been developed.</p>
                    <p>
                        By feeding the tool with an Excel with the results from your product(s), the tool shows the
                        results for the environmental life cycle assessment (LCA), life cycle costing (LCC), social life cycle assessment
                        (S-LCA), circularity and criticality assessments, as well as for the LCSA integration approach developed under the framework of the project.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Product(s) & Company Information</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card card-flush h-md-100">
                        <div className="card-header align-items-center py-2 gap-2 gap-md-5">
                            <div className="d-flex flex-wrap gap-2">
                                <button onClick={update} className="btn btn-sm btn-icon btn-light btn-active-light-primary reload">
                                    <FaIcons.FaArrowsRotate />
                                </button>
                            </div>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <div className="d-flex align-items-center position-relative">
                                        <i className="fa-solid fa-magnifying-glass position-absolute ms-4"></i>
                                        <input type="text" data-listing-filter="search" value={query} onChange={handleChange} className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px ps-11" placeholder="Search" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 generalTable">
                                <thead>
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3 min-w-100px text-start ps-3">SELECTED</th>
                                        <th className="p-0 pb-3 min-w-100px text-start">NAME</th>
                                        <th className="p-0 pb-3 w-100px text-start pe-7">MODEL</th>
                                        <th className="p-0 pb-3 min-w-100px text-start">COMPANY</th>
                                        <th className="p-0 pb-3 min-w-100px text-start">LOCATION</th>
                                        <th className="p-0 pb-3 w-125px text-start pe-5">LIFETIME</th>
                                        <th className="p-0 pb-3 w-100px text-start pe-7">SCENARIO</th>
                                        <th className="p-0 pb-3 w-50px text-end pe-3">OPTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(() => {
                                        if (products.length === 0) {
                                            return (
                                                <tr>
                                                    <td colSpan='8'>
                                                        No product found
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        else {
                                            return (
                                                products.filter(item => item.productName.toLowerCase().includes(query.toLowerCase())).map((item, index) => {
                                                    return (
                                                        <tr key={index} className={item.id === selected ? 'selected' : 'odd'}>
                                                            <td className="p-0 pb-3 pt-3 min-w-100px text-start align-middle">
                                                                {(() => {
                                                                    if (item.id === selected) {
                                                                        return (
                                                                            <div className="selected-button ms-5"><FaIcons.FaCheck /></div>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Button variant="primary" className="select-button ms-3" size="sm" onClick={() => selectProduct(item.id)}>Select</Button>
                                                                        )
                                                                    }
                                                                })()}
                                                            </td>
                                                            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item.productName}</td>
                                                            <td className="p-0 pb-3 pt-3 w-100px text-start align-middle pe-7">{item.productModel}</td>
                                                            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item.company}</td>
                                                            <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item.location}</td>
                                                            <td className="p-0 pb-3 pt-3 w-125px align-middle text-start pe-5">{item.lifeTime}</td>
                                                            <td className="p-0 pb-3 pt-3 w-100px align-middle text-start pe-7">{item.scenario}</td>
                                                            <td className="p-0 pb-3 pt-3 w-50px align-middle text-end">
                                                                <button className="btn btn-sm btn-icon btn-danger delete me-3" onClick={() => handleClickDeleteModal(item.id)}>
                                                                    <FaIcons.FaTrash />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            )
                                        }
                                    })()}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}