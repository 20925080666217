import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import { useLocation } from 'react-router-dom';
import UsersPage from "../Users/UsersPage";
import 'react-toastify/dist/ReactToastify.css';
import './admin_layout.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';

export default function AdminLayout() {
    const pathname = useLocation().pathname;
    return (
        <>
            <ToastContainer />
            <div className="d-flex flex-column flex-root app-root">
                <div className="app-page flex-column flex-column-fluid ">
                    <AdminHeader />
                </div>
            </div>
            <div className="admin_wrapper">
                <AdminSidebar />
                {(() => {
                    if (pathname === "/admin/users") {
                        return (
                            <UsersPage />
                        )
                    }
                })()}
            </div>
        </>
    )
};