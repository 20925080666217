import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import LCCTable from "./LCCTableSection";
import ProductInfo from "../Layout/ProductInfo";
import './lcc.css';
import Chart from 'chart.js/auto';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import { Bar, Doughnut, getElementAtEvent } from 'react-chartjs-2';
import * as BsIcons from "react-icons/bs";
Chart.register(ChartjsPluginStacked100);

export default function LCCPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [isChecked, setIsChecked] = useState(true);
    const [product, setProduct] = useState(null);
    const [categories, setCategories] = useState([]);
    const [tab, setTab] = useState("Undiscounted");
    const [lcc, setLcc] = useState([]);
    const [lccTotals, setLccTotals] = useState(null);
    const [key, setKey] = useState('results');
    const [dataPresentCosts, setDataPresentCosts] = useState(null);
    const [dataUndiscountedCosts, setDataUndiscountedCosts] = useState(null);
    const [dataNetPresentCosts, setDataNetPresentCosts] = useState(null);
    const [dataNetUndiscountedCosts, setDataNetUndiscountedCosts] = useState(null);
    const [totalCharts, setTotalCharts] = useState([]);
    const [data2PresentTotalCosts, setData2PresentTotalCosts] = useState(null);
    const [data2UndiscountedTotalCosts, setData2UndiscountedTotalCosts] = useState(null);
    const [data2UndiscountedNetCosts, setData2UndiscountedNetCosts] = useState(null);
    const [data2PresentNetCosts, setData2PresentNetCosts] = useState(null);
    const [hotspotPresentTitle, setHotspotPresentTitle] = useState("");
    const [hotspotUndiscountedTitle, setHotspotUndiscountedTitle] = useState("");
    const [hotspotPresentNetTitle, setHotspotPresentNetTitle] = useState("");
    const [hotspotUndiscountedNetTitle, setHotspotUndiscountedNetTitle] = useState("");
    const [dataGraphNetPresentValue, setDataGraphNetPresentValue] = useState(null);
    const [dataGraphTotalUndiscountedValue, setDataGraphTotalUndiscountedValue] = useState(null);
    const [dataGraphTotalPresentCosts, setDataGraphTotalPresentCosts] = useState(null);
    const [dataGraphTotalUndiscountedCosts, setDataGraphTotalUndiscountedCosts] = useState(null);
    const [dataGraphNetPresentCosts, setDataGraphNetPresentCosts] = useState(null);
    const [dataGraphNetUndiscountedCosts, setDataGraphNetUndiscountedCosts] = useState(null);
    const [totalPresentCostsTitle, setTotalPresentCostsTitle] = useState("");
    const [totalUndiscountedCostsTitle, setTotalUndiscountedCostsTitle] = useState("");
    const [netPresentValueTitle, setNetPresentValueTitle] = useState("");
    const [totalUndiscountedValueTitle, setTotalUndiscountedValueTitle] = useState("");
    const [netPresentCostsTitle, setNetPresentCostsTitle] = useState("");
    const [netUndiscountedCostsTitle, setNetUndiscountedCostsTitle] = useState("");
    const [dataGraphTotalPresentCostsBar, setDataGraphTotalPresentCostsBar] = useState(null);
    const [dataGraphTotalUndiscountedCostsBar, setDataGraphTotalUndiscountedCostsBar] = useState(null);
    const [dataGraphNetPresentValueBar, setDataGraphNetPresentValueBar] = useState(null);
    const [dataGraphTotalUndiscountedValueBar, setDataGraphTotalUndiscountedValueBar] = useState(null);
    const [dataGraphNetUndiscountedCostsBar, setDataGraphNetUndiscountedCostsBar] = useState(null);
    const [dataGraphNetPresentCostsBar, setDataGraphNetPresentCostsBar] = useState(null);
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const [valueTypeLCC, setValueTypeLCC] = useState("normalized_value");
    const chartRef2Present = useRef();
    const chartRef2Undiscounted = useRef();
    const chartRef2PresentNet = useRef();
    const chartRef2UndiscountedNet = useRef();
    const chartRefPresent2 = useRef();
    const chartRefUndiscounted2 = useRef();
    const chartRefNetPresent2 = useRef();
    const chartRefNetUndiscounted2 = useRef();
    const chartRfNetPresentCosts = useRef();
    const chartRfNetPresentValue = useRef();
    const chartRfTotalPresentCosts = useRef();
    const chartRfTotalUndiscountedValue = useRef();
    const chartRfTotalUndiscountedCosts = useRef();
    const chartRfNetUndiscountedCosts = useRef();
    const chartRfNetPresentCostsNormalized = useRef();
    const chartRfNetPresentValueNormalized = useRef();
    const chartRfTotalPresentCostsNormalized = useRef();
    const chartRfTotalUndiscountedValueNormalized = useRef();
    const chartRfTotalUndiscountedCostsNormalized = useRef();
    const chartRfNetUndiscountedCostsNormalized = useRef();

    const options = {
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (`${item.formattedValue}%`);
                    }
                }
            }
        }
    };
    const options2 = {
        indexAxis: "y",
        duration: 0,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (`${item.formattedValue}€`);
                    }
                }
            }
        }
    };
    const options3 = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (`${item.formattedValue}€`);
                    }
                }
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            }
        }
    };
    const options5 = {
        indexAxis: "y",
        duration: 0,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (`${item.dataset.label}: ${item.formattedValue}%`);
                    }
                },
            },
            stacked100: { enable: true, replaceTooltipLabel: false },
        },
    };
    const options6 = {
        animation: {
            duration: 0
        },
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
            legend: {
                position: 'right'
            },
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (`${item.formattedValue}%`);
                    }
                }
            }
        }
    };
    let cate = [];

    const fetchProduct = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setProduct(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchLccData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/lcc`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setLcc(response.data);
            const result = response.data.filter(el => el.value !== null);
            if (result.length > 0) {
                setTab(result[0].valueType);
            }
            createGraphs(response.data, true);
            createCharts(response.data, true);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchLccTotalsData = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/lcc/totals`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setLccTotals(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);


    const changeTabValue = (tabValue) => {
        setTab(tabValue);
    }

    const createGraphs = (results, checked) => {
        const newData2 = results.map(c => c.items).flat(2);
        let newData = newData2.map(c => c.items).flat(2);
        if (!checked) {
            newData = newData.filter(e => e.indicator !== 'A. Capital Costs');
        }
        newData.sort((a, b) => a.indicator.localeCompare(b.indicator));
        let stages = null;
        if (cate.length > 0) {
            stages = cate.map(c => c);
        }
        else {
            stages = categories.map(c => c);
        }
        let datasets = [];
        for (let i = 0; i < stages.length; i++) {
            const dataset = { label: stages[i].stage, data: [], backgroundColor: colors[i] };
            for (let j = 0; j < newData.length; j++) {
                if (newData[j].valueType === "Total undiscounted costs" && newData[j].indicator !== "I. Positive cash flows") {
                    let exist = false;
                    for (let k = 0; k < newData[j].items.length; k++) {
                        if (newData[j].items[k].stage === stages[i].stage) {
                            dataset.data.push(newData[j].items[k].value);
                            exist = true;
                            break;
                        }
                    }
                    if (!exist) {
                        dataset.data.push(0);
                    }
                }
            }
            datasets.push(dataset);
        };
        let data = {
            labels: newData.filter(c => c.valueType === "Total undiscounted costs" && c.indicator !== "I. Positive cash flows").map(c => c.indicator),
            datasets: datasets
        }

        setDataGraphTotalUndiscountedCosts(data);

        datasets = [];
        for (let i = 0; i < stages.length; i++) {
            const dataset = { label: stages[i].stage, data: [], backgroundColor: colors[i] };
            for (let j = 0; j < newData.length; j++) {
                if (newData[j].valueType === "Total present costs" && newData[j].indicator !== "I. Positive cash flows") {
                    let exist = false;
                    for (let k = 0; k < newData[j].items.length; k++) {
                        if (newData[j].items[k].stage === stages[i].stage) {
                            dataset.data.push(newData[j].items[k].value);
                            exist = true;
                            break;
                        }
                    }
                    if (!exist) {
                        dataset.data.push(0);
                    }
                }
            }
            datasets.push(dataset);
        };
        data = {
            labels: newData.filter(c => c.valueType === "Total present costs" && c.indicator !== "I. Positive cash flows").map(c => c.indicator),
            datasets: datasets
        }

        setDataGraphTotalPresentCosts(data);

        datasets = [];
        for (let i = 0; i < stages.length; i++) {
            const dataset = { label: stages[i].stage, data: [], backgroundColor: colors[i] };
            for (let j = 0; j < newData.length; j++) {
                if (newData[j].valueType === "Net undiscounted costs" && newData[j].indicator !== "I. Positive cash flows") {
                    let exist = false;
                    for (let k = 0; k < newData[j].items.length; k++) {
                        if (newData[j].items[k].stage === stages[i].stage) {
                            dataset.data.push(newData[j].items[k].value);
                            exist = true;
                            break;
                        }
                    }
                    if (!exist) {
                        dataset.data.push(0);
                    }
                }
            }
            datasets.push(dataset);
        };
        data = {
            labels: newData.filter(c => c.valueType === "Net undiscounted costs" && c.indicator !== "I. Positive cash flows").map(c => c.indicator),
            datasets: datasets
        }
        setDataGraphNetUndiscountedCosts(data);

        datasets = [];
        for (let i = 0; i < stages.length; i++) {
            const dataset = { label: stages[i].stage, data: [], backgroundColor: colors[i] };
            for (let j = 0; j < newData.length; j++) {
                if (newData[j].valueType === "Net present costs" && newData[j].indicator !== "I. Positive cash flows") {
                    let exist = false;
                    for (let k = 0; k < newData[j].items.length; k++) {
                        if (newData[j].items[k].stage === stages[i].stage) {
                            dataset.data.push(newData[j].items[k].value);
                            exist = true;
                            break;
                        }
                    }
                    if (!exist) {
                        dataset.data.push(0);
                    }
                }
            }
            datasets.push(dataset);
        };
        data = {
            labels: newData.filter(c => c.valueType === "Net present costs" && c.indicator !== "I. Positive cash flows").map(c => c.indicator),
            datasets: datasets
        }
        setDataGraphNetPresentCosts(data);

        datasets = [];
        for (let i = 0; i < stages.length; i++) {
            const dataset = { label: stages[i].stage, data: [], backgroundColor: colors[i] };
            for (let j = 0; j < newData.length; j++) {
                if (newData[j].valueType === "Total undiscounted value" && newData[j].indicator !== "I. Positive cash flows") {
                    let exist = false;
                    for (let k = 0; k < newData[j].items.length; k++) {
                        if (newData[j].items[k].stage === stages[i].stage) {
                            dataset.data.push(newData[j].items[k].value);
                            exist = true;
                            break;
                        }
                    }
                    if (!exist) {
                        dataset.data.push(0);
                    }
                }
            }
            datasets.push(dataset);
        };
        data = {
            labels: newData.filter(c => c.valueType === "Total undiscounted value" && c.indicator !== "I. Positive cash flows").map(c => c.indicator),
            datasets: datasets
        }
        setDataGraphTotalUndiscountedValue(data);

        datasets = [];
        for (let i = 0; i < stages.length; i++) {
            const dataset = { label: stages[i].stage, data: [], backgroundColor: colors[i] };
            for (let j = 0; j < newData.length; j++) {
                if (newData[j].valueType === "Net present value" && newData[j].indicator !== "I. Positive cash flows") {
                    let exist = false;
                    for (let k = 0; k < newData[j].items.length; k++) {
                        if (newData[j].items[k].stage === stages[i].stage) {
                            dataset.data.push(newData[j].items[k].value);
                            exist = true;
                            break;
                        }
                    }
                    if (!exist) {
                        dataset.data.push(0);
                    }
                }
            }
            datasets.push(dataset);
        };
        data = {
            labels: newData.filter(c => c.valueType === "Net present value" && c.indicator !== "I. Positive cash flows").map(c => c.indicator),
            datasets: datasets
        }
        setDataGraphNetPresentValue(data);
    }

    const handleOnChange = () => {
        setIsChecked(!isChecked);
        createGraphs(lcc, !isChecked);
        createCharts(lcc, !isChecked);
        setDataGraphNetPresentValueBar(null);
        setDataGraphTotalPresentCostsBar(null);
        setDataGraphNetPresentCostsBar(null);
        setDataGraphTotalUndiscountedValueBar(null);
        setDataGraphTotalUndiscountedCostsBar(null);
        setDataGraphNetUndiscountedCostsBar(null);
        setDataGraphNetPresentValueBar(null);
        setDataGraphTotalPresentCostsBar(null);
        setDataGraphNetPresentCostsBar(null);
        setDataGraphTotalUndiscountedValueBar(null);
        setDataGraphTotalUndiscountedCostsBar(null);
        setDataGraphNetUndiscountedCostsBar(null);
        setData2UndiscountedTotalCosts(null);
        setData2UndiscountedNetCosts(null);
        setData2PresentTotalCosts(null);
        setData2PresentNetCosts(null);
    };

    const createCharts = (results, checked) => {
        const newData2 = results.map(c => c.items).flat(2);
        let newData = newData2.map(c => c.items).flat(2);
        if (!checked) {
            newData = newData.filter(e => e.indicator !== 'A. Capital Costs');
        }
        const positives = newData.filter(function (a) { return a.value >= 0; });
        let newDataTotalPresent = [];
        let newDataTotalUndiscounted = [];
        let newDataNetPresent = [];
        let newDataNetUndiscounted = [];
        for (let i = 0; i < positives.length; i++) {
            if (positives[i].valueType === "Total undiscounted costs") {
                newDataTotalUndiscounted.push(positives[i]);
            } else if (positives[i].valueType === "Total present costs") {
                newDataTotalPresent.push(positives[i]);
            } else if (positives[i].valueType === "Net undiscounted costs") {
                newDataNetUndiscounted.push(positives[i]);
            } else if (positives[i].valueType === "Net present costs") {
                newDataNetPresent.push(positives[i]);
            }
        }

        const newListTotalPresent = newDataTotalPresent.filter(c => c.value !== null);
        const newListTotalUndiscounted = newDataTotalUndiscounted.filter(c => c.value !== null);
        const newListNetPresent = newDataNetPresent.filter(c => c.value !== null);
        const newListNetUndiscounted = newDataNetUndiscounted.filter(c => c.value !== null);

        newListTotalPresent.sort((a, b) => (a.value < b.value) ? 1 : -1);
        newListTotalUndiscounted.sort((a, b) => (a.value < b.value) ? 1 : -1);
        newListNetPresent.sort((a, b) => (a.value < b.value) ? 1 : -1);
        newListNetUndiscounted.sort((a, b) => (a.value < b.value) ? 1 : -1);

        setTotalCharts([]);

        const dataUndiscountedCosts = {
            extra: "TOTAL UNDISCOUNTED COSTS",
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: []
            }]
        }
        let total = (newListTotalUndiscounted.reduce((n, { value }) => n + value, 0));
        for (let i = 0; i < newListTotalUndiscounted.length; i++) {
            if (newListTotalUndiscounted[i].indicator !== "I. Positive cash flows") {
                dataUndiscountedCosts.labels.push(`${newListTotalUndiscounted[i].indicator}`);
                dataUndiscountedCosts.datasets[0].data.push((newListTotalUndiscounted[i].value * 100) / total);
                dataUndiscountedCosts.datasets[0].backgroundColor.push(colors[i]);
            }
        }
        setDataUndiscountedCosts(dataUndiscountedCosts);
        if (dataUndiscountedCosts.labels.length > 0) {
            setTotalCharts(totalCharts => [...totalCharts, "TOTAL UNDISCOUNTED COSTS"]);
        }

        const dataPresentCosts = {
            extra: "TOTAL PRESENT COSTS",
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: []
            }]
        }

        total = (newListTotalPresent.reduce((n, { value }) => n + value, 0));
        for (let i = 0; i < newListTotalPresent.length; i++) {
            if (newListTotalPresent[i].indicator !== "I. Positive cash flows") {
                dataPresentCosts.labels.push(`${newListTotalPresent[i].indicator}`);
                dataPresentCosts.datasets[0].data.push((newListTotalPresent[i].value * 100) / total);
                dataPresentCosts.datasets[0].backgroundColor.push(colors[i]);
            }
        }
        setDataPresentCosts(dataPresentCosts);
        if (dataPresentCosts.labels.length > 0) {
            setTotalCharts(totalCharts => [...totalCharts, "TOTAL PRESENT COSTS"]);
        }

        const dataNetUndiscountedCosts = {
            extra: "NET UNDISCOUNTED COSTS",
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: []
            }]
        }

        total = (newListNetUndiscounted.reduce((n, { value }) => n + value, 0));
        for (let i = 0; i < newListNetUndiscounted.length; i++) {
            if (newListNetUndiscounted[i].indicator !== "I. Positive cash flows") {
                dataNetUndiscountedCosts.labels.push(`${newListNetUndiscounted[i].indicator}`);
                dataNetUndiscountedCosts.datasets[0].data.push((newListNetUndiscounted[i].value * 100) / total);
                dataNetUndiscountedCosts.datasets[0].backgroundColor.push(colors[i]);
            }
        }
        setDataNetUndiscountedCosts(dataNetUndiscountedCosts);
        if (dataNetUndiscountedCosts.labels.length > 0) {
            setTotalCharts(totalCharts => [...totalCharts, "NET UNDISCOUNTED COSTS"]);
        }

        const dataNetPresentCosts = {
            extra: "NET PRESENT COSTS",
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: []
            }]
        }

        total = (newListNetPresent.reduce((n, { value }) => n + value, 0));
        for (let i = 0; i < newListNetPresent.length; i++) {
            if (newListNetPresent[i].indicator !== "I. Positive cash flows") {
                dataNetPresentCosts.labels.push(`${newListNetPresent[i].indicator}`);
                dataNetPresentCosts.datasets[0].data.push((newListNetPresent[i].value * 100) / total);
                dataNetPresentCosts.datasets[0].backgroundColor.push(colors[i]);
            }
        }
        setDataNetPresentCosts(dataNetPresentCosts);
        if (dataNetPresentCosts.labels.length > 0) {
            setTotalCharts(totalCharts => [...totalCharts, "NET PRESENT COSTS"]);
        }
    }

    const onClickGraph = (event, type) => {
        let active = null;
        let label = null;
        if (type === "Total present costs") {
            active = getElementAtEvent(chartRefPresent2.current, event);
            if (active.length > 0) {
                label = dataPresentCosts.labels[active[0].index];
            }
        } else if (type === "Total undiscounted costs") {
            active = getElementAtEvent(chartRefUndiscounted2.current, event);
            if (active.length > 0) {
                label = dataUndiscountedCosts.labels[active[0].index];
            }
        } else if (type === "Net present costs") {
            active = getElementAtEvent(chartRefNetPresent2.current, event);
            if (active.length > 0) {
                label = dataNetPresentCosts.labels[active[0].index];
            }
        } else if (type === "Net undiscounted costs") {
            active = getElementAtEvent(chartRefNetUndiscounted2.current, event);
            if (active.length > 0) {
                label = dataNetUndiscountedCosts.labels[active[0].index];
            }
        }
        if (label !== null && label.length > 0) {
            if (type === "Total present costs") {
                setHotspotPresentTitle(label);
            } else if (type === "Total undiscounted costs") {
                setHotspotUndiscountedTitle(label);
            } else if (type === "Net present costs") {
                setHotspotPresentNetTitle(label);
            } else if (type === "Net undiscounted costs") {
                setHotspotUndiscountedNetTitle(label);
            }
            const newData2 = lcc.filter(c => c.valueType === type)[0].items;
            const newData = newData2.map(c => c.items).flat(2);
            createCharts2(newData.filter(c => c.indicator === label)[0], type);
        }
    }

    const onClickReal = (event, type) => {
        let active = null;
        if (type === "Total present costs") {
            active = getElementAtEvent(chartRfTotalPresentCosts.current, event);
        } else if (type === "Total undiscounted costs") {
            active = getElementAtEvent(chartRfTotalUndiscountedCosts.current, event);
        } else if (type === "Net present costs") {
            active = getElementAtEvent(chartRfNetPresentCosts.current, event);
        } else if (type === "Net undiscounted costs") {
            active = getElementAtEvent(chartRfNetUndiscountedCosts.current, event);
        } else if (type === "Total undiscounted value") {
            active = getElementAtEvent(chartRfTotalUndiscountedValue.current, event);
        } else if (type === "Net present value") {
            active = getElementAtEvent(chartRfNetPresentValue.current, event);
        }
        if (active.length > 0) {
            let label = null;
            if (type === "Total present costs") {
                label = dataGraphTotalPresentCosts.labels[active[0].index];
                setTotalPresentCostsTitle(label);
            } else if (type === "Total undiscounted costs") {
                label = dataGraphTotalUndiscountedCosts.labels[active[0].index];
                setTotalUndiscountedCostsTitle(label);
            } else if (type === "Net present costs") {
                label = dataGraphNetPresentCosts.labels[active[0].index];
                setNetPresentCostsTitle(label);
            } else if (type === "Net undiscounted costs") {
                label = dataGraphNetUndiscountedCosts.labels[active[0].index];
                setNetUndiscountedCostsTitle(label);
            } else if (type === "Total undiscounted value") {
                label = dataGraphTotalUndiscountedValue.labels[active[0].index];
                setTotalUndiscountedValueTitle(label);
            } else if (type === "Net present value") {
                label = dataGraphNetPresentValue.labels[active[0].index];
                setNetPresentValueTitle(label);
            }
            const newData2 = lcc.filter(c => c.valueType === type)[0].items;
            const newData = newData2.map(c => c.items).flat(2);
            createCharts3(newData.filter(c => c.indicator === label && c.valueType === type)[0], type);
        }
    }

    const onClickNormalized = (event, type) => {
        let active = null;
        if (type === "Total present costs") {
            active = getElementAtEvent(chartRfTotalPresentCostsNormalized.current, event);
        } else if (type === "Total undiscounted costs") {
            active = getElementAtEvent(chartRfTotalUndiscountedCostsNormalized.current, event);
        } else if (type === "Net present costs") {
            active = getElementAtEvent(chartRfNetPresentCostsNormalized.current, event);
        } else if (type === "Net undiscounted costs") {
            active = getElementAtEvent(chartRfNetUndiscountedCostsNormalized.current, event);
        } else if (type === "Total undiscounted value") {
            active = getElementAtEvent(chartRfTotalUndiscountedValueNormalized.current, event);
        } else if (type === "Net present value") {
            active = getElementAtEvent(chartRfNetPresentValueNormalized.current, event);
        }
        if (active.length > 0) {
            let label = null;
            if (type === "Total present costs") {
                label = dataGraphTotalPresentCosts.labels[active[0].index];
                setTotalPresentCostsTitle(label);
            } else if (type === "Total undiscounted costs") {
                label = dataGraphTotalUndiscountedCosts.labels[active[0].index];
                setTotalUndiscountedCostsTitle(label);
            } else if (type === "Net present costs") {
                label = dataGraphNetPresentCosts.labels[active[0].index];
                setNetPresentCostsTitle(label);
            } else if (type === "Net undiscounted costs") {
                label = dataGraphNetUndiscountedCosts.labels[active[0].index];
                setNetUndiscountedCostsTitle(label);
            } else if (type === "Total undiscounted value") {
                label = dataGraphTotalUndiscountedValue.labels[active[0].index];
                setTotalUndiscountedValueTitle(label);
            } else if (type === "Net present value") {
                label = dataGraphNetPresentValue.labels[active[0].index];
                setNetPresentValueTitle(label);
            }
            const newData2 = lcc.filter(c => c.valueType === type)[0].items;
            const newData = newData2.map(c => c.items).flat(2);
            createCharts4(newData.filter(c => c.indicator === label && c.valueType === type)[0], type);
        }
    }

    const createCharts2 = (result, type) => {
        const newList = result.items.filter(c => c.value !== null);
        newList.sort((a, b) => (a.value < b.value) ? 1 : -1);
        const data2 = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: []
            }]
        }
        const total = (newList.reduce((n, { value }) => n + value, 0));
        for (let i = 0; i < newList.length; i++) {
            data2.labels.push(newList[i].stage);
            data2.datasets[0].data.push((newList[i].value * 100) / total);
            data2.datasets[0].backgroundColor.push(colors[i]);
        }
        if (type === "Total present costs") {
            setData2PresentTotalCosts(data2);
        } else if (type === "Total undiscounted costs") {
            setData2UndiscountedTotalCosts(data2);
        } else if (type === "Net present costs") {
            setData2PresentNetCosts(data2);
        } else if (type === "Net undiscounted costs") {
            setData2UndiscountedNetCosts(data2);
        }
    }

    const createCharts3 = (result, type) => {
        const data = {
            labels: result.items.map(c => c.stage),
            datasets: [
                {
                    label: '',
                    data: result.items.map(x => x.value),
                    backgroundColor: colors,
                }
            ]
        }
        if (type === "Total present costs") {
            setDataGraphTotalPresentCostsBar(data);
        } else if (type === "Total undiscounted costs") {
            setDataGraphTotalUndiscountedCostsBar(data);
        } else if (type === "Net present costs") {
            setDataGraphNetPresentCostsBar(data);
        } else if (type === "Net undiscounted costs") {
            setDataGraphNetUndiscountedCostsBar(data);
        } else if (type === "Total undiscounted value") {
            setDataGraphTotalUndiscountedValueBar(data);
        } else if (type === "Net present value") {
            setDataGraphNetPresentValueBar(data);
        }
    }

    const createCharts4 = (result, type) => {
        var dat = []
        const total = (result.items.reduce((n, { value }) => n + value, 0));
        result.items.forEach(curEl => {
            const percent = (curEl.value * 100) / total;
            dat.push(percent);
        });
        console.log(dat);
        const data = {
            labels: result.items.map(c => c.stage),
            datasets: [
                {
                    label: '',
                    data: dat,
                    backgroundColor: colors,
                }
            ]
        }
        if (type === "Total present costs") {
            setDataGraphTotalPresentCostsBar(data);
        } else if (type === "Total undiscounted costs") {
            setDataGraphTotalUndiscountedCostsBar(data);
        } else if (type === "Net present costs") {
            setDataGraphNetPresentCostsBar(data);
        } else if (type === "Net undiscounted costs") {
            setDataGraphNetUndiscountedCostsBar(data);
        } else if (type === "Total undiscounted value") {
            setDataGraphTotalUndiscountedValueBar(data);
        } else if (type === "Net present value") {
            setDataGraphNetPresentValueBar(data);
        }
    }

    const changeLCCValueType = (value) => {
        setValueTypeLCC(value);
    }

    useEffect(() => {
        document.getElementById("splash").style.display = "block";
        const selected = localStorage.getItem("product");
        const token = localStorage.getItem("token");
        axios.get(`${apiUrl}/products/${selected}/lcc/stages`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            cate = response.data;
            setCategories(response.data);
            if (selected) {
                fetchProduct(selected);
                fetchLccData(selected);
                fetchLccTotalsData(selected);
            }
            document.getElementById("splash").style.display = "none";
        }).catch(err => {
            console.log(err);
            document.getElementById("splash").style.display = "none";
        });
    }, [fetchProduct, fetchLccData, token]);

    return (
        <>
            <ProductInfo product={product} />
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Life Cycle Costing</div>
                        <p>
                            Life cycle costing summarises all costs directly covered by actors throughout a
                            product's life cycle (e.g., supplier, producer, user or consumer, end-of-life agent).
                            In this tool, the LCC results are presented through two dashboards: the "results dashboard"
                            and the "hotspot analysis dashboard". The results dashboard shows the economic indicators in
                            present and undiscounted values & costs through tables and graphs. Additionally, total
                            values for the different economic indicators for the entire life cycle are highlighted.
                            The hotspot analysis dashboard shows the most relevant costs by life cycle stages and/or economic inventory categories.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row pb-5 lcc">
                <div className="col">
                    <Tabs id="controlled-tab" activeKey={key} onSelect={(k) => setKey(k)}>
                        <Tab eventKey="results" title="Results">
                            <div className="card card-flush h-md-100">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col vertical-tabs">
                                            <Tabs>
                                                <Tab eventKey="data" title={<BsIcons.BsFillCalculatorFill />}>
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <div className="alert alert-info">
                                                                Note that including the user's capital cost may dilute the relevance of other cost categories as it typically incorporates upstream producer's costs.
                                                                For this reason, especially when adopting a producer perspective, it is suggested to exclude this element from the analysis.
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" checked={isChecked} onChange={handleOnChange} id="includeCapitalCosts" />
                                                                <label className="form-check-label" htmlFor="includeCapitalCosts">
                                                                    Including the user’s capital cost (i.e., the product acquisition price)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        lcc.length > 0 && <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold mt-3 mb-5">
                                                            {
                                                                lcc.filter(el => el.value !== null).map((item, index) => {
                                                                    return (
                                                                        <li key={index} className="tabButton nav-item mt-2">
                                                                            <span className={tab === item.valueType ? "nav-link text-active-primary ms-0 me-10 active" : "nav-link text-active-primary ms-0 me-10"} onClick={() => changeTabValue(item.valueType)}>{item.valueType}</span>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            {
                                                                categories.length > 0 && <Table className="table table-bordered align-middle gs-0 gy-3 my-0 generalTable">
                                                                    <thead>
                                                                        <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                            <th className="p-0 pb-3 m-w-100px max-w-100px text-start lcc-header"></th>
                                                                            <th className="p-0 pb-3 m-w-100px max-w-100px text-start lcc-header"></th>
                                                                            {
                                                                                categories.map((c, i) => (
                                                                                    <th className="p-0 pb-3 m-w-100px max-w-100px text-center lcc-header" key={i}>{c.stage}</th>
                                                                                ))
                                                                            }
                                                                            <th className="p-0 pb-3 m-w-100px max-w-100px text-center lcc-header gray-background">Entire life cycle</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            lcc.filter(el => el.valueType.toLowerCase().includes(tab.toLowerCase()))
                                                                                .map((item, index) => {
                                                                                    return (
                                                                                        <LCCTable key={`${tab}-${index}`} row={item} categories={categories} isChecked={isChecked} />
                                                                                    );
                                                                                })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            }
                                                            {
                                                                categories.length === 0 && <div className="mt-3">
                                                                    <div className="col">
                                                                        <div className="alert alert-warning">
                                                                            Note that no disaggregated data has been entered into the OOF
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="card card-flush h-md-100 bg-blue results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        {
                                                                            (() => {
                                                                                if (lccTotals && lccTotals.netPresentValue !== null) {
                                                                                    if (isChecked) {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.netPresentValue.toFixed(2) + "€"}</span>)
                                                                                    } else if (!isChecked && lcc.filter(el => el.valueType === "Net present value").length > 0) {
                                                                                        const value = lcc.filter(el => el.valueType === "Net present value")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{(lccTotals.netPresentValue - value).toFixed(2) + "€"}</span>)
                                                                                    } else {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.netPresentValue.toFixed(2) + "€"}</span>)
                                                                                    }
                                                                                } else {
                                                                                    return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">-</span>)
                                                                                }
                                                                            })()
                                                                        }
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Net Present Value</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-flush h-md-100 bg-green mt-3 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        {
                                                                            (() => {
                                                                                if (lccTotals && lccTotals.totalUndiscountedValue !== null) {
                                                                                    if (isChecked) {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.totalUndiscountedValue.toFixed(2) + "€"}</span>)
                                                                                    } else if (!isChecked && lcc.filter(el => el.valueType === "Total undiscounted costs").length > 0) {
                                                                                        const value = lcc.filter(el => el.valueType === "Total undiscounted costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{(lccTotals.totalUndiscountedValue - value).toFixed(2) + "€"}</span>)
                                                                                    } else {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.totalUndiscountedValue.toFixed(2) + "€"}</span>)
                                                                                    }
                                                                                } else {
                                                                                    return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">-</span>)
                                                                                }
                                                                            })()
                                                                        }
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Total Undiscounted Value</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-flush h-md-100 bg-orange mt-3 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        {
                                                                            (() => {
                                                                                if (lccTotals && lccTotals.totalPresentCosts !== null) {
                                                                                    if (isChecked) {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.totalPresentCosts.toFixed(2) + "€"}</span>)
                                                                                    } else if (!isChecked && lcc.filter(el => el.valueType === "Total present costs").length > 0) {
                                                                                        const value = lcc.filter(el => el.valueType === "Total present costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{(lccTotals.totalPresentCosts - value).toFixed(2) + "€"}</span>)
                                                                                    } else {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.totalPresentCosts.toFixed(2) + "€"}</span>)
                                                                                    }
                                                                                } else {
                                                                                    return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">-</span>)
                                                                                }
                                                                            })()
                                                                        }
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Total Present Costs</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-flush h-md-100 bg-red mt-3 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        {
                                                                            (() => {
                                                                                if (lccTotals && lccTotals.totalUndiscountedCosts !== null) {
                                                                                    if (isChecked) {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.totalUndiscountedCosts.toFixed(2) + "€"}</span>)
                                                                                    } else if (!isChecked && lcc.filter(el => el.valueType === "Total undiscounted costs").length > 0) {
                                                                                        const value = lcc.filter(el => el.valueType === "Total undiscounted costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{(lccTotals.totalUndiscountedCosts - value).toFixed(2) + "€"}</span>)
                                                                                    } else {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.totalUndiscountedCosts.toFixed(2) + "€"}</span>)
                                                                                    }
                                                                                } else {
                                                                                    return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">-</span>)
                                                                                }
                                                                            })()
                                                                        }
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Total Undiscounted Costs</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-flush h-md-100 bg-brown mt-3 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        {
                                                                            (() => {
                                                                                if (lccTotals && lccTotals.netPresentCosts !== null) {
                                                                                    if (isChecked) {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.netPresentCosts.toFixed(2) + "€"}</span>)
                                                                                    } else if (!isChecked && lcc.filter(el => el.valueType === "Net present costs").length > 0) {
                                                                                        const value = lcc.filter(el => el.valueType === "Net present costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{(lccTotals.netPresentCosts - value).toFixed(2) + "€"}</span>)
                                                                                    } else {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.netPresentCosts.toFixed(2) + "€"}</span>)
                                                                                    }
                                                                                } else {
                                                                                    return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">-</span>)
                                                                                }
                                                                            })()
                                                                        }
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Net Present Costs</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card card-flush h-md-100 bg-black2 mt-3 results">
                                                                <div className="card-body">
                                                                    <div className="card-title d-flex flex-column">
                                                                        {
                                                                            (() => {
                                                                                if (lccTotals && lccTotals.netUndiscountedCosts !== null) {
                                                                                    if (isChecked) {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.netUndiscountedCosts.toFixed(2) + "€"}</span>)
                                                                                    } else if (!isChecked && lcc.filter(el => el.valueType === "Net undiscounted costs").length > 0) {
                                                                                        const value = lcc.filter(el => el.valueType === "Net undiscounted costs")[0].items.filter(c => c.indicatorType === "Private costs")[0].items.filter(c => c.indicator === "A. Capital Costs")[0].value;
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{(lccTotals.netUndiscountedCosts - value).toFixed(2) + "€"}</span>)
                                                                                    } else {
                                                                                        return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{lccTotals.netUndiscountedCosts.toFixed(2) + "€"}</span>)
                                                                                    }
                                                                                } else {
                                                                                    return (<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">-</span>)
                                                                                }
                                                                            })()
                                                                        }
                                                                        <span className="text-gray-400 pt-1 fw-semibold fs-6">Net Undiscounted Costs</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="graphs" title={<BsIcons.BsGraphUp />}>
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <div className="alert alert-info">
                                                                Note that including the user's capital cost may dilute the relevance of other cost categories as it typically incorporates upstream producer's costs.
                                                                For this reason, especially when adopting a producer perspective, it is suggested to exclude this element from the analysis.
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" checked={isChecked} onChange={handleOnChange} id="includeCapitalCosts" />
                                                                <label className="form-check-label" htmlFor="includeCapitalCosts">
                                                                    Including the user’s capital cost (i.e., the product acquisition price)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        lcc.length > 0 && <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent mt-3 fs-5 fw-bold mb-5">
                                                            {
                                                                lcc.filter(el => el.value !== null).map((item, index) => {
                                                                    return (
                                                                        <li key={index} className="tabButton nav-item mt-2">
                                                                            <span className={tab === item.valueType ? "nav-link text-active-primary ms-0 me-10 active" : "nav-link text-active-primary ms-0 me-10"} onClick={() => changeTabValue(item.valueType)}>{item.valueType}</span>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                    {
                                                        categories.length === 0 && <div className="row mt-3" style={{ paddingBottom: "43px" }}>
                                                            <div className="col">
                                                                <div className="alert alert-warning">
                                                                    Note that no disaggregated data has been entered into the OOF
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        categories.length > 0 && <div className="row mb-5">
                                                            <div className="col mb-3">
                                                                <div className="text-center">
                                                                    <button type="button" className={valueTypeLCC === "normalized_value" ? "btn btn-primary ms-2" : "btn btn-secondary ms-2"} onClick={() => changeLCCValueType("normalized_value")}>Normalized value</button>
                                                                    <button type="button" className={valueTypeLCC === "real_value" ? "btn btn-primary ms-2" : "btn btn-secondary ms-2"} onClick={() => changeLCCValueType("real_value")}>Real value</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net present value" && dataGraphNetPresentValue !== null && valueTypeLCC === "real_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphNetPresentValue} ref={chartRfNetPresentValue} options={options2} onClick={(e) => onClickReal(e, "Net present value")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total present costs" && dataGraphTotalPresentCosts !== null && valueTypeLCC === "real_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphTotalPresentCosts} ref={chartRfTotalPresentCosts} options={options2} onClick={(e) => onClickReal(e, "Total present costs")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net present costs" && dataGraphNetPresentCosts !== null && valueTypeLCC === "real_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphNetPresentCosts} ref={chartRfNetPresentCosts} options={options2} onClick={(e) => onClickReal(e, "Net present costs")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total undiscounted value" && dataGraphTotalUndiscountedValue !== null && valueTypeLCC === "real_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphTotalUndiscountedValue} ref={chartRfTotalUndiscountedValue} options={options2} onClick={(e) => onClickReal(e, "Total undiscounted value")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total undiscounted costs" && dataGraphTotalUndiscountedCosts !== null && valueTypeLCC === "real_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphTotalUndiscountedCosts} ref={chartRfTotalUndiscountedCosts} options={options2} onClick={(e) => onClickReal(e, "Total undiscounted costs")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net undiscounted costs" && dataGraphNetUndiscountedCosts !== null && valueTypeLCC === "real_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphNetUndiscountedCosts} ref={chartRfNetUndiscountedCosts} options={options2} onClick={(e) => onClickReal(e, "Net undiscounted costs")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net present value" && dataGraphNetPresentValue !== null && valueTypeLCC === "normalized_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphNetPresentValue} ref={chartRfNetPresentValueNormalized} options={options5} onClick={(e) => onClickNormalized(e, "Net present value")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total present costs" && dataGraphTotalPresentCosts !== null && valueTypeLCC === "normalized_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphTotalPresentCosts} ref={chartRfTotalPresentCostsNormalized} options={options5} onClick={(e) => onClickNormalized(e, "Total present costs")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net present costs" && dataGraphNetPresentCosts !== null && valueTypeLCC === "normalized_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphNetPresentCosts} ref={chartRfNetPresentCostsNormalized} options={options5} onClick={(e) => onClickNormalized(e, "Net present costs")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total undiscounted value" && dataGraphTotalUndiscountedValue !== null && valueTypeLCC === "normalized_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphTotalUndiscountedValue} ref={chartRfTotalUndiscountedValueNormalized} options={options5} onClick={(e) => onClickNormalized(e, "Total undiscounted value")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total undiscounted costs" && dataGraphTotalUndiscountedCosts !== null && valueTypeLCC === "normalized_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphTotalUndiscountedCosts} ref={chartRfTotalUndiscountedCostsNormalized} options={options5} onClick={(e) => onClickNormalized(e, "Total undiscounted costs")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net undiscounted costs" && dataGraphNetUndiscountedCosts !== null && valueTypeLCC === "normalized_value" && <div className="row">

                                                            <div className="col">
                                                                <Bar className="mb-5" style={{ maxHeight: "450px" }} data={dataGraphNetUndiscountedCosts} ref={chartRfNetUndiscountedCostsNormalized} options={options5} onClick={(e) => onClickNormalized(e, "Net undiscounted costs")} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        categories.length > 0 && <div className="row">
                                                            <div className="col">
                                                                If you select one of the economic inventory categories, the results per life cycle stage will be shown:
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net present value" && dataGraphNetPresentValueBar !== null && valueTypeLCC === "real_value" && <div className="row">
                                                            <div className="col-md-6 mt-4">
                                                                <div className="title2 mt-4">{netPresentValueTitle}</div>
                                                                <Bar className="mt-5" options={options3} data={dataGraphNetPresentValueBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total present costs" && dataGraphTotalPresentCostsBar !== null && valueTypeLCC === "real_value" && <div className="row">
                                                            <div className="col-md-6 mt-4">
                                                                <div className="title2 mt-4">{totalPresentCostsTitle}</div>
                                                                <Bar className="mt-5" options={options3} data={dataGraphTotalPresentCostsBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net present costs" && dataGraphNetPresentCostsBar !== null && valueTypeLCC === "real_value" && <div className="row">
                                                            <div className="col-md-6 mt-4">
                                                                <div className="title2 mt-4">{netPresentCostsTitle}</div>
                                                                <Bar className="mt-5" options={options3} data={dataGraphNetPresentCostsBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total undiscounted value" && dataGraphTotalUndiscountedValueBar !== null && valueTypeLCC === "real_value" && <div className="row">
                                                            <div className="col-md-6 mt-4">
                                                                <div className="title2 mt-4">{totalUndiscountedValueTitle}</div>
                                                                <Bar className="mt-5" options={options3} data={dataGraphTotalUndiscountedValueBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total undiscounted costs" && dataGraphTotalUndiscountedCostsBar !== null && valueTypeLCC === "real_value" && <div className="row">
                                                            <div className="col-md-6 mt-4">
                                                                <div className="title2 mt-4">{totalUndiscountedCostsTitle}</div>
                                                                <Bar className="mt-5" options={options3} data={dataGraphTotalUndiscountedCostsBar} />
                                                            </div>
                                                        </div >
                                                    }
                                                    {
                                                        tab === "Net undiscounted costs" && dataGraphNetUndiscountedCostsBar !== null && valueTypeLCC === "real_value" && <div className="row">
                                                            <div className="col-md-6 mt-4">
                                                                <div className="title2 mt-4">{netUndiscountedCostsTitle}</div>
                                                                <Bar className="mt-5" options={options3} data={dataGraphNetUndiscountedCostsBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net present value" && dataGraphNetPresentValueBar !== null && valueTypeLCC === "normalized_value" && <div className="row">
                                                            <div className="col-md-4 mt-4">
                                                                <div className="title2 mt-4">{netPresentValueTitle}</div>
                                                                <Doughnut options={options6} data={dataGraphNetPresentValueBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total present costs" && dataGraphTotalPresentCostsBar !== null && valueTypeLCC === "normalized_value" && <div className="row">
                                                            <div className="col-md-4 mt-4">
                                                                <div className="title2 mt-4">{totalPresentCostsTitle}</div>
                                                                <Doughnut options={options6} data={dataGraphTotalPresentCostsBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Net present costs" && dataGraphNetPresentCostsBar !== null && valueTypeLCC === "normalized_value" && <div className="row">
                                                            <div className="col-md-4 mt-4">
                                                                <div className="title2 mt-4">{netPresentCostsTitle}</div>
                                                                <Doughnut options={options6} data={dataGraphNetPresentCostsBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total undiscounted value" && dataGraphTotalUndiscountedValueBar !== null && valueTypeLCC === "normalized_value" && <div className="row">
                                                            <div className="col-md-4 mt-4">
                                                                <div className="title2 mt-4">{totalUndiscountedValueTitle}</div>
                                                                <Doughnut options={options6} data={dataGraphTotalUndiscountedValueBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        tab === "Total undiscounted costs" && dataGraphTotalUndiscountedCostsBar !== null && valueTypeLCC === "normalized_value" && <div className="row">
                                                            <div className="col-md-4 mt-4">
                                                                <div className="title2 mt-4">{totalUndiscountedCostsTitle}</div>
                                                                <Doughnut options={options6} data={dataGraphTotalUndiscountedCostsBar} />
                                                            </div>
                                                        </div >
                                                    }
                                                    {
                                                        tab === "Net undiscounted costs" && dataGraphNetUndiscountedCostsBar !== null && valueTypeLCC === "normalized_value" && <div className="row">
                                                            <div className="col-md-4 mt-4">
                                                                <div className="title2 mt-4">{netUndiscountedCostsTitle}</div>
                                                                <Doughnut options={options6} data={dataGraphNetUndiscountedCostsBar} />
                                                            </div>
                                                        </div>
                                                    }
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="hotspot_analysis" title="hotspot analysis">
                            <div className="card card-flush h-md-100 hotspot_analysis">
                                <div className="card-body">
                                    {
                                        categories.length > 0 && <div className="row mt-3">
                                            <div className="col">
                                                <div className="alert alert-info">
                                                    Note that including the user's capital cost may dilute the relevance of other cost categories as it typically incorporates upstream producer's costs.
                                                    For this reason, especially when adopting a producer perspective, it is suggested to exclude this element from the analysis.
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={isChecked} onChange={handleOnChange} id="includeCapitalCosts" />
                                                    <label className="form-check-label" htmlFor="includeCapitalCosts">
                                                        Including the user’s capital cost (i.e., the product acquisition price)
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mt-4">
                                        <div className="col">
                                            {
                                                categories.length === 0 && <div className="row">
                                                    <div className="col">
                                                        <div className="alert alert-warning">
                                                            Note that no disaggregated data has been entered into the OOF
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                categories.length > 0 &&
                                                <p>These are the most relevant cost. If you select one of those, the results per life cycle stage will be shown:</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            const graphs = []
                                            totalCharts.map((item, key) => {
                                                {
                                                    (() => {
                                                        if (dataUndiscountedCosts.extra === item) {
                                                            graphs.push(
                                                                <div key={key} className="row mt-5">
                                                                    <div className="col-md-4">
                                                                        <div className="title">{item}</div>
                                                                        <Doughnut ref={chartRefUndiscounted2} data={dataUndiscountedCosts} options={options6} onClick={(e) => onClickGraph(e, "Total undiscounted costs")} />
                                                                    </div>
                                                                    <div className="col-md-5 offset-md-1">
                                                                        {
                                                                            data2UndiscountedTotalCosts !== null && data2UndiscountedTotalCosts.labels.length > 0 &&
                                                                            <>
                                                                                <div className="title2 mt-5">{hotspotUndiscountedTitle}</div>
                                                                                <Bar ref={chartRef2Undiscounted} data={data2UndiscountedTotalCosts} options={options} />
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        if (dataNetUndiscountedCosts.extra === item) {
                                                            graphs.push(
                                                                <div key={key} className="row mt-5">
                                                                    <div className="col-md-4">
                                                                        <div className="title">{item}</div>
                                                                        <Doughnut ref={chartRefNetUndiscounted2} data={dataNetUndiscountedCosts} options={options6} onClick={(e) => onClickGraph(e, "Net undiscounted costs")} />
                                                                    </div>
                                                                    <div className="col-md-5 offset-md-1">
                                                                        {
                                                                            data2UndiscountedNetCosts !== null && data2UndiscountedNetCosts.labels.length > 0 &&
                                                                            <>
                                                                                <div className="title2 mt-5">{hotspotUndiscountedNetTitle}</div>
                                                                                <Bar ref={chartRef2UndiscountedNet} data={data2UndiscountedNetCosts} options={options} />
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        if (dataPresentCosts.extra === item) {
                                                            graphs.push(
                                                                <div key={key} className="row mt-5">
                                                                    <div className="col-md-4">
                                                                        <div className="title">{item}</div>
                                                                        <Doughnut ref={chartRefPresent2} data={dataPresentCosts} options={options6} onClick={(e) => onClickGraph(e, "Total present costs")} />
                                                                    </div>
                                                                    <div className="col-md-5 offset-md-1">
                                                                        {
                                                                            data2PresentTotalCosts !== null && data2PresentTotalCosts.labels.length > 0 &&
                                                                            <>
                                                                                <div className="title2 mt-5">{hotspotPresentTitle}</div>
                                                                                <Bar ref={chartRef2Present} data={data2PresentTotalCosts} options={options} />
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        } if (dataNetPresentCosts.extra === item) {
                                                            graphs.push(
                                                                <div key={key} className="row mt-5">
                                                                    <div className="col-md-4">
                                                                        <div className="title">{item}</div>
                                                                        <Doughnut ref={chartRefNetPresent2} data={dataNetPresentCosts} options={options6} onClick={(e) => onClickGraph(e, "Net present costs")} />
                                                                    </div>
                                                                    <div className="col-md-5 offset-md-1">
                                                                        {
                                                                            data2PresentNetCosts !== null && data2PresentNetCosts.labels.length > 0 &&
                                                                            <>
                                                                                <div className="title2 mt-5">{hotspotPresentNetTitle}</div>
                                                                                <Bar ref={chartRef2PresentNet} data={data2PresentNetCosts} options={options} />
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })()
                                                }
                                            })
                                            return graphs
                                        })()
                                    }
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div >
        </>
    );
}