import SLCARiskTableSubRow from "./SLCARiskTableSubRow";

export default function SLCARiskTable({ row, index }) {
    const convertExponential = (value) => {
        let decimals = 0;
        if (Math.floor(value.valueOf()) === value.valueOf()) {
            decimals = 0;
        } else {
            decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
        }
        if (decimals > 2) {
            return value.toExponential(3);
        } else {
            return value;
        }
    }
    return (
        <>
            <tr key={index}>
                <td className="p-0 pb-3 pt-3 w-50px align-middle text-start"></td>
                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{row.stakeholder}</td>
                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{row.unit ? row.unit.split(" ").map((text, i) => {
                    if (text === "m3") {
                        return <span key={`${index}-${i}`}>m<sup>3</sup></span>
                    } else {
                        const new_text = text.replace("eq", "<sub>eq</sub>");
                        return (
                            <span key={`${index}-${i}`} dangerouslySetInnerHTML={{
                                __html: `${new_text} `
                            }} />
                        )
                    }
                }) : '-'}</td>
                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-center">{row.value !== null ? convertExponential(row.value) : '-'}</td>
            </tr>
            {
                row.items.map((item2, index2) => {
                    const key = `${index}-${index2}`;
                    return (
                        <SLCARiskTableSubRow row={item2} key={key} />
                    )
                })
            }
        </>
    );
}