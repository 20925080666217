// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27white%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glossary .accordion {
    --bs-accordion-btn-active-icon: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.glossary .accordion-item .accordion-button:not(.collapsed) {
    background-color: #3894ad !important;
    color: white;
}

.glossary .accordion-item .accordion-button{
    font-weight: bold;
}

.glossary .accordion-item .accordion-button:hover,
.glossary .accordion-item .accordion-button:active,
.glossary .accordion-item .accordion-button:focus,
.glossary .accordion-item .accordion-button:focus-visible,
.glossary .accordion-item .accordion-button:visited {
    outline: none !important;
    box-shadow: none;
}

.glossary .accordion-item .accordion-button:not(.collapsed)::after {
    fill: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Glossary/glossary.css"],"names":[],"mappings":"AAAA;IACI,uEAA2S;AAC/S;;AAEA;IACI,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;;;;IAKI,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".glossary .accordion {\n    --bs-accordion-btn-active-icon: url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e\");\n}\n\n.glossary .accordion-item .accordion-button:not(.collapsed) {\n    background-color: #3894ad !important;\n    color: white;\n}\n\n.glossary .accordion-item .accordion-button{\n    font-weight: bold;\n}\n\n.glossary .accordion-item .accordion-button:hover,\n.glossary .accordion-item .accordion-button:active,\n.glossary .accordion-item .accordion-button:focus,\n.glossary .accordion-item .accordion-button:focus-visible,\n.glossary .accordion-item .accordion-button:visited {\n    outline: none !important;\n    box-shadow: none;\n}\n\n.glossary .accordion-item .accordion-button:not(.collapsed)::after {\n    fill: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
