import { React, useState } from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.css';
import './ChangePasswordPage.css';

export default function ChangePasswordPage() {
    const { handleSubmit, register, watch, formState: { errors } } = useForm();
    const [error, setError] = useState('');

    const onSubmit = async data => {
        const passwordPayload = {
            password: data.password
        };
        const apiUrl = process.env.REACT_APP_API_URL;
        axios.patch(`${apiUrl}/users/change-password`, passwordPayload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            //redirect user to home page
            window.location.href = '/general-info';
            setError('');
        }).catch(err => setError(err.response.data.detail));
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Change password</div>
                    </div>
                </div>
            </div>
            <div className="row pb-5 changePassword">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <form id="changePasswordContainer" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label className="form-label">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        {...register("password", {
                                            required: "Required",
                                            pattern: {
                                                value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                                                message: "Password requirements: 8-20 characters, 1 number, 1 letter, 1 symbol."
                                            }
                                        })}
                                    />
                                    {errors.password && <div className="d-grid gap-2 mt-1 error">{errors.password.message}</div>}
                                </div>
                                <div className="form-group mt-4">
                                    <label className="form-label">Repeat password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        {...register("confirm_password", {
                                            required: "Required",
                                            pattern: {
                                                value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                                                message: "Password requirements: 8-20 characters, 1 number, 1 letter, 1 symbol."
                                            },
                                            validate: (val) => {
                                                if (watch('password') !== val) {
                                                    return "Your passwords do no match";
                                                }
                                            },
                                        })}
                                    />
                                    {errors.confirm_password && <div className="d-grid gap-2 mt-1 error">{errors.confirm_password.message}</div>}
                                </div>

                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn submit">
                                        Change
                                    </button>
                                </div>

                                {error !== '' &&
                                    <div className="d-grid gap-2 mt-2 error">
                                        {error}
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}