import React, { useState, useEffect } from 'react';
import * as BiIcons from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

function User() {
    const navigate = useNavigate();
    const [user, setUser] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        if (isLoggedIn) {
            const token = localStorage.getItem("token");
            let decodedToken = null;
            if (token) {
                decodedToken = jwtDecode(token);
                setUser(decodedToken.email);
            }
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('product');
            navigate("/login");
        }
        
    }, [navigate, isLoggedIn]);

    return (
        <div className="user">
            <BiIcons.BiUser />
            {user}
            <Link to="#" className='menu-bars'>
                <BiIcons.BiExit onClick={() => setIsLoggedIn(false)} />
            </Link>
        </div>
    )
}

export default User;