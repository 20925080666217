import Header from "./Header";
import Sidebar from "./Sidebar";
import { useLocation } from 'react-router-dom';
import GeneralInfoPage from "../GeneralInfo/GeneralInfoPage";
import LCAPage from "../LCA/LCAPage";
import CircularityPage from "../Circularity/CircularityPage";
import CriticalityPage from "../Criticality/CriticalityPage";
import LCCPage from "../LCC/LCCPage";
import SLCAPage from "../SLCA/SLCAPage";
import LCSAPage from "../LCSA/LCSAPage";
import ProductComparisonPage from "../ProductComparison/ProductComparisonPage";
import GlossaryPage from "../Glossary/GlossaryPage";
import ChangePasswordPage from "../ChangePassword/ChangePasswordPage";
import 'react-toastify/dist/ReactToastify.css';
import './layout.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';

export default function Layout() {
    const pathname = useLocation().pathname;
    return (
        <>
            <ToastContainer />
            <div className="d-flex flex-column flex-root app-root">
                <div className="app-page flex-column flex-column-fluid ">
                    <Header />
                </div>
            </div>
            <div className="wrapper">
                <Sidebar />
                {(() => {
                    if (pathname === "/") {
                        window.location.replace('/general-info');
                    }
                    else if (pathname === "/general-info") {
                        return (
                            <GeneralInfoPage />
                        )
                    } else if (pathname === "/lca") {
                        return (
                            <LCAPage />
                        )
                    } else if (pathname === "/circularity") {
                        return (
                            <CircularityPage />
                        )
                    } else if (pathname === "/criticality") {
                        return (
                            <CriticalityPage />
                        )
                    } else if (pathname === "/lcc") {
                        return (
                            <LCCPage />
                        )
                    } else if (pathname === "/s-lca") {
                        return (
                            <SLCAPage />
                        )
                    } else if (pathname === "/lcsa") {
                        return (
                            <LCSAPage />
                        )
                    } else if (pathname === "/product-comparison") {
                        return (
                            <ProductComparisonPage />
                        )
                    } else if (pathname === "/glossary") {
                        return (
                            <GlossaryPage />
                        )
                    } else if (pathname === "/change-password") {
                        return (
                            <ChangePasswordPage />
                        )
                    }
                })()}
            </div>
        </>
    )
};