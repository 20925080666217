import * as FaIcons from "react-icons/fa6";

export default function SLCATable({ row, index, stages }) {
    const getColor = (rowValue) => {
        switch (rowValue) {
            case null:
                return 'white';
            case -2:
                return 'red';
            case -1:
                return '#ffc000';
            case 0:
                return '#dddddd';
            case 1:
                return '#92d050'
            case 2:
                return '#00b050'
            default:
                break;
        }
    }

    return (
        <>
            <tr>
                <td key={index} colSpan={9}><b>{row.stakeholder}</b></td>
            </tr>
            {
                row.items.map((item2, index2) => {
                    return (
                        <tr key={`${index}-${index2}`}>
                            <td key={`${index}-${index2}-a`}>{item2.topic} {
                                (() => {
                                    if (item2.critical) {
                                        return (
                                            <p className="critical">(significant)</p>
                                        );
                                    }
                                })()
                            }</td>
                            {(() => {
                                const line = [];
                                for (let i = 1; i <= 7; i++) {
                                    const total = stages.filter(e => e.position === i);
                                    if (total.length === 1) {
                                        item2.items.sort((a, b) => (a.supplier > b.supplier) ? 1 : -1);
                                        for (let j = 0; j < total[0].actors.length; j++) {
                                            const idx = item2.items.findIndex(e => e.position === i && e.supplier === total[0].actors[j]);
                                            if (idx > -1) {
                                                line.push(<td key={`${index}-${index2}-el-${i}-${j}`} className="p-0 pb-3 pt-3 min-w-100px align-middle text-center" style={{ backgroundColor: getColor(item2.items[idx].value) }}>{item2.items[idx].value}</td>);
                                            } else {
                                                line.push(<td key={`${index}-${index2}-el-${i}-${j}`} className="p-0 pb-3 pt-3 min-w-100px align-middle text-center">-</td>);
                                            }
                                        }
                                    }
                                }
                                return (
                                    line
                                );
                            })()}
                            <td key={`${index}-${index2}-as`} className="p-0 pb-3 pt-3 min-w-100px align-middle text-center" style={{ backgroundColor: getColor(item2.aggregatedScore) }}><strong>{item2.aggregatedScore}</strong>
                                {(() => {
                                    if (item2.flag === "red") {
                                        return (
                                            <FaIcons.FaFlag style={{ marginLeft: "5px", color: "red" }} />
                                        );
                                    } else if (item2.flag === "green") {
                                        return (
                                            <FaIcons.FaFlag style={{ marginLeft: "5px", color: "green" }} />
                                        );
                                    }
                                })()}
                            </td>
                        </tr>
                    );
                })
            }
        </>
    );
}