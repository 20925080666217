// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin_sidebar {
	position: fixed;
	top: 80px;
	left: 0;
	width: 280px;
	height: 100%;
	background: #e7e7e7;
	transition: width .1s linear;
}

.admin_wrapper.hide .admin_sidebar {
	width: 0px;
	transition: width .25s linear;
}

.admin_sidebar .menu {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s, opacity 0.5s linear;
}

.admin_wrapper.hide .admin_sidebar .menu {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.5s linear;
}

.admin_sidebar .menu-item {
	display: block;
	padding: 5px 0;
}

.admin_sidebar .menu-item .menu-link {
	cursor: pointer;
	display: block;
	align-items: center;
	padding: 0;
	margin: 0.65rem 25px;
	padding: 0.65rem 25px;
	transition: none;
	outline: none !important;
	text-decoration: none;
	color: black;
}

.admin_sidebar .menu-item .menu-link.active {
	background-color: #f29109;
	color: white;
	border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminLayout/admin_sidebar.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,SAAS;CACT,OAAO;CACP,YAAY;CACZ,YAAY;CACZ,mBAAmB;CACnB,4BAA4B;AAC7B;;AAEA;CACC,UAAU;CACV,6BAA6B;AAC9B;;AAEA;CACC,mBAAmB;CACnB,UAAU;CACV,8CAA8C;AAC/C;;AAEA;CACC,kBAAkB;CAClB,UAAU;CACV,8CAA8C;AAC/C;;AAEA;CACC,cAAc;CACd,cAAc;AACf;;AAEA;CACC,eAAe;CACf,cAAc;CACd,mBAAmB;CACnB,UAAU;CACV,oBAAoB;CACpB,qBAAqB;CACrB,gBAAgB;CAChB,wBAAwB;CACxB,qBAAqB;CACrB,YAAY;AACb;;AAEA;CACC,yBAAyB;CACzB,YAAY;CACZ,kBAAkB;AACnB","sourcesContent":[".admin_sidebar {\n\tposition: fixed;\n\ttop: 80px;\n\tleft: 0;\n\twidth: 280px;\n\theight: 100%;\n\tbackground: #e7e7e7;\n\ttransition: width .1s linear;\n}\n\n.admin_wrapper.hide .admin_sidebar {\n\twidth: 0px;\n\ttransition: width .25s linear;\n}\n\n.admin_sidebar .menu {\n\tvisibility: visible;\n\topacity: 1;\n\ttransition: visibility 0s, opacity 0.5s linear;\n}\n\n.admin_wrapper.hide .admin_sidebar .menu {\n\tvisibility: hidden;\n\topacity: 0;\n\ttransition: visibility 0s, opacity 0.5s linear;\n}\n\n.admin_sidebar .menu-item {\n\tdisplay: block;\n\tpadding: 5px 0;\n}\n\n.admin_sidebar .menu-item .menu-link {\n\tcursor: pointer;\n\tdisplay: block;\n\talign-items: center;\n\tpadding: 0;\n\tmargin: 0.65rem 25px;\n\tpadding: 0.65rem 25px;\n\ttransition: none;\n\toutline: none !important;\n\ttext-decoration: none;\n\tcolor: black;\n}\n\n.admin_sidebar .menu-item .menu-link.active {\n\tbackground-color: #f29109;\n\tcolor: white;\n\tborder-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
