import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import React from "react";

const RoleGuard = ({ allowedRoles }) => {

    function hasRole() {
        const token = localStorage.getItem("token");

        if (!token) {
            return false;
        }

        const decodedToken = jwtDecode(token);
        const hasAllowedRole = allowedRoles.some((role) => decodedToken.role.includes(role));
        return hasAllowedRole;
    }

    const role = hasRole();
    return (
        role ? <Outlet /> : <Navigate to="/general-info" />
    );
};

export default RoleGuard;