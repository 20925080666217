import React, { useState, useEffect, useCallback } from "react";
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import ProductInfo from "../Layout/ProductInfo";
import "./CircularityPage.css";

export default function CircularityPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [product, setProduct] = useState(null);
    const [showTab, setShowTab] = useState('-');
    const [result, setResult] = useState(null);
    const [methodologies, setMethodologies] = useState([]);

    const fetchProduct = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setProduct(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchCircularity = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}/circularity`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setResult(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchCircularityMethodologies = useCallback(async (id) => {
        const token = localStorage.getItem("token");
        axios.get(`${apiUrl}/products/${id}/circularity/methodologies`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setMethodologies(response.data);
            fetchCircularity(id);
            if (response.data.length === 1) {
                setShowTab(response.data[0].methodology);
            }
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, fetchCircularity]);

    const convertExponential = (value) => {
        let decimals = 0;
        if (Math.floor(value.valueOf()) === value.valueOf()) {
            decimals = 0;
        } else {
            decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
        }
        if (decimals > 2) {
            return value.toExponential(3);
        } else {
            return value;
        }
    }

    useEffect(() => {
        document.getElementById("splash").style.display = "block";
        const selected = localStorage.getItem("product");
        if (selected) {
            fetchProduct(selected);
            fetchCircularityMethodologies(selected);
        }
        document.getElementById("splash").style.display = "none";
    }, [fetchProduct, fetchCircularityMethodologies]);

    return (
        <>
            <ProductInfo product={product} />
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Circularity Assessment</div>
                        <p>
                            The ORIENTING project is centered in the assessment of products intended to promote the 
                            extended and/or cyclical use of products, their parts, and materials. 
                            Circularity is considered essential to achieve sustainability, and its assessment has 
                            been conducted by means of 2 different indicators: Circularity Transition Indicators 
                            (%) (CTI3.0) (WBCSD, 2023) and Material Circularity Indicator (MCI) (The Ellen MacArthur 
                            Foundation, 2023). Considering this, the calculations of the different indicators 
                            are presented in this dashboard.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row circularity">
                <div className="col">
                    <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10">
                        <div className="row">
                            <div className="col">
                                <div className="card-body pt-0">
                                    <div className="mt-3 w-100">
                                        <div className="row">
                                            {
                                                methodologies.length === 1 && <div className="col">
                                                    This is the selected methodology:
                                                </div>
                                            }
                                            {
                                                (methodologies.length === 0 || methodologies.length > 1) && <div className="col">
                                                    Please, select one methodology to see the results:
                                                </div>
                                            }
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                {
                                                    methodologies.length === 1 && <Form.Select value={showTab} disabled>
                                                        <option value={methodologies[0].methodology}>{methodologies[0].methodology}</option>
                                                    </Form.Select>
                                                }
                                                {
                                                    (methodologies.length === 0 || methodologies.length > 1) && <Form.Select value={showTab} onChange={e => setShowTab(e.target.value)}>
                                                        {(() => {
                                                            if (showTab === "-") {
                                                                return (
                                                                    <option hidden disabled defaultValue={"-"} value="-"></option>
                                                                );
                                                            }
                                                        })()}
                                                        {
                                                            methodologies.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.methodology}>{item.methodology}</option>
                                                                );
                                                            })
                                                        }
                                                    </Form.Select>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(() => {
                            if (showTab === 'CTI') {
                                return (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mt-4 mb-4">
                                                    <div className="card-body pt-0">
                                                        The <strong>Circular Transitions Indicators (CTI) framework</strong>, developed by the World Business Council for Sustainable Development (WBCSD),
                                                        is a simple, objective, and quantitative framework to measure circularity. It helps companies respond to this growing demand by providing a
                                                        simple and sector-agnostic way to measure circularity and giving insights into how to reduce primary resource use and waste generation.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" >
                                            <div className="col-md-3">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-4 mb-xl-4">
                                                    <div className="card-body d-flex align-items-end pt-0">
                                                        <div className="d-flex align-items-center flex-column mt-3 w-100">
                                                            <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                                                <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{result && result.circularity !== null ? convertExponential(result.circularity) : "-"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mt-4 mb-4">
                                                    <div className="card-body pt-0">
                                                        The <b>% of material circularity</b> – the weighted average between the % circular inflow and the % circular outflow – reflects the ability of a company to Close the Loop.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" >
                                            <div className="col-md-3">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-4 mb-xl-4">
                                                    <div className="card-body d-flex align-items-end pt-0">
                                                        <div className="d-flex align-items-center flex-column mt-3 w-100">
                                                            <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                                                <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{result && result.water_circularity !== null ? convertExponential(result.water_circularity) : "-"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mt-4 mb-4">
                                                    <div className="card-body pt-0">
                                                        Freshwater is a finite yet vital resource. It is critical to use it responsibly and to apply circular principles where possible.
                                                        The purpose of <b>water circularity</b> is to lower freshwater demand and ensure water resource availability for all users and the environment.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" >
                                            <div className="col-md-3">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-4 mb-xl-4">
                                                    <div className="card-body d-flex align-items-end pt-0">
                                                        <div className="d-flex align-items-center flex-column mt-3 w-100">
                                                            <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                                                <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{result && result.renewable_energy !== null ? convertExponential(result.renewable_energy) : "-"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mt-4 mb-4">
                                                    <div className="card-body pt-0">
                                                        In a circular economy, energy production depends on renewable sources and shifts away from fossil fuels.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            else if (showTab === 'MCI') {
                                return (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mt-4 mb-4">
                                                    <div className="card-body pt-0">
                                                        The <b>Material Circularity Indicator (MCI) tool</b>, developed by The Ellen MacArthur
                                                        Foundation and Granta Design, allows companies to identify additional, circular value from their products and materials, and mitigate risks
                                                        from material price volatility and material supply.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-4 mb-xl-4">
                                                    <div className="card-body d-flex align-items-end pt-0">
                                                        <div className="d-flex align-items-center flex-column mt-3 w-100">
                                                            <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                                                <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{result && result.linear_flow_index !== null ? convertExponential(result.linear_flow_index) : "-"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mt-4 mb-4">
                                                    <div className="card-body pt-0">
                                                        The <b>Linear Flow Index</b> measures the proportion of material flowing linearly, which means from virgin materials up to unrecoverable waste.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-4 mb-xl-4">
                                                    <div className="card-body d-flex align-items-end pt-0">
                                                        <div className="d-flex align-items-center flex-column mt-3 w-100">
                                                            <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                                                <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{result && result.utility_product !== null ? convertExponential(result.utility_product) : "-"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mt-4 mb-4">
                                                    <div className="card-body pt-0">
                                                        <b>Utility of a product (X)</b> is the product's use phase (lifetime) and the intensity of use.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-4 mb-xl-4">
                                                    <div className="card-body d-flex align-items-end pt-0">
                                                        <div className="d-flex align-items-center flex-column mt-3 w-100">
                                                            <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                                                <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{result && result.material_circularity_indicator !== null ? convertExponential(result.material_circularity_indicator) : "-"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mt-4 mb-4">
                                                    <div className="card-body pt-0">
                                                        <b>MCI</b> of a product is determined considering the Linear Flow Index and a factor F(X). It is one of the main circularity indexes and gives a value between 0 and 1, where a higher value indicates greater circularity.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })()}
                    </div>
                </div>
            </div>
        </>
    );
}