import React, { useState, useEffect, useCallback } from "react";
import ProductInfo from "../Layout/ProductInfo";
import axios from 'axios';
import { Table, Button, Form, Tabs, Tab } from 'react-bootstrap';
import LSCAPage2 from "./LCSAPage2";
import "./lcsa.css";

export default function LSCAPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [selected, setSelected] = useState([]);
    const [key, setKey] = useState('lcsa');
    const [valueTypeSAW, setValueTypeSAW] = useState("equal");
    const [valueTypeMAVT, setValueTypeMAVT] = useState("equal");
    const [valueTypeTOPSIS, setValueTypeTOPSIS] = useState("equal");
    const [lccWeightSAW, setLccWeightSAW] = useState(null);
    const [lcaWeightSAW, setLcaWeightSAW] = useState(null);
    const [slcaWeightSAW, setSlcaWeightSAW] = useState(null);
    const [lcaWeightMAVT, setLcaWeightMAVT] = useState(null);
    const [lccWeightMAVT, setLccWeightMAVT] = useState(null);
    const [slcaWeightMAVT, setSlcaWeightMAVT] = useState(null);
    const [lcaWeightTOPSIS, setLcaWeightTOPSIS] = useState(null);
    const [lccWeightTOPSIS, setLccWeightTOPSIS] = useState(null);
    const [slcaWeightTOPSIS, setSlcaWeightTOPSIS] = useState(null);
    const [lcsa, setLcsa] = useState([]);
    const [lcsas, setLcsas] = useState([]);
    const [type, setType] = useState('-');
    const [saw, setSaw] = useState([]);
    const [mavt, setMavt] = useState([]);
    const [topsis, setTopsis] = useState([]);
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const indicators = ["SAW", "MAVT", "TOPSIS"];

    const getData = async (selected) => {
        if (selected.length === 0) {
            setSelected([]);
            setType('-');
        } else {
            const objectsLCSA = [];
            for (let i = 0; i < selected.length; i++) {
                const objLCSA = await fetchSelectedLcsaData(selected[i]);
                objectsLCSA.push(objLCSA);
            }
            setLcsas(objectsLCSA);
            createSingleScoreGraphs(objectsLCSA);
        }
    };

    const createSingleScoreGraphs = (selectedProducts) => {
        const saw = [];
        const mavt = [];
        const topsis = [];
        saw.push({
            id: localStorage.getItem("product"),
            lca: lcsa.lcaSingleScore,
            lcc: lcsa.lccSingleScore,
            slca: lcsa.slcaSingleScore,
            normalized_lca: null,
            normalized_lcc: null,
            normalized_slca: null
        });
        mavt.push({
            id: localStorage.getItem("product"),
            lca: lcsa.lcaSingleScore,
            lcc: lcsa.lccSingleScore,
            slca: lcsa.slcaSingleScore,
            normalized_lca: null,
            normalized_lcc: null,
            normalized_slca: null
        });
        topsis.push({
            id: localStorage.getItem("product"),
            lca: lcsa.lcaSingleScore,
            lcc: lcsa.lccSingleScore,
            slca: lcsa.slcaSingleScore,
            normalized_lca: null,
            normalized_lcc: null,
            normalized_slca: null,
            single_score: null,
            d_max_lca: null,
            d_max_lcc: null,
            d_max_slca: null,
            d_max: null,
            d_min_lca: null,
            d_min_lcc: null,
            d_min_slca: null,
            d_min: null
        });
        for (let i = 0; i < selectedProducts.length; i++) {
            saw.push({
                id: selectedProducts[i].id,
                lca: selectedProducts[i].data.lcaSingleScore,
                lcc: selectedProducts[i].data.lccSingleScore,
                slca: selectedProducts[i].data.slcaSingleScore,
                normalized_lca: null,
                normalized_lcc: null,
                normalized_slca: null
            });
            mavt.push({
                id: selectedProducts[i].id,
                lca: selectedProducts[i].data.lcaSingleScore,
                lcc: selectedProducts[i].data.lccSingleScore,
                slca: selectedProducts[i].data.slcaSingleScore,
                normalized_lca: null,
                normalized_lcc: null,
                normalized_slca: null
            });
            topsis.push({
                id: selectedProducts[i].id,
                lca: selectedProducts[i].data.lcaSingleScore,
                lcc: selectedProducts[i].data.lccSingleScore,
                slca: selectedProducts[i].data.slcaSingleScore,
                normalized_lca: null,
                normalized_lcc: null,
                normalized_slca: null,
                single_score: null,
                d_max_lca: null,
                d_max_lcc: null,
                d_max_slca: null,
                d_max: null,
                d_min_lca: null,
                d_min_lcc: null,
                d_min_slca: null,
                d_min: null
            });
        }
        //SAW
        for (let i = 0; i < saw.length; i++) {
            if (saw[i].lca !== null) {
                if (saw[i].lca < 0) {
                    saw[i].lca = Math.abs(saw[i].lca);
                }
                saw[i].lca = 1 / saw[i].lca;
            }
            if (saw[i].lcc !== null) {
                if (saw[i].lcc < 0) {
                    saw[i].lcc = Math.abs(saw[i].lcc);
                }
                saw[i].lcc = 1 / saw[i].lcc;
            }
            if (saw[i].slca !== null) {
                if (saw[i].slca < 0) {
                    saw[i].slca = Math.abs(saw[i].slca);
                }
                saw[i].slca = 1 / saw[i].slca;
            }
        }
        //normalize results
        const lca_map_saw = saw.map(function (o) { return o.lca; });
        const lcc_map_saw = saw.map(function (o) { return o.lcc; });
        const slca_map_saw = saw.map(function (o) { return o.slca; });
        for (let i = 0; i < saw.length; i++) {
            if (saw[i].lca !== null) {
                saw[i].normalized_lca = saw[i].lca / Math.max.apply(Math, lca_map_saw);
            }
            if (saw[i].lcc !== null) {
                saw[i].normalized_lcc = saw[i].lcc / Math.max.apply(Math, lcc_map_saw);
            }
            if (saw[i].slca !== null) {
                saw[i].normalized_slca = saw[i].slca / Math.max.apply(Math, slca_map_saw);
            }
        }
        setSaw(saw);

        //MAVT
        const lca_map_mavt = mavt.map(function (o) { return o.lca; });
        const lcc_map_mavt = mavt.map(function (o) { return o.lcc; });
        const slca_map_mavt = mavt.map(function (o) { return o.slca; });
        for (let i = 0; i < mavt.length; i++) {
            if (mavt[i].lca !== null) {
                mavt[i].normalized_lca = (Math.max.apply(Math, lca_map_mavt) - mavt[i].lca) / (Math.max.apply(Math, lca_map_mavt) - Math.min.apply(Math, lca_map_mavt));
            }
            if (mavt[i].lcc !== null) {
                mavt[i].normalized_lcc = (Math.max.apply(Math, lcc_map_mavt) - mavt[i].lcc) / (Math.max.apply(Math, lcc_map_mavt) - Math.min.apply(Math, lcc_map_mavt));
            }
            if (mavt[i].slca !== null) {
                mavt[i].normalized_slca = (Math.max.apply(Math, slca_map_mavt) - mavt[i].slca) / (Math.max.apply(Math, slca_map_mavt) - Math.min.apply(Math, slca_map_mavt));
            }
        }

        setMavt(mavt);

        //TOPSIS
        const lca_map_topsis = topsis.map(function (o) { return o.lca; });
        const lcc_map_topsis = topsis.map(function (o) { return o.lcc; });
        const slca_map_topsis = topsis.map(function (o) { return o.slca; });
        for (let i = 0; i < topsis.length; i++) {
            if (topsis[i].lca !== null) {
                topsis[i].lca = topsis[i].lca / sqrt(lca_map_topsis);
            }
            if (topsis[i].lcc !== null) {
                topsis[i].lcc = topsis[i].lcc / sqrt(lcc_map_topsis);
            }
            if (topsis[i].slca !== null) {
                topsis[i].slca = topsis[i].slca / sqrt(slca_map_topsis);
            }
        }

        setTopsis(topsis);
    }

    function sqrt(array) {
        const sumaCuadrados = array.reduce((acumulador, elemento) => {
            return acumulador + Math.pow(elemento, 2);
        }, 0);
        const raizSumaCuadrados = Math.sqrt(sumaCuadrados);
        return raizSumaCuadrados;
    }

    const fetchProduct = useCallback(async (id) => {
        axios.get(`${apiUrl}/products/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setProduct(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const fetchSelectedLcsaData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lcsa`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchLcsaData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lcsa`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setLcsa(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchProducts = useCallback(async () => {
        axios.get(`${apiUrl}/products`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            setProducts(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, [apiUrl, token]);

    const changeValue = (type) => {
        setType(type);
        setValueTypeSAW('equal');
        setValueTypeMAVT('equal');
        setValueTypeTOPSIS('equal');
        setLccWeightSAW(null);
        setLcaWeightSAW(null);
        setSlcaWeightSAW(null);
        setLcaWeightMAVT(null);
        setLccWeightMAVT(null);
        setSlcaWeightMAVT(null);
        setLcaWeightTOPSIS(null);
        setLccWeightTOPSIS(null);
        setSlcaWeightTOPSIS(null);
    }

    const convertExponential = (value) => {
        let decimals = 0;
        if (Math.floor(value.valueOf()) === value.valueOf()) {
            decimals = 0;
        } else {
            decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
        }
        if (decimals > 2) {
            return value.toExponential(3);
        } else {
            return value;
        }
    }

    const calculateSaw = (value, type) => {
        if (valueTypeSAW === 'equal') {
            return convertExponential(value / 3);
        } else {
            if (type === "lca") {
                return convertExponential(value * lcaWeightSAW);
            } else if (type === "lcc") {
                return convertExponential(value * lccWeightSAW);
            } else if (type === "slca") {
                return convertExponential(value * slcaWeightSAW);
            } else {
                return convertExponential(value);
            }
        }
    }

    const calculateMavt = (value, type) => {
        if (valueTypeMAVT === 'equal') {
            return convertExponential(value / 3);
        } else {
            if (type === "lca") {
                return convertExponential(value * lcaWeightMAVT);
            } else if (type === "lcc") {
                return convertExponential(value * lccWeightMAVT);
            } else if (type === "slca") {
                return convertExponential(value * slcaWeightMAVT);
            } else {
                return convertExponential(value);
            }
        }
    }

    Array.prototype.calculateTopsis = function () {
        for (let i = 0; i < this.length; i++) {
            if (valueTypeTOPSIS === 'equal') {
                this[i].normalized_lca = this[i].lca / 3;
                this[i].normalized_lcc = this[i].lcc / 3;
                this[i].normalized_slca = this[i].slca / 3;
            } else {
                this[i].normalized_lca = this[i].lca * lcaWeightTOPSIS;
                this[i].normalized_lcc = this[i].lcc * lccWeightTOPSIS;
                this[i].normalized_slca = this[i].slca * slcaWeightTOPSIS;
            }
        }

        const lca_map_topsis = this.map(function (o) { return o.normalized_lca; });
        const lcc_map_topsis = this.map(function (o) { return o.normalized_lcc; });
        const slca_map_topsis = this.map(function (o) { return o.normalized_slca; });

        const lca_topsis_max = Math.max.apply(Math, lca_map_topsis);
        const lcc_topsis_max = Math.max.apply(Math, lcc_map_topsis);
        const slca_topsis_max = Math.max.apply(Math, slca_map_topsis);

        const lca_topsis_min = Math.min.apply(Math, lca_map_topsis);
        const lcc_topsis_min = Math.min.apply(Math, lcc_map_topsis);
        const slca_topsis_min = Math.min.apply(Math, slca_map_topsis);

        for (let i = 0; i < this.length; i++) {
            this[i].d_max_lca = Math.pow(this[i].normalized_lca - lca_topsis_min, 2);
            this[i].d_max_lcc = Math.pow(this[i].normalized_lcc - lcc_topsis_min, 2);
            this[i].d_max_slca = Math.pow(this[i].normalized_slca - slca_topsis_min, 2);

            this[i].d_min_lca = Math.pow(this[i].normalized_lca - lca_topsis_max, 2);
            this[i].d_min_lcc = Math.pow(this[i].normalized_lcc - lcc_topsis_max, 2);
            this[i].d_min_slca = Math.pow(this[i].normalized_slca - slca_topsis_max, 2);

            this[i].d_max = Math.sqrt(this[i].d_max_lca + this[i].d_max_lcc + this[i].d_max_slca);
            this[i].d_min = Math.sqrt(this[i].d_min_lca + this[i].d_min_lcc + this[i].d_min_slca);

            this[i].single_score = this[i].d_min / (this[i].d_max + this[i].d_min);
        }

        return this;
    }

    const calculateSingleScoreSaw = (valuelca, valuelcc, valueslca) => {
        if (valueTypeSAW === 'equal') {
            return convertExponential((valuelca + valuelcc + valueslca) / 3);
        } else {
            return convertExponential((valuelca * lcaWeightSAW) + (valuelcc * lccWeightSAW) + (valueslca * slcaWeightSAW));
        }
    }

    const calculateSingleScoreMavt = (valuelca, valuelcc, valueslca) => {
        if (valueTypeMAVT === 'equal') {
            return convertExponential((valuelca + valuelcc + valueslca) / 3);
        } else {
            return convertExponential((valuelca * lcaWeightMAVT) + (valuelcc * lccWeightMAVT) + (valueslca * slcaWeightMAVT));
        }
    }

    const changeTab = (tab) => {
        setKey(tab);
    }

    const changeValueTypeSAW = (type) => {
        if (type === "equal") {
            setSlcaWeightSAW(null);
            setLcaWeightSAW(null);
            setLccWeightSAW(null);
        }
        setValueTypeSAW(type);
    }

    const changeValueTypeMAVT = (type) => {
        if (type === "equal") {
            setLcaWeightMAVT(null);
            setLccWeightMAVT(null);
            setSlcaWeightMAVT(null);
        }
        setValueTypeMAVT(type);
    }

    const changeValueTypeTOPSIS = (type) => {
        if (type === "equal") {
            setLcaWeightTOPSIS(null);
            setLccWeightTOPSIS(null);
            setSlcaWeightTOPSIS(null);
        }
        setValueTypeTOPSIS(type);
    }

    const circlesOverlapInY = (circle1, circle2) => {
        const distanceY = Math.abs(circle1.y - circle2.y);
        const sumOfRadii = circle1.radius + circle2.radius;
        return distanceY <= sumOfRadii;
    }

    const canCalculate = () => {
        if (lcsa.lcaSingleScore === null || lcsa.lccSingleScore === null || lcsa.slcaSingleScore === null) {
            return false;
        }
        for (let i = 0; i < lcsas.length; i++) {
            if (lcsas[i].data.lcaSingleScore === null || lcsas[i].data.lccSingleScore === null || lcsas[i].data.slcaSingleScore === null) {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        document.getElementById("splash").style.display = "block";
        fetchProducts();
        const selected = localStorage.getItem("product");
        if (selected) {
            fetchProduct(selected);
            fetchLcsaData(selected);
        }
        document.getElementById("splash").style.display = "none";
    }, [fetchProduct]);

    return (
        <>
            <ProductInfo product={product} />
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Select at least one product to enable the tool to perform the integration calculations:</div>
                    </div>
                </div>
            </div>
            <div className="row pb-3">
                <div className="col">
                    <div className="card card-flush h-md-100">
                        <div className="card-body">
                            <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 generalTable">
                                <thead>
                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                        <th className="p-0 pb-3 min-w-100px text-start"></th>
                                        <th className="p-0 pb-3 min-w-100px text-start">NAME</th>
                                        <th className="p-0 pb-3 w-100px text-start pe-7">MODEL</th>
                                        <th className="p-0 pb-3 min-w-100px text-start">COMPANY</th>
                                        <th className="p-0 pb-3 min-w-100px text-start">LOCATION</th>
                                        <th className="p-0 pb-3 w-125px text-start pe-5">LIFETIME</th>
                                        <th className="p-0 pb-3 w-100px text-start pe-7">SCENARIO</th>
                                        <th className="p-0 pb-3 min-w-100px text-start ps-3">OPTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(() => {
                                        if (products.length === 0) {
                                            return (
                                                <tr>
                                                    <td colSpan='8'>
                                                        No product found
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        else {
                                            return (
                                                products.sort((a, b) => (a.productName > b.productName) ? 1 : (a.productName === b.productName) ? ((a.scenario > b.scenario) ? 1 : -1) : -1).map((item, index) => {
                                                    if (item.id !== localStorage.getItem("product")) {
                                                        return (
                                                            <tr key={index} className={selected.findIndex((element) => element === item.id) >= 0 ? 'selected' : 'odd'}>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">
                                                                    {
                                                                        selected.findIndex((element) => element === item.id) >= 0 &&
                                                                        <div style={{ width: '15px', height: '15px', marginLeft: '10px', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                    }
                                                                </td>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item.productName}</td>
                                                                <td className="p-0 pb-3 pt-3 w-100px text-start align-middle pe-7">{item.productModel}</td>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item.company}</td>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px align-middle text-start">{item.location}</td>
                                                                <td className="p-0 pb-3 pt-3 w-125px align-middle text-start pe-5">{item.lifeTime}</td>
                                                                <td className="p-0 pb-3 pt-3 w-100px align-middle text-start pe-7">{item.scenario}</td>
                                                                <td className="p-0 pb-3 pt-3 min-w-100px text-start align-middle">
                                                                    {(() => {
                                                                        if (selected.findIndex((element) => element === item.id) >= 0) {
                                                                            return (
                                                                                <button className="btn btn-sm btn-icon btn-danger delete" onClick={() => {
                                                                                    const sltd = selected;
                                                                                    if (sltd.includes(item.id)) {
                                                                                        const index = sltd.indexOf(item.id);
                                                                                        sltd.splice(index, 1);
                                                                                        setSelected(sltd);
                                                                                        getData(selected);
                                                                                    }
                                                                                }}>Unselect</button>
                                                                            )
                                                                        } else if (selected.length < 2) {
                                                                            return (
                                                                                <Button variant="primary" className="select-button ms-3" size="sm" onClick={() => {
                                                                                    const sltd = selected;
                                                                                    if (sltd.length < 3) {
                                                                                        sltd.push(item.id);
                                                                                        setSelected(sltd);
                                                                                        getData(sltd);
                                                                                    }
                                                                                }}>Select</Button>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                })
                                            )
                                        }
                                    })()}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">LCSA</div>
                        <p>
                            One of the overall aims of the ORIENTING project is to develop a robust
                            methodological approach for the integration and interpretation of
                            multi-dimensional life cycle information. Up to date, there are still
                            no standardized methods for integration or aggregation "LCSA", but under
                            ORIENTING, several methods have been studied and hence, are proposed as
                            robust methodologies for integration.
                        </p>
                        <p>
                            In the tool, the integration is presented in 2 different dashboards.
                            In both dashboards, the three following MCDA methods have been included
                            due to their ease of operationalization: Simple Additive Weighting (SAW),
                            Multi-Attribute Value Theory (MAVT) and Technique for Order Preference
                            by Similarity to Ideal Solution (TOPSIS).
                        </p>
                        <p>
                            Note that for the application of any of the methodologies,
                            at least 2 products must be selected. In addition, to aggregate the results
                            of the 3 domains, some weights have been set by default, but they can be modified,
                            thus the influence of the different weights on the results can be observed.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row pb-5 product-comparison">
                <div className="col">
                    <Tabs id="controlled-tab" activeKey={key} onSelect={(k) => changeTab(k)}>
                        <Tab eventKey="lcsa" title="LCSA">
                            {
                                selected.length === 0 && <div className="card card-flush h-md-100">
                                    <div className="card-body">
                                        <div className="mt-3 w-100">
                                            <div className="row">
                                                <div className="col">No product has been selected</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                selected.length > 0 && <div className="card card-flush h-md-100">
                                    <div className="card-body">
                                        {
                                            !canCalculate() && <div className="mt-3 w-100">
                                                <div className="col">
                                                    <div className="alert alert-warning">
                                                        If the 3 single scores (LCA single score, total undiscounted value, risk assessment single score) are not available, the integration cannot be performed using this method. Please go to "Custom LCSA".
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            canCalculate() && <div className="mt-3 w-100">
                                                <div className="row">
                                                    <div className="col">
                                                        In this dashboard, the results of the environmental, economic, and social assessments are aggregated in a single LCSA indicator. The single scores are taken from the individual assessments: the designated single score for LCA corresponds to the calculation conducted using PEF 3.1 or ORIENTING depending on the indicators that the user has entered, the “Total undiscounted cost” is the recommended single score for the LCC, and for the S-LCA, the single score is calculated based on the Risk assessment.
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        Which MCDA method would you like to apply to aggregate the LCSA results?
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6">
                                                        <Form.Select
                                                            value={type}
                                                            onChange={(e) => { changeValue(e.currentTarget.value) }}
                                                        >
                                                            {(() => {
                                                                if (type === "-") {
                                                                    return (
                                                                        <option hidden disabled value="-"></option>
                                                                    );
                                                                }
                                                            })()}
                                                            {
                                                                indicators.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item}>{item}</option>
                                                                    );
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                                {
                                                    type === 'SAW' && <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="title2 mt-3 mb-4">Weights</div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col text-center">
                                                                    <button type="button" className={valueTypeSAW === "equal" ? "btn btn-xs btn-primary" : "btn btn-xs btn-secondary"} onClick={() => changeValueTypeSAW("equal")}>By default</button>
                                                                    <button type="button" className={valueTypeSAW === "custom" ? "btn btn-xs btn-primary ms-2" : "btn btn-xs btn-secondary ms-2"} onClick={() => changeValueTypeSAW("custom")}>Customised</button>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <Table className="weight-table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>ENV</td>
                                                                                <td>
                                                                                    {
                                                                                        valueTypeSAW === "equal" && <Form.Control
                                                                                            type="text"
                                                                                            value="1/3"
                                                                                            disabled
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        valueTypeSAW === "custom" && <Form.Control
                                                                                            type="number"
                                                                                            min={0}
                                                                                            max={1}
                                                                                            step={0.01}
                                                                                            value={lcaWeightSAW || ''}
                                                                                            onChange={({ target: { value } }) => {
                                                                                                if (value >= 0 && value <= 1) {
                                                                                                    setLcaWeightSAW(value)
                                                                                                } else if (value > 1) {
                                                                                                    setLcaWeightSAW(1);
                                                                                                } else if (value < 0) {
                                                                                                    setLcaWeightSAW(0);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>ECO</td>
                                                                                <td>
                                                                                    {
                                                                                        valueTypeSAW === "equal" && <Form.Control
                                                                                            type="text"
                                                                                            value="1/3"
                                                                                            disabled
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        valueTypeSAW === "custom" && <Form.Control
                                                                                            type="number"
                                                                                            min={0}
                                                                                            max={1}
                                                                                            step={0.01}
                                                                                            value={lccWeightSAW || ''}
                                                                                            onChange={({ target: { value } }) => {
                                                                                                if (value >= 0 && value <= 1) {
                                                                                                    setLccWeightSAW(value)
                                                                                                } else if (value > 1) {
                                                                                                    setLccWeightSAW(1);
                                                                                                } else if (value < 0) {
                                                                                                    setLccWeightSAW(0);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>SOC</td>
                                                                                <td>
                                                                                    {
                                                                                        valueTypeSAW === "equal" && <Form.Control
                                                                                            type="text"
                                                                                            value="1/3"
                                                                                            disabled
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        valueTypeSAW === "custom" && <Form.Control
                                                                                            type="number"
                                                                                            min={0}
                                                                                            max={1}
                                                                                            step={0.01}
                                                                                            value={slcaWeightSAW || ''}
                                                                                            onChange={({ target: { value } }) => {
                                                                                                if (value >= 0 && value <= 1) {
                                                                                                    setSlcaWeightSAW(value)
                                                                                                } else if (value > 1) {
                                                                                                    setSlcaWeightSAW(1);
                                                                                                } else if (value < 0) {
                                                                                                    setSlcaWeightSAW(0);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 offset-md-1">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="title2 mt-3 mb-4">LCA, LCC and S-LCA single scores</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                (valueTypeSAW === 'equal' || (lcaWeightSAW !== null && lccWeightSAW !== null && slcaWeightSAW !== null && (Number(lcaWeightSAW) + Number(lccWeightSAW) + Number(slcaWeightSAW) === 1))) && <div className="row mt-4">
                                                                    <div className="col">
                                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered">
                                                                            <thead>
                                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">ENV</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">ECO</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">SOC</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Single score</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    saw.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateSaw(item.normalized_lca, "lca")}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateSaw(item.normalized_lcc, "lcc")}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateSaw(item.normalized_slca, "slca")}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateSingleScoreSaw(item.normalized_lca, item.normalized_lcc, item.normalized_slca)}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            (valueTypeSAW === 'equal' || (lcaWeightSAW !== null && lccWeightSAW !== null && slcaWeightSAW !== null && (Number(lcaWeightSAW) + Number(lccWeightSAW) + Number(slcaWeightSAW) === 1))) && <div className="col-md-3 offset-md-1">
                                                                <div className="chart mt-4 mb-4">
                                                                    <div className="values">
                                                                        <ul>
                                                                            <li>1,00E+00</li>
                                                                            <li>9,00E-01</li>
                                                                            <li>8,00E-01</li>
                                                                            <li>7,00E-01</li>
                                                                            <li>6,00E-01</li>
                                                                            <li>5,00E-01</li>
                                                                            <li>4,00E-01</li>
                                                                            <li>3,00E-01</li>
                                                                            <li>2,00E-01</li>
                                                                            <li>1,00E-01</li>
                                                                            <li>0,00E-00</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="graph">
                                                                        {(() => {
                                                                            const results = [];
                                                                            let i = 0;
                                                                            const circles = [];
                                                                            saw.map((item) => {
                                                                                results.push((calculateSingleScoreSaw(item.normalized_lca, item.normalized_lcc, item.normalized_slca) * 400) - 25);
                                                                            });

                                                                            results.map((item, index) => {
                                                                                const circle1 = { x: item, y: item + 50, radius: 50 };
                                                                                let tots = 0;
                                                                                results.forEach((item2, _) => {
                                                                                    const circle2 = { x: item2, y: item2 + 50, radius: 50 };
                                                                                    if (circlesOverlapInY(circle1, circle2)) {
                                                                                        tots++;
                                                                                    }
                                                                                })
                                                                                if (tots > 1) {
                                                                                    let left = 0;
                                                                                    if (i === 0) {
                                                                                        left = 20;
                                                                                    } else if (i === 1) {
                                                                                        left = 80;
                                                                                    }
                                                                                    i++;
                                                                                    circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, left: `${left}px`, backgroundColor: colors[index] }}></div>);
                                                                                } else {
                                                                                    circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, backgroundColor: colors[index] }}></div>);
                                                                                }
                                                                            })
                                                                            return (
                                                                                circles
                                                                            );
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    type === 'MAVT' && (mavt.filter(e => isNaN(e.normalized_lca)).length > 0 || mavt.filter(e => isNaN(e.normalized_lcc)).length > 0 || mavt.filter(e => isNaN(e.normalized_slca)).length > 0) && <div className="row mt-4">
                                                        <div className="col">
                                                            <div className="alert alert-warning">
                                                                MAVT cannot be applied if two or more alternatives have an equal score/value/result for the same domain.
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    type === 'MAVT' && (mavt.filter(e => isNaN(e.normalized_lca)).length === 0 || mavt.filter(e => isNaN(e.normalized_lcc)).length === 0 || mavt.filter(e => isNaN(e.normalized_slca)).length === 0) && <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="title2 mt-3 mb-4">Weights</div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col text-center">
                                                                    <button type="button" className={valueTypeMAVT === "equal" ? "btn btn-xs btn-primary" : "btn btn-xs btn-secondary"} onClick={() => changeValueTypeMAVT("equal")}>By default</button>
                                                                    <button type="button" className={valueTypeMAVT === "custom" ? "btn btn-xs btn-primary ms-2" : "btn btn-xs btn-secondary ms-2"} onClick={() => changeValueTypeMAVT("custom")}>Customised</button>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <Table className="weight-table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>ENV</td>
                                                                                <td>
                                                                                    {
                                                                                        valueTypeMAVT === "equal" && <Form.Control
                                                                                            type="text"
                                                                                            value="1/3"
                                                                                            disabled
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        valueTypeMAVT === "custom" && <Form.Control
                                                                                            type="number"
                                                                                            min={0}
                                                                                            max={1}
                                                                                            step={0.01}
                                                                                            value={lcaWeightMAVT || ''}
                                                                                            onChange={({ target: { value } }) => {
                                                                                                if (value >= 0 && value <= 1) {
                                                                                                    setLcaWeightMAVT(value)
                                                                                                } else if (value > 1) {
                                                                                                    setLcaWeightMAVT(1);
                                                                                                } else if (value < 0) {
                                                                                                    setLcaWeightMAVT(0);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>ECO</td>
                                                                                <td>
                                                                                    {
                                                                                        valueTypeMAVT === "equal" && <Form.Control
                                                                                            type="text"
                                                                                            value="1/3"
                                                                                            disabled
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        valueTypeMAVT === "custom" && <Form.Control
                                                                                            type="number"
                                                                                            min={0}
                                                                                            max={1}
                                                                                            step={0.01}
                                                                                            value={lccWeightMAVT || ''}
                                                                                            onChange={({ target: { value } }) => {
                                                                                                if (value >= 0 && value <= 1) {
                                                                                                    setLccWeightMAVT(value)
                                                                                                } else if (value > 1) {
                                                                                                    setLccWeightMAVT(1);
                                                                                                } else if (value < 0) {
                                                                                                    setLccWeightMAVT(0);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>SOC</td>
                                                                                <td>
                                                                                    {
                                                                                        valueTypeMAVT === "equal" && <Form.Control
                                                                                            type="text"
                                                                                            value="1/3"
                                                                                            disabled
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        valueTypeMAVT === "custom" && <Form.Control
                                                                                            type="number"
                                                                                            min={0}
                                                                                            max={1}
                                                                                            step={0.01}
                                                                                            value={slcaWeightMAVT || ''}
                                                                                            onChange={({ target: { value } }) => {
                                                                                                if (value >= 0 && value <= 1) {
                                                                                                    setSlcaWeightMAVT(value)
                                                                                                } else if (value > 1) {
                                                                                                    setSlcaWeightMAVT(1);
                                                                                                } else if (value < 0) {
                                                                                                    setSlcaWeightMAVT(0);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 offset-md-1">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="title2 mt-3 mb-4">LCA, LCC and S-LCA single scores</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                (valueTypeMAVT === 'equal' || (lcaWeightMAVT !== null && lccWeightMAVT !== null && slcaWeightMAVT !== null && (Number(lcaWeightMAVT) + Number(lccWeightMAVT) + Number(slcaWeightMAVT) === 1))) && <div className="row mt-4">
                                                                    <div className="col">
                                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered">
                                                                            <thead>
                                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">ENV</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">ECO</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">SOC</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Single score</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    mavt.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateMavt(item.normalized_lca, "lca")}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateMavt(item.normalized_lcc, "lcc")}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateMavt(item.normalized_slca, "slca")}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{calculateSingleScoreMavt(item.normalized_lca, item.normalized_lcc, item.normalized_slca)}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            (valueTypeMAVT === 'equal' || (lcaWeightMAVT !== null && lccWeightMAVT !== null && slcaWeightMAVT !== null && (Number(lcaWeightMAVT) + Number(lccWeightMAVT) + Number(slcaWeightMAVT) === 1))) && <div className="col-md-3 offset-md-1">
                                                                <div className="chart mt-4 mb-4">
                                                                    <div className="values">
                                                                        <ul>
                                                                            <li>1,00E+00</li>
                                                                            <li>9,00E-01</li>
                                                                            <li>8,00E-01</li>
                                                                            <li>7,00E-01</li>
                                                                            <li>6,00E-01</li>
                                                                            <li>5,00E-01</li>
                                                                            <li>4,00E-01</li>
                                                                            <li>3,00E-01</li>
                                                                            <li>2,00E-01</li>
                                                                            <li>1,00E-01</li>
                                                                            <li>0,00E-00</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="graph">
                                                                        {(() => {
                                                                            const results = [];
                                                                            let i = 0;
                                                                            const circles = [];
                                                                            mavt.map((item) => {
                                                                                results.push((calculateSingleScoreMavt(item.normalized_lca, item.normalized_lcc, item.normalized_slca) * 400) - 25);
                                                                            });

                                                                            results.map((item, index) => {
                                                                                const circle1 = { x: item, y: item + 50, radius: 50 };
                                                                                let tots = 0;
                                                                                results.forEach((item2, _) => {
                                                                                    const circle2 = { x: item2, y: item2 + 50, radius: 50 };
                                                                                    if (circlesOverlapInY(circle1, circle2)) {
                                                                                        tots++;
                                                                                    }
                                                                                })
                                                                                if (tots > 1) {
                                                                                    let left = 0;
                                                                                    if (i === 0) {
                                                                                        left = 20;
                                                                                    } else if (i === 1) {
                                                                                        left = 80;
                                                                                    }
                                                                                    i++;
                                                                                    circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, left: `${left}px`, backgroundColor: colors[index] }}></div>);
                                                                                } else {
                                                                                    circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, backgroundColor: colors[index] }}></div>);
                                                                                }
                                                                            })
                                                                            return (
                                                                                circles
                                                                            );
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    type === 'TOPSIS' && <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="title2 mt-3 mb-4">Weights</div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col text-center">
                                                                    <button type="button" className={valueTypeTOPSIS === "equal" ? "btn btn-xs btn-primary" : "btn btn-xs btn-secondary"} onClick={() => changeValueTypeTOPSIS("equal")}>By default</button>
                                                                    <button type="button" className={valueTypeTOPSIS === "custom" ? "btn btn-xs btn-primary ms-2" : "btn btn-xs btn-secondary ms-2"} onClick={() => changeValueTypeTOPSIS("custom")}>Customised</button>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <Table className="weight-table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>ENV</td>
                                                                                <td>
                                                                                    {
                                                                                        valueTypeTOPSIS === "equal" && <Form.Control
                                                                                            type="text"
                                                                                            value="1/3"
                                                                                            disabled
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        valueTypeTOPSIS === "custom" && <Form.Control
                                                                                            type="number"
                                                                                            min={0}
                                                                                            max={1}
                                                                                            step={0.01}
                                                                                            value={lcaWeightTOPSIS || ''}
                                                                                            onChange={({ target: { value } }) => {
                                                                                                if (value >= 0 && value <= 1) {
                                                                                                    setLcaWeightTOPSIS(value)
                                                                                                } else if (value > 1) {
                                                                                                    setLcaWeightTOPSIS(1);
                                                                                                } else if (value < 0) {
                                                                                                    setLcaWeightTOPSIS(0);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>ECO</td>
                                                                                <td>
                                                                                    {
                                                                                        valueTypeTOPSIS === "equal" && <Form.Control
                                                                                            type="text"
                                                                                            value="1/3"
                                                                                            disabled
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        valueTypeTOPSIS === "custom" && <Form.Control
                                                                                            type="number"
                                                                                            min={0}
                                                                                            max={1}
                                                                                            step={0.01}
                                                                                            value={lccWeightTOPSIS || ''}
                                                                                            onChange={({ target: { value } }) => {
                                                                                                if (value >= 0 && value <= 1) {
                                                                                                    setLccWeightTOPSIS(value)
                                                                                                } else if (value > 1) {
                                                                                                    setLccWeightTOPSIS(1);
                                                                                                } else if (value < 0) {
                                                                                                    setLccWeightTOPSIS(0);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>SOC</td>
                                                                                <td>
                                                                                    {
                                                                                        valueTypeTOPSIS === "equal" && <Form.Control
                                                                                            type="text"
                                                                                            value="1/3"
                                                                                            disabled
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        valueTypeTOPSIS === "custom" && <Form.Control
                                                                                            type="number"
                                                                                            min={0}
                                                                                            max={1}
                                                                                            step={0.01}
                                                                                            value={slcaWeightTOPSIS || ''}
                                                                                            onChange={({ target: { value } }) => {
                                                                                                if (value >= 0 && value <= 1) {
                                                                                                    setSlcaWeightTOPSIS(value)
                                                                                                } else if (value > 1) {
                                                                                                    setSlcaWeightTOPSIS(1);
                                                                                                } else if (value < 0) {
                                                                                                    setSlcaWeightTOPSIS(0);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 offset-md-1">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="title2 mt-3 mb-4">LCA, LCC and S-LCA single scores</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                (valueTypeTOPSIS === 'equal' || (lcaWeightTOPSIS !== null && lccWeightTOPSIS !== null && slcaWeightTOPSIS !== null && (Number(lcaWeightTOPSIS) + Number(lccWeightTOPSIS) + Number(slcaWeightTOPSIS) === 1))) && <div className="row mt-4">
                                                                    <div className="col">
                                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered">
                                                                            <thead>
                                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">ENV</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">ECO</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">SOC</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">d+</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    topsis.calculateTopsis().map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.d_max_lca)}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.d_max_lcc)}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.d_max_slca)}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.d_max)}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered mt-4">
                                                                            <thead>
                                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">ENV</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">ECO</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">SOC</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">d-</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    topsis.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.d_min_lca)}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.d_min_lcc)}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.d_min_slca)}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.d_min)}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0 table-bordered mt-4">
                                                                            <thead>
                                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">Product</th>
                                                                                    <th className="p-0 pb-2 pt-2 min-w-100px text-center align-middle">RC</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    topsis.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td><div style={{ width: '15px', height: '15px', marginLeft: '10px', marginRight: '10px', marginTop: '5px', float: 'left', backgroundColor: colors[selected.findIndex((element) => element === item.id) + 1] }}></div>
                                                                                                    {index === 0 ? product && `${product.name}-${product.scenario}` : `${products.filter(e => e.id === item.id)[0].productName}-${products.filter(e => e.id === item.id)[0].scenario}`}</td>
                                                                                                <td className="p-0 pb-2 pt-2 align-middle text-center">{convertExponential(item.single_score)}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            (valueTypeTOPSIS === 'equal' || (lcaWeightTOPSIS !== null && lccWeightTOPSIS !== null && slcaWeightTOPSIS !== null && (Number(lcaWeightTOPSIS) + Number(lccWeightTOPSIS) + Number(slcaWeightTOPSIS) === 1))) && <div className="col-md-3 offset-md-1">
                                                                <div className="chart mt-4 mb-4">
                                                                    <div className="values">
                                                                        <ul>
                                                                            <li>1,00E+00</li>
                                                                            <li>9,00E-01</li>
                                                                            <li>8,00E-01</li>
                                                                            <li>7,00E-01</li>
                                                                            <li>6,00E-01</li>
                                                                            <li>5,00E-01</li>
                                                                            <li>4,00E-01</li>
                                                                            <li>3,00E-01</li>
                                                                            <li>2,00E-01</li>
                                                                            <li>1,00E-01</li>
                                                                            <li>0,00E-00</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="graph">
                                                                        {(() => {
                                                                            const results = [];
                                                                            let i = 0;
                                                                            const circles = [];
                                                                            topsis.map((item) => {
                                                                                results.push((item.single_score * 400) - 25);
                                                                            });

                                                                            results.map((item, index) => {
                                                                                const circle1 = { x: item, y: item + 50, radius: 50 };
                                                                                let tots = 0;
                                                                                results.forEach((item2, _) => {
                                                                                    const circle2 = { x: item2, y: item2 + 50, radius: 50 };
                                                                                    if (circlesOverlapInY(circle1, circle2)) {
                                                                                        tots++;
                                                                                    }
                                                                                })
                                                                                if (tots > 1) {
                                                                                    let left = 0;
                                                                                    if (i === 0) {
                                                                                        left = 20;
                                                                                    } else if (i === 1) {
                                                                                        left = 80;
                                                                                    }
                                                                                    i++;
                                                                                    circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, left: `${left}px`, backgroundColor: colors[index] }}></div>);
                                                                                } else {
                                                                                    circles.push(<div className="circle" key={index} style={{ bottom: `${(item)}px`, backgroundColor: colors[index] }}></div>);
                                                                                }
                                                                            })
                                                                            return (
                                                                                circles
                                                                            );
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </Tab>
                        <Tab eventKey="lcsa2" title="CUSTOM LCSA">
                            {
                                selected.length === 0 && <div className="card card-flush h-md-100">
                                    <div className="card-body">
                                        <div className="mt-3 w-100">
                                            <div className="row">
                                                <div className="col">No product has been selected to compare</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                selected.length > 0 && <div className="card card-flush h-md-100">
                                    <LSCAPage2 product={product} products={products} selected={selected} />
                                </div>
                            }
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
}