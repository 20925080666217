export default function ProductInfo({ product }) {
    return (
        <div className="row g-5 gx-xl-10 mb-5 mb-xl-3 mt-5">
            <div className="col">
                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 selected-product-card">
                    <div className="card-header">
                        <div className="card-title d-flex flex-column">
                            <span>Selected product</span>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <div className="mt-3 col-md-3">
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{product && product.name}</span>
                                </div>
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span>Product name</span>
                                </div>
                            </div>
                            <div className="mt-3 col-md-3">
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{product && product.model}</span>
                                </div>
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span>Product model</span>
                                </div>
                            </div>
                            <div className="mt-3 col-md-2">
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{product && product.scenario}</span>
                                </div>
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span>Scenario</span>
                                </div>
                            </div>
                            <div className="mt-3 col-md-2">
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{product && product.lifeTime}</span>
                                </div>
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span>Lifetime (yr.)</span>
                                </div>
                            </div>
                            <div className="mt-3 col-md-2">
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{product && product.brand}</span>
                                </div>
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span>Product brand</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-md-6">
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{product && product.fu}</span>
                                </div>
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span>Functional Unit</span>
                                </div>
                            </div>
                            <div className="mt-3 col-md-6">
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span className="fs-2hx fw-bold me-2 lh-1 ls-n2">{product && product.date}</span>
                                </div>
                                <div className="d-flex justify-content-between fw-bold fs-6 w-100 mt-auto mb-2">
                                    <span>Date year</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}