import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from 'axios';
import ProductInfo from "../Layout/ProductInfo";
import { Table, Tab, Tabs, Form } from 'react-bootstrap';
import Chart from 'chart.js/auto';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import * as BsIcons from "react-icons/bs";
import SLCATable from "./SLCATable";
import SLCARiskTable from "./SLCARiskTable";
import SLCARiskTableSubRow2 from "./SLCARiskTableSubRow2";
import './slca.css';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import * as FaIcons from "react-icons/fa6";
Chart.register(ChartjsPluginStacked100);

export default function SLCAPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [product, setProduct] = useState(null);
    const [type, setType] = useState('-');
    const [key, setKey] = useState('performance');
    const [slcaPerformance, setSlcaPerformance] = useState([]);
    const [slcaPerformanceStages, setSlcaPerformanceStages] = useState([]);
    const [slcaRisk, setSlcaRisk] = useState([]);
    const [totals, setTotals] = useState([]);
    const [dataTotalsPerformance, setDataTotalsPerformance] = useState(0);
    const [dataTotalsRisk, setDataTotalsRisk] = useState(0);
    const [categories, setCategories] = useState([]);
    const [slcaRiskDatabases, setSlcaRiskDatabases] = useState([]);
    const colors = ['#9465b5', '#ffafff', '#ff7ac2', '#76bdff', '#ffa37b', '#ffff70', '#b1b1ff', '#bbb'];
    const [dataGraph, setDataGraph] = useState(null);
    const [title, setTitle] = useState("");
    const [dataGraphBar, setDataGraphBar] = useState(null);
    const chartRf = useRef();
    let cates = [];
    const options2 = {
        indexAxis: "y",
        duration: 0,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        if (item.dataset.data[item.dataIndex] < 0.001) {
                            return (`${item.dataset.label}: ${item.formattedValue}% (${convertExponential(item.dataset.data[item.dataIndex])} ${item.dataset.unit[item.dataIndex]})`);
                        } else {
                            return (`${item.dataset.label}: ${item.formattedValue}% (${item.dataset.data[item.dataIndex]} ${item.dataset.unit[item.dataIndex]})`);
                        }
                    }
                }
            },
            stacked100: { enable: true, replaceTooltipLabel: false }
        }
    };
    const options3 = {
        responsive: true,
        scales: {
            y: {
                ticks: {
                    callback: (val) => (convertExponential(val))
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (item) => {
                        const index = item.dataset.data.findIndex((element) => element === item.raw);
                        if (item.raw < 0.001) {
                            return (`${item.dataset.label}: ${convertExponential(item.raw)} ${item.dataset.unit[index]}`);
                        } else {
                            return (`${item.dataset.label}: ${convertExponential(item.formattedValue)} ${item.dataset.unit[index]}`);
                        }
                    }
                }
            },
            legend: {
                display: false
            },
            title: {
                display: false
            }
        }
    };

    const fetchProduct = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setProduct(res.data);
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const onClick2 = (event) => {
        const active = getElementAtEvent(chartRf.current, event);
        if (active.length > 0) {
            const label = dataGraph.labels[active[0].index];
            setTitle(label);
            const newData = slcaRisk.map(c => c.items).flat(2);
            createCharts(newData.filter(c => c.topic === label)[0]);
        }
    }

    const fetchSlcaPerformanceData = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/performance`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setSlcaPerformance(res.data);
            if (res.data.length === 0) {
                setKey('risk_assesment');
            }
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchSlcaPerformanceStagesData = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/performance/stages`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setSlcaPerformanceStages(res.data);
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const fetchSlcaRiskData = async (id, database) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/risk/databases/${database}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setSlcaRisk(res.data);
            createGraphs(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSlcaTotalsData = async (id, database) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/totals`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setTotals(res.data);
            if (res.data.totalNumberOfActors !== null && res.data.totalTier1Actors !== null) {
                const percentPerformance = (res.data.totalNumberOfActors / res.data.totalTier1Actors) * 100;
                const percentRisk = 100 - percentPerformance;
                setDataTotalsPerformance(percentPerformance);
                setDataTotalsRisk(percentRisk);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const convertExponential = (value) => {
        let decimals = 0;
        if (Math.floor(value.valueOf()) === value.valueOf()) {
            decimals = 0;
        } else {
            decimals = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
        }
        if (decimals > 2) {
            return value.toExponential(3);
        } else {
            return value;
        }
    }

    const fetchSlcaRiskDatabasesData = useCallback(async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/risk/databases`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setSlcaRiskDatabases(res.data);
            if (res.data.length === 1) {
                await changeValue(res.data[0].database);
            }
        } catch (err) {
            console.log(err);
        }
    }, [apiUrl, token]);

    const createGraphs = (dta) => {
        const newData = dta.map(c => c.items).flat(2);
        newData.sort((a, b) => a.topic.localeCompare(b.topic));
        let cats = cates;
        if (cats.length === 0) {
            cats = categories;
        }
        const stages = cats.map(c => c);
        let datasets = [];
        for (let i = 0; i < stages.length; i++) {
            const dataset = { label: stages[i], data: [], unit: [], backgroundColor: colors[i] };
            for (let j = 0; j < newData.length; j++) {
                let exist = false;
                for (let k = 0; k < newData[j].items.length; k++) {
                    if (newData[j].items[k].stage === stages[i]) {
                        dataset.data.push(newData[j].items[k].value);
                        dataset.unit.push(newData[j].items[k].unit);
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    dataset.data.push(0);
                }
            }
            datasets.push(dataset);
        };
        const data = {
            labels: newData.map(c => c.topic),
            datasets: datasets
        }

        setDataGraph(data);
    }

    const createCharts = (result) => {
        const data = {
            labels: result.items.map(c => c.stage),
            datasets: [
                {
                    label: '',
                    data: result.items.map(x => x.value),
                    backgroundColor: colors,
                    unit: result.items.map(x => x.unit)
                }
            ],
        }
        setDataGraphBar(data);
    }

    const changeValue = async (type) => {
        setType(type);
        const selected = localStorage.getItem("product");
        await fetchSlcaRiskData(selected, type);
    }

    useEffect(() => {
        document.getElementById("splash").style.display = "block";
        const selected = localStorage.getItem("product");
        const token = localStorage.getItem("token");
        axios.get(`${apiUrl}/products/categories`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            cates = response.data;
            setCategories(response.data);
            if (selected) {
                fetchProduct(selected);
                fetchSlcaPerformanceData(selected);
                fetchSlcaPerformanceStagesData(selected);
                fetchSlcaRiskDatabasesData(selected);
                fetchSlcaTotalsData(selected);
                document.getElementById("splash").style.display = "none";
            }
        }).catch(err => {
            console.log(err);
            document.getElementById("splash").style.display = "none";
        });
    }, [fetchProduct, fetchSlcaPerformanceData, fetchSlcaRiskDatabasesData]);

    return (
        <>
            <ProductInfo product={product} />
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-wrap flex-stack my-3">
                        <div className="fs-5 fw-semibold my-2 title">Social Life Cycle Assessment</div>
                        <p>
                            The ORIENTING S-LCA is built upon the UNEP (2020) Guidelines for S-LCA and the Handbook for Product Social Impact Assessment
                            (Goedkoop et al., 2020) and it focuses on <i>assessing social performances and risks of products along their life cycle</i>, according
                            to the Reference Scale Approach. Risks and performances are two conceptually different outcomes, built on different information: <i>social performances</i>
                            &nbsp;are based on company or site-specific data and describe the behaviour of the organization(s) in relation to those
                            processes under the direct/indirect control of the company conducting the assessment; <i>social risks</i> are based on sector or
                            country level data that describe the likelihood of a negative effect, especially in the background processes. The evaluation of performances
                            and risks is an interconnected and iterative process, thus, S-LCA application could result in the evaluation of one of the
                            two – performance or risk – or both, in a sort of hybrid analysis where social performance and social risk results are both assessed
                            for different portions of the product system. Considering this, 2 dashboards are available to select between S-LCA Performance and S-LCA Risk.
                            In the S-LCA Performance, the material and critical social topics are displayed. In the S-LCA Risk, all social indicators according to the database and relevant stakeholders are displayed.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row pb-5 slca">
                <div className="col">
                    <Tabs id="controlled-tab" activeKey={key} onSelect={(k) => setKey(k)}>
                        {
                            slcaPerformance.length > 0 && <Tab eventKey="performance" title="Performance assesment">
                                <div className="card card-flush h-md-100">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="alert alert-info">
                                                    <b>Relevant information:</b> Only the material topics are visualized according to the user selection. Those topics selected as "significant" by the user for the score aggregation are highlighted in the list.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col vertical-tabs">
                                                <div className="row">
                                                    <div className="col-md-9 mt-4">
                                                        <Table className="table table-bordered align-middle gs-0 gy-3 my-0 generalTable">
                                                            <thead>
                                                                <tr key={1} className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                    <th className="p-0 pb-3 text-start"></th>
                                                                    {
                                                                        slcaPerformanceStages.map((c, i) => (
                                                                            <th colSpan={c.actors.length} className="p-0 pb-3 m-w-100px max-w-100px text-center performance-header" key={`head-${i}`}>{c.stage}</th>
                                                                        ))
                                                                    }
                                                                    <th rowSpan={2} className="p-0 pb-3 m-w-100px max-w-100px text-center performance-header" style={{ backgroundColor: "#f4f4f4", verticalAlign: "middle" }}>Aggregated score</th>
                                                                </tr>
                                                                <tr key={2} className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                    <th key={0} className="p-0 pb-3 text-start"></th>
                                                                    {
                                                                        slcaPerformanceStages.map((c, i) => (
                                                                            c.actors.map((element, j) => (
                                                                                <th className="p-0 pb-3 m-w-100px max-w-100px text-center performance-header" key={`subhead-${i}-${j}`}>Actor {element}</th>
                                                                            ))
                                                                        ))
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    slcaPerformance.map((item, index) => {
                                                                        return (
                                                                            <SLCATable key={`performance-${index}`} row={item} stages={slcaPerformanceStages} />
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="card reference">
                                                            <div className="card-header align-items-center border-0">
                                                                <h3 className="card-title">Referece scale</h3>
                                                                <span className="text-muted mt-1 fw-semibold fs-7">5-point reference scale scores</span>
                                                            </div>
                                                            <div className="card-body pt-3">
                                                                <div className="align-items-sm-center mb-7">
                                                                    <div className="symbol me-4">
                                                                        <div className="symbol-label" style={{ backgroundColor: 'red' }}>-2</div>
                                                                        <div className="symbol-text">
                                                                            Unacceptable situation
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="align-items-sm-center mb-7 mt-3">
                                                                    <div className="symbol me-4">
                                                                        <div className="symbol-label" style={{ backgroundColor: '#ffc000' }}>-1</div>
                                                                        <div className="symbol-text">
                                                                            Unacceptable situation with signs of improvement
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="align-items-sm-center mb-7 mt-3">
                                                                    <div className="symbol me-4">
                                                                        <div className="symbol-label" style={{ backgroundColor: '#dddddd' }}>0</div>
                                                                        <div className="symbol-text">
                                                                            Acceptable situation
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="align-items-sm-center mb-7 mt-3">
                                                                    <div className="symbol me-4">
                                                                        <div className="symbol-label" style={{ backgroundColor: '#92d050' }}>+1</div>
                                                                        <div className="symbol-text">
                                                                            Beyond acceptable
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="align-items-sm-center mb-7 mt-3">
                                                                    <div className="symbol me-4">
                                                                        <div className="symbol-label" style={{ backgroundColor: '#00b050' }}>+2</div>
                                                                        <div className="symbol-text">
                                                                            Ideal performance
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="align-items-sm-center mb-7 mt-3">
                                                                    <div className="mt-3 mb-3" style={{ height: '1px', width: '100%', backgroundColor: '#e9e9e9', float: 'left' }}></div>
                                                                </div>
                                                                <div className="align-items-sm-center mb-3 mt-3">
                                                                    <div className="symbol flags me-4 mt-3">
                                                                        <div className="symbol-label"><FaIcons.FaFlag style={{ color: "red" }} /></div>
                                                                        <div className="symbol-text">
                                                                            In an overall positive score, the red flag indicates the presence of one or more negative scores (or risks) within the disaggregated scores.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="align-items-sm-center mb-3">
                                                                    <div className="symbol flags me-4 mt-3">
                                                                        <div className="symbol-label"><FaIcons.FaFlag style={{ color: "green" }} /></div>
                                                                        <div className="symbol-text">
                                                                            In an overall negative score, the green flag indicates the presence of one or more positive scores within the disaggregated scores.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        }
                        <Tab eventKey="risk_assesment" title="Risk assesment">
                            <div className="card card-flush h-md-100">
                                <div className="card-body">
                                    {(() => {
                                        if (slcaRiskDatabases.length === 0) {
                                            return (
                                                <div className="row">
                                                    <div className="col">
                                                        No data to show
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="row">
                                                            {slcaRiskDatabases.length === 1 && <div className="col">
                                                                This is the selected database:
                                                            </div>}
                                                            {(slcaRiskDatabases.length > 1) && <div className="col">
                                                                Please, select one database:
                                                            </div>}
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-md-6">
                                                                {
                                                                    slcaRiskDatabases.length === 1 && <Form.Select disabled value={type}>
                                                                        <option value={slcaRiskDatabases[0].database}>{slcaRiskDatabases[0].database}</option>
                                                                    </Form.Select>
                                                                }
                                                                {
                                                                    (slcaRiskDatabases.length === 0 || slcaRiskDatabases.length > 1) && <Form.Select
                                                                        value={type}
                                                                        onChange={(e) => { changeValue(e.currentTarget.value) }}
                                                                    >
                                                                        {(() => {
                                                                            if (type === "-") {
                                                                                return (
                                                                                    <option hidden disabled defaultValue={"-"} value="-"></option>
                                                                                );
                                                                            }
                                                                        })()}
                                                                        {
                                                                            slcaRiskDatabases.map((item, index) => {
                                                                                return (
                                                                                    <option key={index} value={item.database}>{item.database}</option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col vertical-tabs">
                                                                <Tabs
                                                                    defaultActiveKey="data"
                                                                    id="vertical-tabs"
                                                                >
                                                                    <Tab eventKey="data" title={<BsIcons.BsFillCalculatorFill />}>
                                                                        {
                                                                            (() => {
                                                                                if (type === "PSILCA") {
                                                                                    return (
                                                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                                                                            <thead>
                                                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                    <th className="p-0 pb-3 text-start"></th>
                                                                                                    <th className="p-0 pb-3 min-w-100px text-start">STAKEHOLDER & TOPICS</th>
                                                                                                    <th className="p-0 pb-3 min-w-100px text-start">UNIT</th>
                                                                                                    <th className="p-0 pb-3 min-w-100px text-center">VALUE</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className={slcaRisk.length === 0 ? "h-100px" : ""}>
                                                                                                {
                                                                                                    slcaRisk.map((item, index) => {
                                                                                                        return (
                                                                                                            <SLCARiskTable key={`risk-${index}`} row={item} />
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    );
                                                                                } else if (type !== "") {
                                                                                    return (
                                                                                        <Table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                                                                            <thead>
                                                                                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                                                    <th className="p-0 pb-3 text-start"></th>
                                                                                                    <th className="p-0 pb-3 min-w-100px text-start">TOPICS</th>
                                                                                                    <th className="p-0 pb-3 min-w-100px text-start">UNIT</th>
                                                                                                    <th className="p-0 pb-3 min-w-100px text-center">VALUE</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className={slcaRisk.length === 0 ? "h-100px" : ""}>
                                                                                                {(() => {
                                                                                                    const datas = slcaRisk.map(x => x.items);
                                                                                                    const common = datas.reduce((a, b) => [...a, ...b], []);
                                                                                                    common.sort((a, b) => (a.topic < b.topic) ? -1 : 1);
                                                                                                    return (
                                                                                                        common.map((item, index) => {
                                                                                                            return (
                                                                                                                <SLCARiskTableSubRow2 row={item} key={`risk-${index}`} />
                                                                                                            )
                                                                                                        })
                                                                                                    )
                                                                                                })()}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    );
                                                                                }
                                                                            })()
                                                                        }
                                                                    </Tab>
                                                                    <Tab eventKey="graphs" title={<BsIcons.BsGraphUp />}>
                                                                        <div className={slcaRisk.length === 0 ? "h-100px row" : "row"}>
                                                                            <div className="col">
                                                                                {
                                                                                    dataGraph !== null && <Bar className="mb-5" style={{ height: "auto" }} data={dataGraph} ref={chartRf} options={options2} onClick={onClick2} />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                If you select one social indicator, the results per life cycle stage will be shown:
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-5">
                                                                            <div className="col-md-6">
                                                                                {
                                                                                    dataGraphBar !== null && <div className="title2">{title}</div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                {
                                                                                    dataGraphBar !== null && <Bar className="mt-5" options={options3} data={dataGraphBar} />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Tab>
                                                                </Tabs>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="card card-flush h-md-100 bg-singlescore mt-3 results">
                                                            <div className="card-body">
                                                                <div className="card-title d-flex flex-column">
                                                                    <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{convertExponential(slcaRisk.reduce((n, { value }) => n + value, 0))}</span>
                                                                    <span className="text-gray-400 pt-1 fw-semibold fs-6">Single score</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })()}
                                </div>
                            </div>
                        </Tab>
                        {
                            totals !== null && totals.totalNumberOfActors !== null && totals.totalTier1Actors !== null && <Tab eventKey="combination" title="Graphical combination of risk and performance">
                                <div className="card card-flush h-md-100">
                                    <div className="card-body">
                                        <div className="row mt-5">
                                            <div className="col-md-6">
                                                <div className="title">Social impact assesment</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                {
                                                    (dataTotalsPerformance !== 0 || dataTotalsRisk !== 0) &&
                                                    <>
                                                        <div className="totls">
                                                            <div className="performance" style={{ width: `${dataTotalsPerformance}%` }}>{`${dataTotalsPerformance.toFixed(2)}%`}</div>
                                                            <div className="risk" style={{ width: `${dataTotalsRisk}%` }}>{`${dataTotalsRisk.toFixed(2)}%`}</div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                {
                                                    (dataTotalsPerformance !== 0 || dataTotalsRisk !== 0) &&
                                                    <>
                                                        <div className="legend">
                                                            <div className="performance"><div className="box"></div>Performance</div>
                                                            <div className="risk"><div className="box"></div>Risk</div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-6">
                                                100% corresponds to the total amount of tier -1 suppliers, so the percentage of risk and performance represent the number of actors/suppliers evaluated in terms of risk and performance, respectively.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        }
                    </Tabs>
                </div>
            </div >
        </>
    );
}