import * as AiIcons from "react-icons/ai";

export default function SLCARiskTableExpandableButton({ isOpen, toggle }) {
    return (
        <>
            {(() => {
                if (isOpen) {
                    return (
                        <AiIcons.AiOutlineMinusSquare onClick={toggle} />
                    )
                }
                else {
                    return (
                        <AiIcons.AiOutlinePlusSquare onClick={toggle} />
                    )
                }
            })()}
        </>
    )
}